import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import { observer } from 'mobx-react-lite';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import AddCircle from '@mui/icons-material/AddCircle';
import Collapse from '@mui/material/Collapse';
import EstateCard from '../../EstateCard';
import Estate from '../../../models/estates/Estate';
import UiText from '../../../uiComponents/UiText';
import EstateImport from './EstateImport';
import Estates from '../../../models/estates/Estates';
import HBoxVBox from '../../../uiComponents/HBoxVBox';
import useCollapse from '../../../uiComponents/useCollapse';
import { useMobile } from '../../../uiComponents/Responsive';
import gphQLApi from '../../../api/GraphqlAPI';
import { LangKey } from '../../../lang/LangKey';
import Operations from '../forest/operations/Operations';
import { Image } from '../../Article';
import { app } from '../../../models/App';

const PaginationStyled = styled(Pagination)({
  margin: '0 auto',
});
const HBoxStyled = styled(HBox)({
  cursor: !gphQLApi.isAuth ? 'inherit' : 'pointer',
});
const TitleContainer = styled(VBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: 8,
  },
}));

const EstateImportContainer = styled(HBoxVBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: 8,
  },
}));
const TitleStyle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    letterSpacing: '0.05em',
  },
}));

type Props = {
  title: string;
  desc?: string;
  estates: Estates;
};

const EstateTable = observer((props: Props) => {
  const {
    title, desc, estates,
  } = props;

  const isMobile = useMobile();

  const disabled = !gphQLApi.isAuth;

  const handleChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    estates.currentPage = page;
  };

  const { collapse, toggleCollapse } = useCollapse();

  return (
    <VBox gap={16}>
      <TitleContainer gap={16}>
        <TitleStyle div variant="h3">{title}</TitleStyle>
        {!!desc && <UiText div variant="body1">{desc}</UiText>}
      </TitleContainer>
      {(!disabled || estates.estates.length !== 0) && (
        <EstateImportContainer hGap={16} vGap={4}>
          <HBoxStyled onClick={toggleCollapse} gap={8} align="center">
            <UiText div variant="button" color="primary">{LangKey.EstatesAddTitle}</UiText>
            <AddCircle color="primary" fontSize="small" />
          </HBoxStyled>
          <Collapse in={collapse} orientation={!isMobile ? 'horizontal' : 'vertical'}>
            <EstateImport />
          </Collapse>
        </EstateImportContainer>
      )}
      {estates.pageEstates.map((estate: Estate) => (
        <EstateCard model={estate} key={estate.id} disabled={disabled} />
      ))}
      {!disabled && estates.pageCount !== 0 && (
        <PaginationStyled
          count={estates.pageCount}
          page={estates.currentPage}
          showFirstButton
          showLastButton
          size="medium"
          color="primary"
          onChange={handleChange}
          disabled={disabled}
        />
      )}
      {disabled && (
        <>
          <HBoxStyled onClick={toggleCollapse} gap={8} align="center">
            <UiText div variant="button" color="primary">{LangKey.EstatesAddTitle}</UiText>
            <AddCircle color="primary" fontSize="small" />
          </HBoxStyled>
          <Image src="./temp/img/guest1.png" alt="Estate preview_1" isMobile={+isMobile} isFullWidth />
        </>
      )}
      {(!disabled && estates.estates.length === 0 && app.browser.page.p !== 'forest') && <Image src="./temp/img/guest1.png" alt="Estate preview_1" isMobile={+isMobile} isFullWidth />}
      <Operations disabled={disabled || estates.estates.length === 0} />
      {(disabled || (estates.estates.length === 0 && app.browser.page.p !== 'forest'))
      && (
        <VBox gap={8}>
          <UiText variant="h3" div>Metsäomaisuuden tulokset ja suunnitelmien vertailu</UiText>
          <Image src="./temp/img/guest3.png" alt="Result preview_1" isMobile={+isMobile} isFullWidth />
        </VBox>
      )}
    </VBox>
  );
});

export default EstateTable;
