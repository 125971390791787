import * as React from 'react';
import { Form, useError, useValue } from '@simosol/forms/lib/forms';
import { KeysOfType } from '@simosol/forms/lib/FormInput';
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldProps,
} from '@mui/material/TextField/TextField';
import { styled } from '@mui/material/styles';

interface LoginInput {
  loginField?: boolean;
}

const TextFieldStyled = styled(TextField)<LoginInput>(({ loginField }) => ({
  '& .MuiInputBase-root': {
    borderRadius: 5,
    background: loginField ? 'rgba(95, 99, 102, 0.14)' : 'inherit',
    height: loginField ? 'auto' : 32,
  },
  '& .MuiFormHelperText-root': {
    margin: '0 auto',
  },
  'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button,': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  'input[type="tel"]:-webkit-autofill': {
    height: 0,
  },
}));

export type OmitMuiTextFieldProps<K extends keyof TextFieldProps> =
  Omit<StandardTextFieldProps, K> |
  Omit<FilledTextFieldProps, K> |
  Omit<OutlinedTextFieldProps, K>;

export type FormTextFieldProps<T> =
  OmitMuiTextFieldProps<'value' | 'onChange' | 'onBlur' | 'error' | 'helperText' > &
  {
    model: Form<T>
    field: KeysOfType<T, string | number | undefined | null>,
    formatFunction?: (value: string | undefined) => string,
    disableErrors?: boolean,
    loginField?: boolean,
  };

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FormTextField = <T, >(props: FormTextFieldProps<T>) => {
  const {
    model,
    field,
    formatFunction,
    disableErrors,
    ...otherProps
  } = props;

  const [changed, setChanged] = React.useState(false);

  let value = useValue(model, field) as unknown as string | undefined;
  const err = useError(model, field);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    const newValue: string | undefined = event.currentTarget.value;
    if (newValue === '') setChanged(false);
    model.setValue(field, newValue as unknown as T[keyof T]);
  };

  const onBlur = () => {
    if (changed) {
      model.validate(field);
    }
  };

  if (formatFunction) {
    value = formatFunction(value);
  }

  const errorText = err ? err.t() ?? '' : '';

  return (
    <TextFieldStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      onChange={onChange}
      value={value || ''}
      onBlur={onBlur}
      error={disableErrors ? false : !!err}
      helperText={disableErrors ? undefined : errorText}
    />
  );
};

export default FormTextField;
