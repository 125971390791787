import DEAD_TREE_STRATA from './deadTreeStrata';
import { PROPS } from '../queries/common';
import STRATA from './strata';

const TREE_STANDS = `{
  age
  basalArea
  carbonStorage
  dataDate
  deadTreeStrata ${DEAD_TREE_STRATA}
  developmentClass ${PROPS}
  id
  mainSpecies ${PROPS}
  meanDiameter
  meanHeight
  natureValue
  productiveValue
  pulpWoodVolume
  sawLogVolume
  soilCarbonStorage
  stemCount
  strata ${STRATA}
  treeCarbonFlux
  treeCarbonStorage
  treeStandType
  value
  valueGrowthPercent
  volume
  volumeGrowth
}`;

export default TREE_STANDS;
