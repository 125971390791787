import * as React from 'react';
import Star from '@mui/icons-material/Star';
import StarOutline from '@mui/icons-material/StarOutline';
import { observer } from 'mobx-react-lite';
import UiButton from '../../../uiComponents/UiButton';
import { LangKey } from '../../../lang/LangKey';
import { app } from '../../../models/App';
import { ArticleType } from '../../../models/article/Articles';

type Props = {
  article: ArticleType;
};

const ArticleAddToFavorite: React.FC<Props> = observer(({ article }) => {
  const { articles } = app;
  const toggle = () => articles.favoriteListSet(article);

  return (
    <div>
      <UiButton
        variant="outlined"
        color="primary"
        startIcon={articles.favoriteList.find((el) => el.id === article.id) ? <Star /> : <StarOutline />}
        onClick={toggle}
      >
        {articles.favoriteList.find((el) => el.id === article.id) ? LangKey.ButtonsRemoveFromFavorite.t() : LangKey.ButtonsAddToFavorite.t()}
      </UiButton>
    </div>
  );
});

export default ArticleAddToFavorite;
