import * as React from 'react';
import { HBox } from '@simosol/boxes';
import { ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { app } from '../../models/App';
import UiButton from '../../uiComponents/UiButton';
import GoalsButton from '../pages/goals/GoalsButton';
import GoalsDialog from '../pages/goals/GoalsDialog';
import { LangKey } from '../../lang/LangKey';
import { useMobile } from '../../uiComponents/Responsive';
import gphQLApi from '../../api/GraphqlAPI';

type Props = {};

const RootStyled = styled(HBox)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  height: 60,
  padding: theme.spacing(2, 0),
  borderBottom: `5px solid ${theme.palette.success.main}`,
  background: '#fff',
  position: 'relative',
}));

const AppTopMenu: React.FC<Props> = observer(() => {
  const page = app.browser.page.p;
  const isMobile = useMobile();

  const getColor = (name: string) => {
    let color: ButtonProps['color'] = 'inherit';
    if (name === LangKey.HeaderMetsaniTitle.t() && page !== '') color = 'secondary';
    // if (name === LangKey.HeaderVerotyokalutTitle.t() && page === '') color = 'secondary';
    return color;
  };

  return (
    <RootStyled gap={isMobile ? 0 : 32} align="center">
      <div>
        <UiButton
          variant="text"
          onClick={() => {
            if (!gphQLApi.isAuth) return;
            app.browser.page = { p: 'forest' };
          }}
          color={getColor(LangKey.HeaderMetsaniTitle.t())}
        >
          {LangKey.HeaderMetsaniTitle.t()}
        </UiButton>
      </div>
      <div>
        <UiButton
          variant="text"
          color={getColor(LangKey.HeaderVerotyokalutTitle.t())}
        >
          {LangKey.HeaderVerotyokalutTitle.t()}
        </UiButton>
      </div>
      <div>
        <UiButton
          variant="text"
          color={getColor(LangKey.HeaderMuistiinpanotTitle.t())}
        >
          {LangKey.HeaderMuistiinpanotTitle.t()}
        </UiButton>
      </div>
      <GoalsButton />
      <GoalsDialog open={app.estates.showGoals} onClose={app.estates.hideShowGoals} />
    </RootStyled>
  );
});

export default AppTopMenu;
