import * as React from 'react';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import Slider from '@mui/material/Slider';
import { SliderData, SliderModel } from '../../../models/goals/SliderModel';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';

const SliderStyled = styled(Slider)(() => ({
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    border: '6px solid currentColor',
    boxSizing: 'border-box',
    ',&:focus,&:hover,&$active': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  '& .MuiSlider-thumbDisabled': {
    display: 'none',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#fff',
    height: 16,
  },
  '& .MuiSlider-rail': {
    height: 20,
    borderRadius: 10,
    backgroundColor: '#C4C4C4',
    opacity: 1,
  },
  '& .MuiSlider-track': {
    height: 16,
  },
}));

export type SliderProps<D extends SliderData> = {
  model: SliderModel<D>;
};

const GoalSlider = observer(<D extends SliderData>(props: SliderProps<D>) => {
  const { model } = props;
  const { max, value } = model;
  const marks = [];
  for (let i = 1; i < max; i += 1) {
    marks.push({ value: i });
  }
  const onSliderChange = (_e: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number' && newValue !== model.value) {
      model.value = newValue;
    }
  };

  // timeout is used because immediately after this function mouseUp is fired and
  // snackbar closes
  const onChangeCommitted = () => {
    if (model.error) window.setTimeout(() => ResultSnackBar.error(model.error!.t()), 10);
  };
  return (
    <SliderStyled
      value={value}
      onChange={onSliderChange}
      step={1}
      min={0}
      max={max}
      marks={marks}
      color="secondary"
      onChangeCommitted={onChangeCommitted}
    />
  );
});

export default GoalSlider;
