import { PROPS } from '../queries/common';
import PLANING_AREAS from './planingAreas';
import SPECIAL_FEATURES from './specialFeatures';
import RESTRICTIONS from './restrictions';
import TREE_STANDS from './treeStands';
import { BADGE_OPERATION } from './operation';
import OPERATION_COMPUTED_PLANS from './operationComputedPlans';

const STANDS = `{
  id
  accessibility ${PROPS}
  areaHa
  areaM2
  dataDate
  description
  developmentClass ${PROPS}
  ditchingYear
  drainageState ${PROPS}
  estate { 
    id
    name
  }
  extension
  fertilityClass ${PROPS}
  geometry
  goalText
  isValid
  mainGroup ${PROPS}
  mainTreeSpecies ${PROPS}
  needUpdatePresentState
  number
  optimizedTreatmentType
  ownText
  parcelId
  parcelNumber
  planningAreas ${PLANING_AREAS}
  publicText
  quality ${PROPS}
  restrictions ${RESTRICTIONS}
  selectedTreatmentType
  simulationErrors
  soilType ${PROPS}
  specialFeatures ${SPECIAL_FEATURES}
  subGroup ${PROPS}
  validationErrors
  selectedPlanComputedTreeStand ${TREE_STANDS}
  selectedPlanEstimatedNatureValue
  selectedPlanEstimatedTotalVolume
  selectedPlanEstimatedTreeCarbonFlux
  selectedPlanEstimatedValue
  selectedPlanNextOperations ${BADGE_OPERATION}
  operationComputedPlans ${OPERATION_COMPUTED_PLANS}
}`;

export default STANDS;

export const SIMPLE_STAND = `{
  id
  extension
  number
}`;

/*
  fieldProposalOperations ${FIELD_PROPOSAL_OPERATIONS}
  activePlanComputedTreeStands ${TREE_STANDS}
  treeStandComputedPlans ${TREE_STANDS_COMPUTED_PLANS}
* */
