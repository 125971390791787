import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Paper, PaperProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  className?: string;
  style?: React.CSSProperties;
} & PropsWithChildren & Pick<PaperProps, 'elevation' | 'style' | 'className'>;

const useStyles = makeStyles(() => createStyles({
  root: {
    borderRadius: '18px !important',
  },
}));

const BlockRounded: React.FunctionComponent<Props> = ({ elevation, ...paperProps }) => {
  const styles = useStyles();
  return (
    <Paper
      classes={{
        root: styles.root,
      }}
      elevation={elevation || 6}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...paperProps}
    />
  );
};

export default BlockRounded;
