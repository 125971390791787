import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import SlidersModelData from '../../../models/goals/SliderModelData';
import Sliders from './Sliders';
import UiText from '../../../uiComponents/UiText';
import theme from '../../../theme';
import Spacer from '../../../common/Spacer';
import { app } from '../../../models/App';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';
import UiButton from '../../../uiComponents/UiButton';
import { LangKey } from '../../../lang/LangKey';

const DialogContentStyled = styled(DialogContent)({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 5, 0),
  },
});
const DialogTitleStyled = styled(DialogTitle)({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 5),
  },
});
const DialogActionsStyled = styled(DialogActions)({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 5),
  },
  justifyContent: 'space-between',
});
const DialogContentTextStyled = styled(DialogContentText)({
  paddingBottom: theme.spacing(2),
});
const UiButtonStyled = styled(UiButton)({
  width: 160,
});

const GoalsPointStyled = styled(UiText)({
  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
  },
});

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  top: 0,
  left: 0,
});

type Props = {
  open: boolean;
  onClose: () => void;
};

const GoalsDialog = observer((props: Props) => {
  const { open, onClose } = props;
  const { state } = app.goals;

  React.useEffect(
    () => {
      SlidersModelData.reset();
    },
    [],
  );

  React.useEffect(
    () => {
      if (open) SlidersModelData.reset();
    },
    [open],
  );

  const handleSaveGoals = async () => {
    const goals = SlidersModelData.sliders.map((sl) => (
      { weight: sl.value, criteria: sl.data.criteria }
    ));
    try {
      await app.goals.setGoals(goals);
      ResultSnackBar.show(LangKey.GoalsSuccess.t());
      onClose();
    } catch (e) {
      ResultSnackBar.show(LangKey.ErrorsGoalsError.t(), true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleStyled variant="h3">
        {state === 'new' ? LangKey.GoalsNewGoalsTitle.t() : LangKey.GoalsOldGoalsTitle.t()}
      </DialogTitleStyled>
      {state !== 'new' && (
        <IconButtonStyled onClick={onClose}>
          <Close color="secondary" />
        </IconButtonStyled>
      )}
      <DialogContentStyled>
        <DialogContentTextStyled>
          {LangKey.MessagesGoals.t()}
        </DialogContentTextStyled>
        <Sliders model={SlidersModelData} />
      </DialogContentStyled>
      <DialogActionsStyled>
        <GoalsPointStyled>{LangKey.GoalsPointLeft.t({ value: SlidersModelData.pointsLeft })}</GoalsPointStyled>
        <Spacer />
        {state !== 'new' && (
          <UiButton
            variant="text"
            onClick={onClose}
            loading={app.goals.updating}
          >
            {LangKey.ButtonsCancel.t()}
          </UiButton>
        )}
        <UiButtonStyled
          variant="contained"
          onClick={handleSaveGoals}
          loading={app.goals.updating}
        >
          {LangKey.ButtonsSave.t()}
        </UiButtonStyled>
      </DialogActionsStyled>
    </Dialog>
  );
});

export default GoalsDialog;
