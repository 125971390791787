import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import UiText from '../../../../uiComponents/UiText';
import ArticleTabs from './ArticleTabs';
import ArticleNewsShort from '../news/short/ArticleNewsShort';
import Divider from '../../../../common/Divider';
import { app } from '../../../../models/App';
import { LangKey } from '../../../../lang/LangKey';
import ArticleContent from '../ArticleContent';
import { HiddenDesktop } from '../../../../uiComponents/Responsive';

type Props = {
  expanded?: boolean;
};

const RootStyled = styled(VBox)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const HeaderButton = styled('div')(({ theme }) => ({
  width: '57px',
  height: '5px',
  borderRadius: '5px',
  margin: '0 auto',
  marginBottom: '8px',
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const HeaderStyled = styled(UiText)(({ theme }) => ({
  color: theme.palette.primary.main,
  letterSpacing: '1px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
}));

const ArticleLeftContainer: React.FC<Props> = observer(({ expanded }) => {
  const { articles } = app;
  const currentArticle = articles.filteredArticle;

  return (
    <RootStyled gap={16}>
      <HiddenDesktop>
        <HeaderButton />
      </HiddenDesktop>
      {((articles.currentArticle && !expanded) || (articles.currentArticle && app.browser.page.p !== 'estate')) ? <ArticleContent expanded small />
        : (
          <>
            <HeaderStyled div variant="h5">{LangKey.ArticlesArticlesTitle.t()}</HeaderStyled>
            <ArticleTabs />
            <VBox gap={16}>
              {currentArticle.map((v) => (
                <React.Fragment key={v.id}>
                  <ArticleNewsShort article={v} />
                  <DividerStyled height={1} />
                </React.Fragment>
              ))}
            </VBox>
          </>
        )}
    </RootStyled>
  );
});

export default ArticleLeftContainer;
