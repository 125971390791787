import * as React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import { useMobile } from '../../uiComponents/Responsive';
import { app } from '../../models/App';
import UiButton from '../../uiComponents/UiButton';
import LogOutDialog from '../LogOutDialog';
import { LangKey } from '../../lang/LangKey';
import theme from '../../theme';

const ButtonStyled = styled(UiButton)({
  position: 'absolute',
  right: 0,
  top: 8,
});
const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 8,
  padding: '2px 8px',
});

const LogOutStyled = styled('div')({
  position: 'relative',
  height: 44,
  [theme.breakpoints.down('md')]: {
    left: 30,
  },
});

const LogOutButton = observer(() => {
  const [openLoginOut, setOpenLoginOut] = React.useState<boolean>(false);
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <LogOutStyled>
        <IconButtonStyled onClick={() => setOpenLoginOut(true)} disabled={app.loading}>
          <ExitToAppIcon />
        </IconButtonStyled>
        <LogOutDialog open={openLoginOut} onClose={() => setOpenLoginOut(false)} />
      </LogOutStyled>
    );
  }

  return (
    <LogOutStyled>
      <ButtonStyled
        variant="outlined"
        color="primary"
        onClick={() => setOpenLoginOut(true)}
        endIcon={<ExitToAppIcon />}
        disabled={app.loading}
      >
        {LangKey.ButtonsLogout.t()}
      </ButtonStyled>
      <LogOutDialog open={openLoginOut} onClose={() => setOpenLoginOut(false)} />
    </LogOutStyled>
  );
});

export default LogOutButton;
