import * as React from 'react';
import { styled } from '@mui/material/styles';
import UiText from '../../uiComponents/UiText';
import theme from '../../theme';

const TitleStyled = styled(UiText)({
  letterSpacing: '0.05em',
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
  },
});

type Props = {
  title: string;
};

const BlockTitle: React.FunctionComponent<Props> = ({ title }) => (
  <TitleStyled div variant="h3">{title}</TitleStyled>
);

export default BlockTitle;
