import {
  Feature, MultiPolygon, Polygon,
} from 'geojson';
import proj4 from 'proj4';

export type Features = Polygon | MultiPolygon;

export class UtilsMap {
  static inverse3067 = (geometry: Features): Features => {
    switch (geometry.type) {
      case 'Polygon': {
        // eslint-disable-next-line no-param-reassign
        geometry.coordinates = geometry.coordinates
          .map((positionArr) => positionArr.map((position) => proj4('EPSG:3067')
            .inverse(position)));
        break;
      }
      case 'MultiPolygon': {
        // eslint-disable-next-line no-param-reassign
        geometry.coordinates = geometry.coordinates
          .map((positionArr) => positionArr
            .map((position) => position.map((pos) => proj4('EPSG:3067')
              .inverse(pos))));
        break;
      }
      default:
        break;
    }
    return geometry;
  };

  static geomToGeoJSON = (feature: string): Feature<Features> => ({
    type: 'Feature',
    geometry: UtilsMap.inverse3067(JSON.parse(feature)),
    properties: {},
  });
}
