import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoRounded from '@mui/icons-material/InfoRounded';
import List from '@mui/material/List';
import { observer } from 'mobx-react-lite';
import UiText from '../../../../uiComponents/UiText';
import MainContainer from '../../../common/MainContainer';
import { moneyFlowStr } from '../../../../utils/stringUtils';
import { app } from '../../../../models/App';
import CompareTable, { EPlansNames } from '../../../../models/CompareTable';
import CompareYearSelector from './CompareYearSelector';
import ComparePlans from './ComparePlans';
import gphQLApi from '../../../../api/GraphqlAPI';
import { LangKey } from '../../../../lang/LangKey';

const ContentBox = styled(VBox)<{ loading: number }>(({ loading }) => ({
  width: '100%',
  pointerEvents: loading ? 'none' : undefined,
  '@keyframes pulsate': {
    from: {
      opacity: 0.3,
    },
    to: {
      opacity: 0.7,
    },
  },
  animation: loading ? 'pulsate 1s infinite alternate ease' : undefined,
}));

const ResultTitle = styled(HBox)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 2),
  },
}));

type Props = {
  model: CompareTable,
};

const ResultContainer: React.FunctionComponent<Props> = observer(({ model }) => (
  gphQLApi.isAuth
    ? (
      <MainContainer title={LangKey.MainResultResultTitle.t()} description={LangKey.MainResultResultDescription.t()}>
        <VBox gap={16} align="center">
          <UiText div variant="h1" color="green" fontWeight="bold">
            {moneyFlowStr(app.totalUmount ?? 0)}
          </UiText>
          <ResultTitle gap={4} align="center">
            <UiText variant="h5">{LangKey.MainResultResultTotalTitle.t()}</UiText>
            <Tooltip
              placement="top"
              arrow
              title={LangKey.TooltipResultTotatal.t()}
            >
              <IconButton>
                <InfoRounded color="success" />
              </IconButton>
            </Tooltip>
          </ResultTitle>
          <Divider color="green" style={{ height: 2, width: '100%' }} />
          <ContentBox gap={8} loading={+model.loading}>
            <CompareYearSelector model={model} />
            <List>
              <ComparePlans
                model={model}
                simulationPlan={model.currentPlan}
                alternativePlan={model.economicPlan}
                currentTreeStand={model.currentTreeStand}
                alternativeTreeStand={model.economicTreeStand}
                planName={EPlansNames.ECONOMIC}
              />
              <ComparePlans
                model={model}
                simulationPlan={model.currentPlan}
                alternativePlan={model.carbonePlan}
                currentTreeStand={model.currentTreeStand}
                alternativeTreeStand={model.carboneTreeStand}
                planName={EPlansNames.CARBON}
                isEven
                defaultCollapse={app.browser.page.p !== 'forest'}
              />
              <ComparePlans
                model={model}
                simulationPlan={model.currentPlan}
                alternativePlan={model.naturePlan}
                currentTreeStand={model.currentTreeStand}
                alternativeTreeStand={model.natureTreeStand}
                planName={EPlansNames.NATURE}
              />
            </List>
          </ContentBox>
        </VBox>
      </MainContainer>
    )
    : null
));

export default ResultContainer;
