import { RefreshTokenInput } from '../../../generated/graphql';

const REFRESH_TOKEN = `
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      success
      errors
      token {
        token
        payload {
          exp
        }
      }
      refreshToken {
        token
      }
    }
  }
`;

export type RefreshTokenType = {
  input: RefreshTokenInput,
};

export default REFRESH_TOKEN;
