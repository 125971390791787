import STANDS from './stands';
import { COMPARE_OPERATION } from './operation';

const ESTATE = `{
  id
  code
  name
  description
  certification
  geometry
  selectedPlanEconomyCriteriaImprovement
  areaM2
  areaHa
  lastSimulation {
    createdAt
    finishedAt
    status
  }
  municipality {
    label
    region
    value
  }
  stands ${STANDS}
}`;

export const ESTATE_WITHOUT_STANDS = `{
  id
  code
  name
  description
  certification
  geometry
  selectedPlanEconomyCriteriaImprovement
  areaM2
  areaHa
  lastSimulation {
    createdAt
    finishedAt
    status
  }
  municipality {
    label
    region
    value
  }
  stands {
    id
    number
    extension
    estate {
      id
    }
  }
}`;

export const ESTATE_STANDS = `{
  id
  planningYearOperationsSummaryForSelectedPlan {
    year
    totalCashFlow
    operations ${COMPARE_OPERATION}
  }
  planningYearOperationsSummaryForPlainPlans {
    yearInfo {
      totalCashFlow
      year
      operations ${COMPARE_OPERATION}
    }
    treatmentType
  }
  planningYearTreeStandsSummaryForPlainPlans {
    treatmentType
    yearInfo {
      totalCarbonStorage
      totalNatureValueIndex
      year
    }
  }
  planningYearTreeStandsSummaryForSelectedPlan {
    totalCarbonStorage
    totalNatureValueIndex
    year
  }
  stands ${STANDS}
}`;

export default ESTATE;
