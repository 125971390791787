import * as React from 'react';
import {
  styled, ListItem,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { HBox, VBox } from '@simosol/boxes';
import BlockRounded from '../../../common/BlockRounded';
import UiText from '../../../../uiComponents/UiText';
import { moneyFlowStr } from '../../../../utils/stringUtils';
import Estate from '../../../../models/estates/Estate';
import StandCompareRow from './StandCompareRow';
import { app } from '../../../../models/App';
import { StandOperation } from '../../../../models/CompareTable';
import theme from '../../../../theme';
import { TreatmentTypeEnum } from '../../../../generated/graphql';
import { LangKey } from '../../../../lang/LangKey';

const BlockRoundedStyled = styled(BlockRounded)(({
  padding: 0,
  overflow: 'hidden',
}));
const RowStyled = styled(HBox)(() => ({
  width: '100%',
  alignItems: 'center',
}));
const CellYear = styled('div')<{ last?: 1 }>({
  flex: 2,
});
const CellData = styled('div')<{ last?: 1 }>({
  flex: 5,
});
const VBoxStyled = styled(VBox)({
  '& li:nth-child(odd)': {
    background: theme.palette.divider,
  },
});

type Props = {
  estate: Estate,
};

const ComparePlansContent: React.FC<Props> = observer((props: Props) => {
  const { estate } = props;

  const tableData: StandOperation[] = [];

  const {
    settings, endYear, plan, getAltOperation,
  } = app.compareTable;
  const { startYear, period } = settings;

  const thisPeriodCurrentPlanOperations = estate.planningYearOperationsSummaryForSelectedPlan
    .filter((p) => p.year >= startYear && p.year < endYear);
  const alternativePlan = estate.planningYearOperationsSummaryForPlainPlans
    .find((p) => p.treatmentType === plan);
  const thisPeriodAlternativePlanOperations = alternativePlan?.yearInfo
    .filter((p) => p.year >= startYear && p.year < endYear);

  const curPlanTotal = thisPeriodCurrentPlanOperations
    .reduce((sum, currentValue) => sum + currentValue.totalCashFlow, 0);
  const altPlanTotal = thisPeriodAlternativePlanOperations
    ?.reduce((sum, currentValue) => sum + currentValue.totalCashFlow, 0) ?? 0;

  for (let year = startYear; year < endYear; year += period) {
    estate.stands?.forEach((stand) => {
      tableData.push({
        year,
        period,
        data: stand.data,
        activeOperation: getAltOperation(thisPeriodCurrentPlanOperations, year, stand.id),
        alternativeOperation: getAltOperation(thisPeriodAlternativePlanOperations ?? [], year, stand.id),
      });
    });
  }

  const onlyUnique = (value: number, index: number, self: number[]) => self.indexOf(value) === index;
  const yearRows = tableData.map((td) => td.year).filter(onlyUnique);

  const tableTitle = plan === TreatmentTypeEnum.Nature
    ? LangKey.ResultsPlanNature
    : plan === TreatmentTypeEnum.Carbon
      ? LangKey.ResultsPlanCarbon
      : LangKey.ResultsPlanEconomic;

  return (
    <BlockRoundedStyled>
      <VBoxStyled>
        <ListItem dense style={{ background: '#15A42E', color: 'white' }}>
          <RowStyled>
            <CellYear>
              <UiText small>{LangKey.ResultsPlanYear}</UiText>
            </CellYear>
            <CellData>
              <UiText small>{LangKey.ResultsPlanUserPlan}</UiText>
            </CellData>
            <CellData>
              <UiText small>{tableTitle}</UiText>
            </CellData>
          </RowStyled>
        </ListItem>
        {yearRows.map((year, i) => (
          <StandCompareRow
            year={year}
            period={period}
            stands={tableData.filter((data) => (data.year === year && (
              data.activeOperation.length > 0 || data.alternativeOperation.length > 0)))}
            key={`${year}-${i}`}
            isEven={i % 2 === 0}
          />
        ))}
        <ListItem
          dense
          style={{
            background: thisPeriodCurrentPlanOperations.length % 2 === 0 ? undefined : theme.palette.divider,
          }}
        >
          <RowStyled>
            <CellYear>
              <UiText bold small>{LangKey.ResultsPlanInTotal}</UiText>
            </CellYear>
            <CellData>
              <UiText small color={curPlanTotal < 0 ? 'red' : 'inherit'}>{moneyFlowStr(curPlanTotal)}</UiText>
            </CellData>
            <CellData>
              <UiText small color={altPlanTotal < 0 ? 'red' : 'inherit'}>{moneyFlowStr(altPlanTotal)}</UiText>
            </CellData>
          </RowStyled>
        </ListItem>
      </VBoxStyled>
    </BlockRoundedStyled>
  );
});

export default ComparePlansContent;
