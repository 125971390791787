import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import UiText from '../uiComponents/UiText';

type Props = {
  title: string;
  desc: string;
};

const MainTitle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
  letterSpacing: '1px',
}));

const ArticleTexts = (props: Props) => {
  const { title, desc } = props;

  return (
    <VBox gap={16}>
      <MainTitle div variant="h3">{title}</MainTitle>
      <UiText div variant="body1">{desc}</UiText>
    </VBox>
  );
};

export default ArticleTexts;
