import * as React from 'react';
import { styled } from '@mui/material/styles';

type Props = {};

const SIZE = 24;

const DivIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(3),
  margin: theme.spacing(1),
  background: theme.palette.secondary.main,
  width: SIZE,
  height: SIZE,
}));

const ScaleIcon: React.FC<Props> = () => (
  <DivIcon>
    <img src="media/stand.svg" alt="" width={SIZE - 8} />
  </DivIcon>
);

export default ScaleIcon;
