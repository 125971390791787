import { createTheme } from '@mui/material/styles';

const headerFontFamily = 'Roboto';
const textGrayColor = '#6D6D6D';
const textGrayBlack = '#000000';
const dividerColor = '#F3F3F3';
const headerStyle = {
  fontFamily: headerFontFamily,
  color: textGrayBlack,
};

export const breakpointXXS = 360;

export const AppWidth = 1440;
export const rightBarWidth = 460;

const theme = createTheme({
  palette: {
    primary: {
      main: '#15A42E',
    },
    secondary: {
      main: '#7000E0',
      light: '#D98D3B',
    },
    contrastThreshold: 1.8,
    text: {
      primary: textGrayBlack,
      secondary: textGrayColor,
    },
    divider: dividerColor,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 23,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlinedPrimary: {
          borderColor: dividerColor,
        },
        contained: {
          '&,&:hover,&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
      defaultProps: {
        underline: 'hover',
      },
    },
  },
  typography: {
    h1: {
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1.167,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 22,
        lineHeight: 1.36,
      },
    },
    h2: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1.4,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 22,
        lineHeight: 1.36,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.5,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 22,
        lineHeight: 1.36,
      },
    },
    h4: {
      fontSize: 22,
      lineHeight: 1.36,
      fontWeight: 700,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 18,
        lineHeight: 1.222,
      },
    },
    h5: {
      fontSize: 18,
      lineHeight: 1.36,
      fontWeight: 700,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 16,
        lineHeight: 1.375,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.57,
      ...headerStyle,
      '@media (max-width: 600px)': {
        fontSize: 10,
        lineHeight: 1.4,
      },
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.56,
      '@media (max-width: 600px)': {
        fontSize: 12,
        lineHeight: 1.5,
      },
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.33,
    },
    subtitle1: {
      fontSize: 28,
      fontWeight: 300,
      lineHeight: 1.285,
      '@media (max-width: 600px)': {
        fontSize: 16,
        lineHeight: 1.375,
      },
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      color: textGrayColor,
    },
    overline: {
      fontSize: 14,
      lineHeight: 2.36,
      '@media (max-width: 600px)': {
        fontSize: 12,
        lineHeight: 2.75,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
