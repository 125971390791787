import {
  Breadcrumbs,
  Button, Card, CardActions, CardContent, CardMedia, styled,
} from '@mui/material';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { HBox } from '@simosol/boxes';
import { Star, StarOutline } from '@mui/icons-material';
import { ReactText } from '../../../../../uiComponents/UiText';
import { ArticleLabels, ArticleType } from '../../../../../models/article/Articles';
import { app } from '../../../../../models/App';
import { LangKey } from '../../../../../lang/LangKey';
import { useMobile } from '../../../../../uiComponents/Responsive';

const BoxStyled = styled(HBox)({
  justifyContent: 'space-between',
});

const TitleStyled = styled(ReactText)({
  fontSize: '16px',
  lineHeight: '22px',
  cursor: 'pointer',
});

const DescriptionStyled = styled(ReactText)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
});

const AritcleStyled = styled(Button)({
  fontFamily: 'Open Sans',
  fontWeight: 'bold',
  paddingLeft: 0,
});

type Props = {
  article: ArticleType
};

const ArticleNewsShort = observer((props: Props) => {
  const { articles } = app;
  const { article } = props;
  const isMobile = useMobile();

  const handleClick = () => {
    articles.setCurrentArticle(article);
  };

  return (
    <Card sx={{ maxWidth: isMobile ? '100%' : 460 }}>
      <CardMedia
        style={{ cursor: 'pointer' }}
        component="img"
        height="140"
        image={article.img}
        onClick={handleClick}
      />
      <CardContent>
        <BoxStyled>
          <AritcleStyled color="warning">
            {LangKey.ButtonsArticle.t()}
          </AritcleStyled>
          <Button
            color="primary"
            startIcon={articles.favoriteList.find((el) => el.id === article.id) ? <Star /> : <StarOutline />}
            onClick={() => articles.favoriteListSet(article)}
          >
            {articles.favoriteList.find((el) => el.id === article.id) ? LangKey.ButtonsRemoveFromFavorite.t() : LangKey.ButtonsAddToFavorite.t()}
          </Button>
        </BoxStyled>
        <TitleStyled gutterBottom variant="h5" div onClick={handleClick}>
          {article.title}
        </TitleStyled>
        <DescriptionStyled variant="body2" color="text.secondary">
          {article.description}
        </DescriptionStyled>
      </CardContent>
      <CardActions>
        <img src="./temp/img/hummer.svg" alt="img" />
        <Breadcrumbs separator="|">
          <ReactText variant="button">{ArticleLabels[article.category].t()}</ReactText>
          <ReactText variant="button">{article.author}</ReactText>
          <ReactText variant="button">{article.date}</ReactText>
        </Breadcrumbs>
      </CardActions>
    </Card>
  );
});

export default ArticleNewsShort;
