import * as React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Utils from '../lang/Utils';

export const ReactText: React.FunctionComponent<Omit<TextProps, 'p' | 'disableTranslation'>> = (props) => {
  const {
    div, children, text, bold, small, ...newProps
  } = props;

  const typographyProps: TypographyProps = {
    fontWeight: bold ? 'bold' : newProps.fontWeight,
    fontSize: small ? '11px' : newProps.fontWeight,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  const typography = <Typography {...newProps} {...typographyProps}>{text || children}</Typography>;
  if (div) return <div>{typography}</div>;
  return typography;
};

type TextProps = {
  disableTranslation?: boolean;
  div?: boolean;
  text?: string;
  p?: Object;
  bold?: boolean;
  small?: boolean;
} & TypographyProps;

const UiText = (props: TextProps) => {
  const {
    p, disableTranslation, children, ...newProps
  } = props;
  let { children: newChildren } = props;
  if (!disableTranslation) {
    newChildren = Utils.translateStringChildren(children, p);
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ReactText {...newProps}>
      {newChildren}
    </ReactText>
  );
};

export default UiText;
