import React from 'react';
import { Stand as MapStand } from '@simosol/stands-map';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import { StandPriority } from '@simosol/stands-map/lib/Stand';

export type DStandForMap = {
  id: string;
  geoJSON: Feature<Polygon | MultiPolygon>;
  priority: StandPriority;
  extensionLabel: string;
  forestRegulation?: boolean,
};

const useMapStands = <T extends DStandForMap>(stands?: T[]): MapStand[] => {
  const getStands = (): MapStand[] => {
    const mapStands = stands?.map((stand) => {
      let lineColor;
      if (stand.forestRegulation) lineColor = 'purple';
      return ({
        id: stand.id,
        geoJSON: stand.geoJSON,
        priority: () => stand.priority,
        extensionLabel: stand.extensionLabel,
        lineColor,
      });
    });
    return mapStands ?? [];
  };
  return React.useMemo(
    () => getStands(),
    [stands],
  );
};

export default useMapStands;
