import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import BlockRounded from '../../common/BlockRounded';
import UiText from '../../../uiComponents/UiText';
import useCollapse from '../../../uiComponents/useCollapse';
import UiButton from '../../../uiComponents/UiButton';

type Props = {
  small?: boolean;
};

const BlockRoundedStyled = styled(BlockRounded)<{ small?: boolean }>(({ theme, small }) => ({
  padding: theme.spacing(2),
  width: small ? '90%' : '70%',
  maxWidth: 500,
  margin: theme.spacing(0, 'auto', 5),
}));

const ArticleBlockDescription: React.FC<Props> = ({ small }) => {
  const { toggleCollapse, collapse } = useCollapse();
  return (
    <BlockRoundedStyled small={small}>
      <VBox gap={8} align="start">
        <HBox gap={8}>
          <UiText bold div>Metsäni:</UiText>
        </HBox>
        <UiButton
          variant="outlined"
          color="secondary"
          onClick={toggleCollapse}
          endIcon={collapse ? <ExpandLess /> : <ExpandMore />}
          fullWidth={false}
        >
          Näytä tilat
        </UiButton>
      </VBox>
    </BlockRoundedStyled>
  );
};

export default ArticleBlockDescription;
