import * as React from 'react';
import {
  styled, Collapse, Link,
} from '@mui/material';
import { HBox, VBox } from '@simosol/boxes';
import UiText, { ReactText } from '../../../../uiComponents/UiText';
import { moneyFlowStr } from '../../../../utils/stringUtils';
import useCollapse from '../../../../uiComponents/useCollapse';
import { app } from '../../../../models/App';
import { StandOperation } from '../../../../models/CompareTable';
import { StandGqlType } from '../../../../generated/graphql';
import theme from '../../../../theme';
import { extensionLabel } from '../../../../models/stands/Stand';
import { LangKey } from '../../../../lang/LangKey';

const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
  lineHeight: 2,
  fontSize: 11,
}));
const RowStyled = styled(HBox)<{ small?: number }>(({ small }) => ({
  position: 'relative',
  padding: theme.spacing(small ? 0.5 : 1, 1),
  alignItems: 'start',
}));
const CollapseButton = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
}));
const CellYear = styled('div')<{ last?: 1 }>({
  flex: 2,
});
const CellData = styled('div')<{ last?: 1 }>({
  flex: 5,
});

type Props = {
  year: number;
  period: number;
  stands: StandOperation[],
  isEven?: boolean;
};

const StandCompareRow: React.FC<Props> = (props: Props) => {
  const {
    year, period, stands, isEven,
  } = props;
  const { collapse, toggleCollapse, iconButton } = useCollapse();

  const handleClick = (data: StandGqlType) => {
    app.browser.page = {
      p: 'estate', p1: data.estate.id.toString(), p2: 'stand', p3: data.id,
    };
  };

  const yearTitle = period > 1
    ? `${year.toString().slice(-2)}-${(year + period - 1).toString().slice(-2)}`
    : `${year}`;

  const totalSelected = stands
    .reduce((total, stand) => total + stand.activeOperation
      .reduce((tot, op) => tot + (op.cashFlow ?? 0), 0), 0);
  const totalAlt = stands
    .reduce((total, stand) => total + stand.alternativeOperation
      .reduce((tot, op) => tot + (op.cashFlow ?? 0), 0), 0);

  return (
    <VBox
      gap={0}
      style={{
        background: isEven ? undefined : theme.palette.divider,
      }}
    >
      <RowStyled>
        <CellYear><UiText small>{yearTitle}</UiText></CellYear>
        <CellData><UiText small color={totalSelected < 0 ? 'red' : 'inherit'}>{moneyFlowStr(totalSelected, true)}</UiText></CellData>
        <CellData><UiText small color={totalAlt < 0 ? 'red' : 'inherit'}>{moneyFlowStr(totalAlt, true)}</UiText></CellData>
        <CollapseButton onClick={toggleCollapse}>
          {iconButton}
        </CollapseButton>
      </RowStyled>
      <Collapse in={collapse}>
        {stands.map((stand) => (
          <RowStyled key={stand.data.id} small={1}>
            <CellYear>
              <LinkStyled
                variant="caption"
                onClick={() => handleClick(stand.data)}
              >
                {LangKey.StandsStand.t({ number: extensionLabel(stand.data.number, stand.data.extension) })}
              </LinkStyled>
            </CellYear>
            <CellData>
              {stand.activeOperation?.map((op) => (
                <div key={op.id}>
                  <ReactText variant="caption" small color={op.cashFlow < 0 ? 'red' : 'inherit'}>
                    {`${op.operationType.label} ${moneyFlowStr(op.cashFlow ?? 0, true)}`}
                  </ReactText>
                </div>
              ))}
            </CellData>
            <CellData>
              {stand.alternativeOperation?.map((op) => (
                <div key={op.id}>
                  <ReactText variant="caption" small color={op.cashFlow < 0 ? 'red' : 'inherit'}>
                    {`${op.operationType.label} ${moneyFlowStr(op.cashFlow ?? 0, true)}`}
                  </ReactText>
                </div>
              ))}
            </CellData>
          </RowStyled>
        ))}
      </Collapse>
    </VBox>
  );
};

export default StandCompareRow;
