import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import { Breadcrumbs, styled } from '@mui/material';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import UiText from '../../../../uiComponents/UiText';
import Money from '../../../../common/Money';
import OperationInfo from './OperationInfo';
import HBoxVBox from '../../../../uiComponents/HBoxVBox';
import { app } from '../../../../models/App';
import { LangKey } from '../../../../lang/LangKey';
import { useMobile } from '../../../../uiComponents/Responsive';
import MobileOperation from './MobileOperation';

const PaperStyled = styled(Paper)(({
  overflow: 'hidden',
  borderRadius: '20px',
  padding: '8px 12px',
}));

const HBoxStyled = styled(HBox)(({
  flex: 1,
  justifyContent: 'center',
}));
const ItemStyled = styled(VBox)(({
  flex: 1,
}));
const BigItemStyled = styled(VBox)({
  flex: 2,
});

const ImgStyled = styled('img')({
  width: '36px',
  height: '36px',
  marginRight: '16px',
});

const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
  lineHeight: 2,
}));

const OperationType = styled(UiText)(({ theme }) => ({
  letterSpacing: '0.05em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}));

type Props = {
  estateId: string,
  estateName: string,
  operationType: string,
  yearPlanned?: number | null,
  cashFlow?: number | null,
  additionalInfo?: string,
  disabled?: boolean;
  withEstate?: boolean;
  standStyle?: boolean;
};

const Operation = (props: Props) => {
  const {
    operationType, estateId, estateName, yearPlanned, cashFlow, additionalInfo, disabled, withEstate,
  } = props;

  const isMobile = useMobile();

  const handleEstateClick = () => {
    if (disabled) return;
    app.browser.page = { p: 'estate', p1: estateId };
  };

  return (
    isMobile
      ? (
        <MobileOperation
          estateId={estateId}
          estateName={estateName}
          operationType={operationType}
          yearPlanned={yearPlanned}
          cashFlow={cashFlow}
          additionalInfo={additionalInfo}
          disabled={disabled}
          withEstate={withEstate}
        />
      )
      : (
        <PaperStyled elevation={3} style={{ opacity: disabled ? 0.5 : undefined }}>
          <HBox align="center">
            <ImgStyled src="./temp/img/3.png" alt="alt" />
            <HBoxStyled align="center">
              <HBoxVBox hAlign="center" sm style={{ width: '100%' }}>
                <BigItemStyled>
                  <OperationType variant="h5">{operationType}</OperationType>
                  <Breadcrumbs separator="•">
                    {withEstate && (
                    <LinkStyled variant="caption" onClick={() => handleEstateClick()}>{estateName}</LinkStyled>
                    )}
                  </Breadcrumbs>
                </BigItemStyled>
                {yearPlanned && (
                <ItemStyled>
                  <UiText variant="body1">{yearPlanned}</UiText>
                </ItemStyled>
                )}
                <ItemStyled>
                  <Money
                    title={cashFlow && (cashFlow > 0)
                      ? LangKey.CashTitlePositive.t({ value: '' })
                      : LangKey.CashTitleNegative.t({ value: '' })}
                    umount={cashFlow ?? 0}
                  />
                </ItemStyled>
              </HBoxVBox>
              <OperationInfo
                disabled={disabled}
                description={additionalInfo}
              />
            </HBoxStyled>
          </HBox>
        </PaperStyled>
      )
  );
};

export default Operation;
