import { HBox, VBox } from '@simosol/boxes';
import * as React from 'react';
import { Collapse, styled } from '@mui/material';
import useCollapse from '../../../../uiComponents/useCollapse';
import UiText from '../../../../uiComponents/UiText';
import StandStepsGoal from './StandStepsGoal';
import { OperationGqlType } from '../../../../generated/graphql';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  operations?: OperationGqlType[];
};

const LeftBlock = styled('div')({
  width: 36,
});

const BlockCollapse = styled(VBox)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StandStepsProcedures: React.FC<Props> = ({ operations }) => {
  const { iconButton, collapse } = useCollapse();
  return (
    <VBox>
      <HBox gap={16}>
        <LeftBlock />
        <HBox gap={4} align="center">
          <UiText variant="button">{LangKey.StandsAllProcedure}</UiText>
          {iconButton}
        </HBox>
      </HBox>
      <Collapse in={collapse}>
        <BlockCollapse gap={8}>
          {operations?.map((operation) => (
            <StandStepsGoal
              key={operation.id}
              data={operation}
              icon="waiting"
            />
          ))}
          {!operations?.length && <UiText>{LangKey.StandsNothingToShow}</UiText>}
        </BlockCollapse>
      </Collapse>
    </VBox>

  );
};

export default StandStepsProcedures;
