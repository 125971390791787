import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import ArticleLeftContainer from '../pages/article/leftContainer/ArticleLeftContainer';
import { app } from '../../models/App';
import CollapseButton from './CollapseButton';
import { rightBarWidth } from '../../theme';

type Props = {};

const Root = styled(VBox)<{ collapsed: number }>(({ theme, collapsed }) => ({
  width: !collapsed ? rightBarWidth : theme.spacing(0),
  minWidth: !collapsed ? rightBarWidth : 0,
  position: 'relative',
  borderLeft: `1px solid ${theme.palette.divider}`,
  boxShadow: '4px 0px 12px rgba(0, 0, 0, 0.1)',
  boxSizing: 'border-box',
  transition: theme.transitions.create(['width', 'min-width'], { duration: '0.3s' }),
  background: '#fff',
}));

const RightContainer: React.FunctionComponent<Props> = () => (
  <Root collapsed={+app.collapse}>
    <CollapseButton collapsed={app.collapse} onClick={app.toggleCollapsed} />
    {!app.collapse && <ArticleLeftContainer expanded={!app.collapse} />}
  </Root>
);

export default RightContainer;
