import * as React from 'react';
import { Info } from '@mui/icons-material';
import { VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import MainContainer from '../../../../common/MainContainer';
import StandFeature from './StandFeature';
import { RestrictionGqlType } from '../../../../../generated/graphql';
import { LangKey } from '../../../../../lang/LangKey';

const InfoStyled = styled(Info)(({ theme }) => ({
  margin: theme.spacing(1),
}));

type Props = {
  restrictions: RestrictionGqlType[];
};

const StandRestrictions: React.FC<Props> = (props: Props) => {
  const { restrictions } = props;

  if (restrictions.length === 0) {
    return (
      <MainContainer title={LangKey.FeaturesRestrictionTitle.t()} titleOnly>
        <StandFeature
          title={LangKey.MiscNoRestriction.t()}
          icon={<InfoStyled color="success" />}
        />
      </MainContainer>
    );
  }

  return (
    <MainContainer title={LangKey.FeaturesRestrictionTitle.t()} titleOnly>
      <VBox gap={16}>
        {restrictions.map((restriction) => (
          <StandFeature
            title={restriction.restrictionType.label}
            icon={<Info color="success" />}
            tooltip
          />
        ))}
      </VBox>
    </MainContainer>
  );
};

export default StandRestrictions;
