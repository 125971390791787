import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { VBox } from '@simosol/boxes';
import { LangKey } from '../../../lang/LangKey';

const Root = styled(VBox)(({
  position: 'absolute',
  top: 136,
  left: -36,
}));

const ButtonStyled = styled(Button)(({
  textTransform: 'uppercase',
  lineHeight: 1,
  fontSize: 12,
}));
const ButtonDiv = styled('div')(({
  width: 40,
  height: 120,
}));

const ButtonArticleStyled = styled(Button)(({
  textTransform: 'uppercase',
  lineHeight: 1,
  width: 160,
  fontSize: 12,
  padding: '6px 8px',
}));
const DivRotated = styled('div')(({
  transformOrigin: 'center',
  transform: 'rotate(-90deg)',
}));

type Props = {
  expanded: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
  toggleExpand: () => void;
  hidden: boolean;
};

const ArticleExpandButton: React.FunctionComponent<Props> = ({
  expanded, collapsed, toggleCollapse, toggleExpand, hidden,
}) => (
  <Root gap={48}>
    <ButtonDiv>
      <DivRotated>
        {!expanded && (
          <ButtonStyled
            color="secondary"
            variant="contained"
            endIcon={collapsed ? <ExpandLess /> : <ExpandMore />}
            onClick={toggleCollapse}
          >
            {collapsed ? LangKey.ButtonsExpandArticle.t() : LangKey.ButtonsCollapseArticle.t()}
          </ButtonStyled>
        )}
      </DivRotated>
    </ButtonDiv>
    <ButtonDiv hidden={hidden}>
      <DivRotated>
        <ButtonArticleStyled
          color="primary"
          variant="contained"
          endIcon={!expanded ? <ExpandLess /> : <ExpandMore />}
          onClick={toggleExpand}
        >
          {LangKey.ButtonsShowArticle.t()}
        </ButtonArticleStyled>
      </DivRotated>
    </ButtonDiv>
  </Root>
);

export default ArticleExpandButton;
