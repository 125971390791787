import { PROPS } from '../queries/common';

const DEAD_TREE_STRATA = `{
  deadTreeType ${PROPS}
  id
  meanDiameter
  species ${PROPS}
  volume
}`;

export default DEAD_TREE_STRATA;
