import { HBox } from '@simosol/boxes';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from '@simosol/forms';
import UiButton from '../../../uiComponents/UiButton';
import { app } from '../../../models/App';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';
import { apiErrorMessage } from '../../../api/API';
import APIError from '../../../api/APIError';
import FormTextField from '../../../uiComponents/forms/FormTextField';
import {
  isNumber, max, min, required,
} from '../../../uiComponents/forms/FormRules';
import { LangKey } from '../../../lang/LangKey';
import { useMobile } from '../../../uiComponents/Responsive';

type FormData = {
  1: string | undefined,
  2: string | undefined,
  3: string | undefined,
  4: string | undefined,
};

const validUntilRule = (dec: number) => [required, isNumber, min(0), max(10 ** dec - 1)];

const useRegDataForm = () => {
  const data: FormData = React.useState(() => ({
    1: undefined, 2: undefined, 3: undefined, 4: undefined,
  }))[0];
  const form = useForm<FormData>(data, {
    1: validUntilRule(3),
    2: validUntilRule(3),
    3: validUntilRule(4),
    4: validUntilRule(4),
  });
  return { data, form };
};

const EstateImport = observer(() => {
  const regDataForm = useRegDataForm();

  const clearFields = () => {
    regDataForm.form.setValue(1, undefined);
    regDataForm.form.setValue(2, undefined);
    regDataForm.form.setValue(3, undefined);
    regDataForm.form.setValue(4, undefined);
  };

  const handleImportEstateByCode = async () => {
    try {
      const f = regDataForm.form;
      if (!f.validateAll()) {
        ResultSnackBar.show(LangKey.EstatesIncorrect.t(), true);
        return;
      }
      const estateCode = `${f.getValue(1)}-${f.getValue(2)}-${f.getValue(3)}-${f.getValue(4)}`;
      if (app.estates.estates.length !== 0 && app.estates.estates.find((estate) => estate.code === estateCode)) {
        ResultSnackBar.show(LangKey.ErrorsEstateAlreadyAdded.t(), true);
        return;
      }
      const res = await app.estates.downloadEstateByCode(estateCode);
      if (res?.importOpenData.estates) {
        const { estates } = app;
        estates.fromData(res.importOpenData.estates);
        estates.addStands(res.importOpenData.estates);
        estates.importedEstates = res.importOpenData.estates;
        estates.showInfo = true;
        ResultSnackBar.show(LangKey.EstatesSuccess.t());
        clearFields();
      } else {
        ResultSnackBar.error(LangKey.EstatesNotFound.t());
      }
    } catch (e) {
      ResultSnackBar.show(apiErrorMessage(e as APIError), true);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = event.clipboardData.getData('text');
    const ids = pasted.trim().split('-');
    if (ids.length === 4) {
      regDataForm.form.setValue(1, ids[0]);
      regDataForm.form.setValue(2, ids[1]);
      regDataForm.form.setValue(3, ids[2]);
      regDataForm.form.setValue(4, ids[3]);
      event.preventDefault();
    }
  };

  const handleTab = (field: keyof FormData, dec: number) => {
    const length = regDataForm.form.getValue(field)?.toString().length;
    if (length && length >= dec) {
      const nextSibling = document.querySelector(`input[name=ssn-${field + 1}]`);
      if (nextSibling !== null) {
        // @ts-ignore
        nextSibling.focus();
      }
    }
  };

  const isMobile = useMobile();

  return (
    <HBox gap={isMobile ? 4 : 16} align="start">
      <HBox gap={isMobile ? 4 : 16}>
        <div>
          <FormTextField
            onPaste={handlePaste}
            onKeyUp={() => handleTab(1, 3)}
            model={regDataForm.form}
            field={1}
            variant="outlined"
            style={{ width: 64 }}
            disabled={app.estates.estateActionLoading}
            margin="none"
            type="tel"
            name="ssn-1"
          />
        </div>
        <div>
          <FormTextField
            onPaste={handlePaste}
            onKeyUp={() => handleTab(2, 3)}
            model={regDataForm.form}
            field={2}
            variant="outlined"
            style={{ width: 64 }}
            disabled={app.estates.estateActionLoading}
            margin="none"
            type="tel"
            name="ssn-2"
          />
        </div>
        <div>
          <FormTextField
            onPaste={handlePaste}
            onKeyUp={() => handleTab(3, 4)}
            model={regDataForm.form}
            field={3}
            variant="outlined"
            style={{ width: 64 }}
            disabled={app.estates.estateActionLoading}
            margin="none"
            type="tel"
            name="ssn-3"
          />
        </div>
        <div>
          <FormTextField
            onPaste={handlePaste}
            onKeyUp={() => handleTab(4, 4)}
            model={regDataForm.form}
            field={4}
            variant="outlined"
            style={{ width: 64 }}
            disabled={app.estates.estateActionLoading}
            margin="none"
            type="tel"
            name="ssn-4"
          />
        </div>
      </HBox>
      <UiButton
        div
        variant="contained"
        onClick={handleImportEstateByCode}
        loading={app.estates.estateActionLoading}
        size={isMobile ? 'small' : 'medium'}
      >
        {LangKey.ButtonsAdd}
      </UiButton>
    </HBox>
  );
});

export default EstateImport;
