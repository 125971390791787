import * as React from 'react';

const useNeighborBoxHeight = () => {
  const [height, setHeight] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(
    () => {
      setHeight(ref.current?.clientHeight as number);
    },
    [],
  );

  return {
    ref,
    height,
  };
};

export default useNeighborBoxHeight;
