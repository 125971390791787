import * as React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import { TypographyProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ReactText } from './UiText';
import { IconButtonProps } from './UiIconButton';
import ResultSnackBar from './ResultSnackBar';
import { apiErrorMessage } from '../api/API';
import APIError from '../api/APIError';
import Button, { ButtonProps } from './UiButton';
import { LangKey } from '../lang/LangKey';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    padding: theme.spacing(1),
  },
}));

type Props = {
  message?: string | string[];
  onClick: () => void,
  onOpen?: () => void,
  icon?: React.ReactElement,
  itemClassName?: string,
  variant: 'button' | 'icon' | 'listItem' | 'menuItem' | 'switch',
  checked?: boolean,
  disabled?: boolean,
  buttonProps?: ButtonProps & IconButtonProps & SvgIconProps,
  div?: boolean,
  textAlign?: TypographyProps['align'],
  title?: string,
  buttonText?: string,
};

type PropsWithChildren<P> = P & { children?: React.ReactNode };

const Confirm = (props: PropsWithChildren<Props>) => {
  const {
    message, onClick, textAlign, onOpen, variant, buttonProps, checked, disabled, icon, itemClassName, children, div, title, buttonText,
  } = props;
  const styles = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>();

  const handleClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const handleClickAgree = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (e) {
      if (e instanceof APIError && e.type === 'other') {
        ResultSnackBar.error(apiErrorMessage(e));
      } else {
        ResultSnackBar.error(LangKey.ErrorsConfirmError.t());
      }
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const handleClick = () => {
    onOpen?.();
    setOpen(true);
  };

  let element;
  if (variant === 'button') {
    const btnProps = { ...buttonProps, onClick: handleClick };
    const child = children || 'OK';
    element = React.createElement(Button, btnProps, child);
  } else if (variant === 'icon') {
    const iconProps = { ...buttonProps, onClick: handleClick };
    const childIcon = children || React.createElement(Delete, { fontSize: 'small' });
    element = React.createElement(IconButton, iconProps, childIcon);
  } else if (variant === 'listItem') {
    element = (
      <ListItem disabled={loading} onClick={handleClick} button>
        <ListItemText primary={children || 'OK'} />
      </ListItem>
    );
  } else if (variant === 'menuItem') {
    element = (
      <MenuItem disabled={loading ?? disabled} onClick={handleClick}>
        <ListItemIcon className={itemClassName}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={children || 'OK'} />
      </MenuItem>
    );
  } else if (variant === 'switch') {
    element = (
      <Switch checked={checked} onChange={handleClick} color="primary" />
    );
  }

  const text = message
    ? Array.isArray(message)
      ? message.map((v, k) => <ReactText text={v} key={k} />)
      : message
    : LangKey.MessagesConfirm.t();

  const content = (
    <>
      {element}
      <Dialog open={open} onClose={handleClose} classes={{ paper: styles.paper }}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText align={textAlign}>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={handleClose}
            variant="contained"
            color="inherit"
          >
            {LangKey.ButtonsDontWant.t()}
          </Button>
          <Button disabled={disabled} loading={loading} onClick={handleClickAgree} variant="contained" color="primary" autoFocus>
            {buttonText || LangKey.ButtonsYes.t()}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  if (div) return <div>{content}</div>;
  return content;
};

export default Confirm;
