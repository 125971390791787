import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import UiText, { ReactText } from '../../../../uiComponents/UiText';

type Props = {
  author: string,
  small?: boolean,
  mobile: boolean,
};

const RootStyled = styled(HBox)<{ small?: boolean }>(({ small }) => ({
  width: '90%',
  maxWidth: 800,
  justifyContent: 'space-between',
  flexWrap: small ? 'wrap' : 'nowrap',
}));

const PhotoStyled = styled('img')({
  width: 120,
});

const IconStyled = styled('img')({
  width: 30,
  height: 30,
  cursor: 'pointer',
});

const SocialIconStylde = styled(VBox)<{ small?: boolean }>(({ small }) => ({
  marginTop: small ? '28px' : 'inherit',
}));

const ArticleProfileRoot: React.FC<Props> = ({ author, small, mobile }) => (
  <RootStyled gap={24} align="center" small={small}>
    <HBox gap={24} align="start">
      <PhotoStyled src="./temp/img/beautifulGirl.png" />
      <VBox gap={16}>
        <ReactText div bold>{author}</ReactText>
        <ReactText div>Kirjoittaja</ReactText>
      </VBox>
    </HBox>
    <SocialIconStylde gap={8} small={small}>
      <UiText div>Jaa artikkeli</UiText>
      <HBox gap={4}>
        <IconStyled src={small && !mobile ? './temp/icons/facebookOrange.svg' : './temp/icons/facebook.svg'} />
        <IconStyled src={small && !mobile ? './temp/icons/innOrange.svg' : './temp/icons/inn.svg'} />
        <IconStyled src={small && !mobile ? './temp/icons/twitterOrange.svg' : './temp/icons/twitter.svg'} />
      </HBox>
    </SocialIconStylde>
  </RootStyled>
);

export default ArticleProfileRoot;
