import * as React from 'react';
import { styled } from '@mui/material/styles';
import { LangKey } from '../lang/LangKey';
import Article from './Article';

export const Image = styled('img')<{
  isMobile: number
}>(({ isMobile }) => ({
  borderRadius: 20,
  filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.1))',
  maxWidth: isMobile ? '100%' : '47%',
  height: 'fit-content',
  fontSize: isMobile ? '18px' : 'inherit',
}));

const LoggedArticle = () => (
  <Article
    title={LangKey.GuestTitle.t()}
    desc={LangKey.GuestDescription.t()}
    img="./temp/img/1.png"
  >
    <span />
  </Article>
);

export default LoggedArticle;
