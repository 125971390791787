import * as React from 'react';
import {
  Breadcrumbs, styled,
} from '@mui/material';
import UiText from '../../../uiComponents/UiText';
import EstateHeaderComparePlans from './comparePlans/EstateHeaderComparePlans';
import CommonStandsHeader from '../../common/stand/CommonStandsHeader';
import Estate from '../../../models/estates/Estate';
import theme from '../../../theme';
import { LangKey } from '../../../lang/LangKey';

const BreadcrumbsStyled = styled(Breadcrumbs)({
  padding: theme.spacing(1, 0),
});

const EstateTitleStyled = styled(UiText)({
  letterSpacing: '0.05em',
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
});

const EstateInfoStyled = styled(UiText)({
  fontSize: '14px',
});

type Props = {
  model: Estate,
};

const EstateHeader: React.FC<Props> = (props: Props) => {
  const { model } = props;

  return (
    <CommonStandsHeader estate={model}>
      <EstateTitleStyled variant="h3">{LangKey.EstatesHeader}</EstateTitleStyled>

      <BreadcrumbsStyled separator="•">
        <EstateInfoStyled variant="caption">{model.code}</EstateInfoStyled>
        {model.stands?.length === 1
          ? <EstateInfoStyled variant="caption">{`${model.stands?.length} ${LangKey.EstatesStand.t()}`}</EstateInfoStyled>
          : <EstateInfoStyled variant="caption">{`${model.stands?.length} ${LangKey.EstatesStands.t()}`}</EstateInfoStyled>}
        <EstateInfoStyled variant="caption">{`${model.areaM2} m³`}</EstateInfoStyled>
      </BreadcrumbsStyled>

      <UiText div variant="caption">
        {model.description}
      </UiText>

      <EstateHeaderComparePlans estate={model} />
    </CommonStandsHeader>
  );
};

export default EstateHeader;
