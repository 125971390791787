import * as React from 'react';
import { VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import ArticleTexts from './ArticleTexts';
import HBoxVBox from '../uiComponents/HBoxVBox';
import { useMobile } from '../uiComponents/Responsive';

export const Image = styled('img')<{
  isMobile: number,
  isFullWidth?: boolean,
}>(({ isMobile, isFullWidth }) => ({
  borderRadius: 20,
  filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.1))',
  maxWidth: (isMobile || isFullWidth) ? '100%' : '47%',
  height: 'fit-content',
}));

const StyledArticle = styled(VBox)(({
  whiteSpace: 'break-spaces',
}));

type Props = {
  img: string;
  title: string;
  desc: string;
  children: React.ReactNode;
};

const Article = (props: Props) => {
  const {
    img,
    desc,
    title,
    children,
  } = props;
  const isMobile = useMobile();

  return (
    <HBoxVBox hGap={40} vGap={16}>
      <Image src={img} alt={title} isMobile={+isMobile} />
      <StyledArticle gap={16}>
        <ArticleTexts title={title} desc={desc} />
        {children}
      </StyledArticle>
    </HBoxVBox>
  );
};

export default Article;
