import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment/moment';
import {
  OperationGqlType,
  PlanningYearOperationInfoGqlType,
  PlanningYearOperationSummaryGqlType,
  PlanningYearTreeStandInfoGqlType,
  PlanningYearTreeStandSummaryGqlType,
  StandGqlType, TreatmentTypeEnum,
} from '../generated/graphql';
import { GraphType } from '../views/pages/estate/comparePlans/ComparePlanGraph';
import gphQLApi from '../api/GraphqlAPI';

export const GRAPH_PERIOD = 30;

type TButtonSettings = {
  period: number,
  numPeriods: number,
  startYear: number,
};

export enum EPlansNames {
  ECONOMIC = 'Taloutta painottava',
  CARBON = 'Hiilivarastoa painottava',
  NATURE = 'Luontoarvoja painottava',
  ECONOMIC_FIELD = '',
  PURE_FIELD = '',
}

export type StandOperation = {
  year: number,
  period: number,
  data: StandGqlType,
  activeOperation: OperationGqlType[],
  alternativeOperation: OperationGqlType[],
};

class CompareTable {
  @observable
    loading: boolean = false;

  startYear = moment().year();

  currentPlan: PlanningYearOperationInfoGqlType[] = [];

  economicPlan: PlanningYearOperationInfoGqlType[] = [];

  carbonePlan: PlanningYearOperationInfoGqlType[] = [];

  naturePlan: PlanningYearOperationInfoGqlType[] = [];

  currentTreeStand: PlanningYearTreeStandInfoGqlType[] = [];

  economicTreeStand: PlanningYearTreeStandInfoGqlType[] = [];

  carboneTreeStand: PlanningYearTreeStandInfoGqlType[] = [];

  natureTreeStand: PlanningYearTreeStandInfoGqlType[] = [];

  @observable item: number = 1;

  @observable plan: TreatmentTypeEnum = TreatmentTypeEnum.Carbon;

  constructor() {
    makeObservable(this);
  }

  getData = async () => {
    try {
      this.loading = true;
      const res = await Promise.all([
        gphQLApi.planningYearOperationsSummaryForSelectedPlanGet(),
        gphQLApi.planningYearOperationsSummaryForPlainPlansGet(),
        gphQLApi.planningYearTreeStandsSummaryForSelectedPlanGet(),
        gphQLApi.planningYearTreeStandsSummaryForPlainPlansGet(),
      ]);
      if (res) {
        this.fromData(
          res[0].planningYearOperationsSummaryForSelectedPlan,
          res[1].planningYearOperationsSummaryForPlainPlans,
          res[2].planningYearTreeStandsSummaryForSelectedPlan,
          res[3].planningYearTreeStandsSummaryForPlainPlans,
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  };

  fromData = (
    currentPlan: PlanningYearOperationInfoGqlType[],
    otherPlans: PlanningYearOperationSummaryGqlType[],
    currentTreeStand: PlanningYearTreeStandInfoGqlType[],
    otherTreeStand: PlanningYearTreeStandSummaryGqlType[],
  ) => {
    this.currentPlan = currentPlan;
    this.economicPlan = otherPlans.find((plan) => plan.treatmentType === TreatmentTypeEnum.Economic)?.yearInfo ?? [];
    this.carbonePlan = otherPlans.find((plan) => plan.treatmentType === TreatmentTypeEnum.Carbon)?.yearInfo ?? [];
    this.naturePlan = otherPlans.find((plan) => plan.treatmentType === TreatmentTypeEnum.Nature)?.yearInfo ?? [];
    this.currentTreeStand = currentTreeStand;
    this.economicTreeStand = otherTreeStand.find((plan) => plan.treatmentType === TreatmentTypeEnum.Economic)?.yearInfo ?? [];
    this.carboneTreeStand = otherTreeStand.find((plan) => plan.treatmentType === TreatmentTypeEnum.Carbon)?.yearInfo ?? [];
    this.natureTreeStand = otherTreeStand.find((plan) => plan.treatmentType === TreatmentTypeEnum.Nature)?.yearInfo ?? [];
  };

  @computed
  get settings(): TButtonSettings {
    if (this.item === 1) {
      return {
        period: 1,
        numPeriods: 5,
        startYear: this.startYear,
      };
    }
    if (this.item === 2) {
      return {
        period: 1,
        numPeriods: 5,
        startYear: this.startYear + 5,
      };
    }
    return {
      period: 5,
      numPeriods: 8,
      startYear: this.startYear + 10,
    };
  }

  @computed
  get endYear() {
    const { startYear, period, numPeriods } = this.settings;
    return startYear + period * numPeriods;
  }

  // eslint-disable-next-line class-methods-use-this
  getAltOperation = (plan: PlanningYearOperationInfoGqlType[], year: number, standId: string) => {
    const standOperation: OperationGqlType[] = [];
    plan.forEach((curYear) => {
      if (year !== curYear.year) return;
      curYear.operations.forEach((op: OperationGqlType) => {
        if (op.stand.id !== standId) return;
        standOperation.push(op);
      });
    });
    return standOperation;
  };

  createDataForGraph = (
    selectedPlan: PlanningYearTreeStandInfoGqlType[],
    allPlans: PlanningYearTreeStandSummaryGqlType[],
  ) => {
    const altPlan = allPlans
      .find((plan) => plan.treatmentType === this.plan);

    const endYear = this.startYear + GRAPH_PERIOD;
    const carboneData: GraphType = [];
    const natureData: GraphType = [];

    selectedPlan.forEach((curr) => {
      if (curr.year > endYear) return;
      altPlan?.yearInfo.forEach((alt) => {
        if (curr.year === alt.year) {
          carboneData.push({
            year: `${curr.year}`,
            current: curr.totalCarbonStorage,
            alt: alt.totalCarbonStorage,
          });
          natureData.push({
            year: `${curr.year}`,
            current: curr.totalNatureValueIndex,
            alt: alt.totalNatureValueIndex,
          });
        }
      });
    });
    return { carboneData, natureData };
  };

  createCashFlowDataForGraph = (
    current: PlanningYearOperationInfoGqlType[],
    alt: PlanningYearOperationSummaryGqlType[],
  ) => {
    const cashFlowData: GraphType = [];
    const altPlan = alt
      .find((plan) => plan.treatmentType === this.plan);

    const endYear = this.startYear + GRAPH_PERIOD;
    const period = 5;
    for (let year = this.startYear; year < endYear; year += period) {
      const totalSelectCashFlow = current
        .filter((cash) => cash.year >= year && cash.year < year + period)
        .reduce((total, cash) => total + cash.totalCashFlow ?? 0, 0);
      const totalAltCashFlow = altPlan?.yearInfo
        .filter((cash) => cash.year >= year && cash.year < year + period)
        .reduce((total, cash) => total + cash.totalCashFlow ?? 0, 0);
      cashFlowData.push({
        year: `${year.toString().slice(-2)}-${(year + period - 1).toString().slice(-2)}`,
        current: totalSelectCashFlow,
        alt: totalAltCashFlow ?? 0,
      });
    }
    return cashFlowData;
  };

  getAltPlanForGraph = (
    selectedPlan: PlanningYearTreeStandInfoGqlType[],
    altPlan: PlanningYearTreeStandInfoGqlType[],
  ) => {
    const currentYear = this.startYear + GRAPH_PERIOD;
    const carboneData: GraphType = [];
    const natureData: GraphType = [];

    selectedPlan.forEach((curr) => {
      if (curr.year > currentYear) return;
      altPlan.forEach((alt) => {
        if (curr.year === alt.year) {
          carboneData.push({
            year: `${curr.year}`,
            current: curr.totalCarbonStorage,
            alt: alt.totalCarbonStorage,
          });
          natureData.push({
            year: `${curr.year}`,
            current: curr.totalNatureValueIndex,
            alt: alt.totalNatureValueIndex,
          });
        }
      });
    });
    return { carboneData, natureData };
  };

  getCashFlowDataForGraph = (
    current: PlanningYearOperationInfoGqlType[],
    alt: PlanningYearOperationInfoGqlType[],
  ) => {
    const cashFlowData: GraphType = [];

    const endYear = this.startYear + GRAPH_PERIOD;
    const period = 5;
    for (let year = this.startYear; year < endYear; year += period) {
      const totalSelectCashFlow = current
        .filter((cash) => cash.year >= year && cash.year < year + period)
        .reduce((total, cash) => total + cash.totalCashFlow ?? 0, 0);
      const totalAltCashFlow = alt?.filter((cash) => cash.year >= year && cash.year < year + period)
        .reduce((total, cash) => total + cash.totalCashFlow ?? 0, 0);
      cashFlowData.push({
        year: `${year.toString().slice(-2)}-${(year + period - 1).toString().slice(-2)}`,
        current: totalSelectCashFlow,
        alt: totalAltCashFlow ?? 0,
      });
    }
    return cashFlowData;
  };
}

export default CompareTable;
