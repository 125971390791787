import * as React from 'react';
import { HBox } from '@simosol/boxes';
import Info from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { app } from '../../../models/App';
import theme from '../../../theme';
import UiButton from '../../../uiComponents/UiButton';
import { LangKey } from '../../../lang/LangKey';

const DialogContentStyled = styled(DialogContent)({
  padding: theme.spacing(0, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
  },
});
const DialogTitleStyled = styled(DialogTitle)({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 4),
  },
});
const DialogActionsStyled = styled(DialogActions)({
  padding: theme.spacing(4, 8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 4),
  },
  justifyContent: 'flex-start',
});
const UiButtonStyled = styled(UiButton)({
  width: 120,
});

const AfterImportDialog = observer(() => (
  <Dialog
    open={app.estates.showInfo}
    onClose={app.estates.hideImportInfo}
    maxWidth="sm"
  >
    <DialogTitleStyled variant="h3">{LangKey.EstatesDialogTitle.t()}</DialogTitleStyled>
    <DialogContentStyled>
      <HBox gap={16} align="center">
        <div><Info color="warning" fontSize="large" /></div>
        <div>
          <DialogContentText>
            {LangKey.MessagesAddEstate.t({ number: app.estates.importedEstates.map((e) => e.code).join(',') })}
          </DialogContentText>
        </div>
      </HBox>
    </DialogContentStyled>
    <DialogActionsStyled>
      <UiButtonStyled variant="contained" onClick={app.estates.hideImportInfo}>{LangKey.ButtonsOk.t()}</UiButtonStyled>
    </DialogActionsStyled>
  </Dialog>
));

export default AfterImportDialog;
