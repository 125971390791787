import * as React from 'react';
import {
  Collapse, TableCell,
} from '@mui/material';
import { HBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import UiText from '../../../../uiComponents/UiText';
import ScaleIcon from '../../../common/icons/ScaleIcon';
import RowStandInfo from '../../estate/RowStandInfo';
import { StandsPatternRowStyled } from './StandsPatternStyles';
import { humanNumber, moneyFlowStr } from '../../../../utils/stringUtils';
import { app } from '../../../../models/App';
import useCollapse from '../../../../uiComponents/useCollapse';
import Stand from '../../../../models/stands/Stand';
import Estate from '../../../../models/estates/Estate';
import theme from '../../../../theme';
import { TreeStandGqlType } from '../../../../generated/graphql';
import { valueFormatter } from '../StandInfo';

type Props = {
  background?: string;
  stand: Stand;
  estate: Estate,
  isMobile: boolean;
};

const TextIdStyled = styled(UiText)({
  color: theme.palette.success.main,
  textDecoration: 'underline',
  cursor: 'pointer',
});
const CollapseDiv = styled('div')({
  position: 'absolute',
  top: 12,
  right: 8,
});
const HBoxStyled = styled(HBox)({
  margin: theme.spacing(0, 2),
  justifyContent: 'space-between',
});
const CollapseStyled = styled(Collapse)(({ collapse }: { collapse: boolean }) => ({
  margin: collapse ? theme.spacing(2) : 0,
}));

const StandsPatternRow: React.FC<Props> = ({
  background, stand, estate, isMobile,
}) => {
  const {
    extensionLabel, areaHa, treeSpecies, devClass, volume, value, natural, forestRegulation,
  } = stand;

  const { treeCarbonStorage, soilCarbonStorage } = stand.selectedPlanComputedTreeStand as TreeStandGqlType || 0;

  const { collapse, iconButton } = useCollapse();

  const openStand = () => {
    app.browser.page = {
      p: 'estate', p1: estate.id, p2: 'stand', p3: stand.id,
    };
  };

  return (
    <>
      <StandsPatternRowStyled sx={{ background }} height={64}>
        <TableCell>
          <HBoxStyled gap={0} align="center">
            <TextIdStyled
              fontWeight="bold"
              div
              onClick={openStand}
            >
              {extensionLabel}
            </TextIdStyled>
            {forestRegulation && <ScaleIcon />}
          </HBoxStyled>
        </TableCell>
        <TableCell>
          <UiText>{humanNumber(areaHa)}</UiText>
        </TableCell>
        {!isMobile && (
          <TableCell>
            <UiText>{treeSpecies}</UiText>
          </TableCell>
        )}
        {!isMobile && (
          <TableCell>
            <UiText>{devClass}</UiText>
          </TableCell>
        )}
        <TableCell>
          <UiText>{humanNumber(volume)}</UiText>
        </TableCell>
        {!isMobile && (
          <>
            <TableCell>
              <UiText>{moneyFlowStr(value, false, false)}</UiText>
            </TableCell>
            <TableCell>
              <UiText>{treeCarbonStorage && soilCarbonStorage && valueFormatter(humanNumber(treeCarbonStorage + soilCarbonStorage))}</UiText>
            </TableCell>
            <TableCell>
              <UiText>{humanNumber(natural)}</UiText>
            </TableCell>
          </>
        )}
        <CollapseDiv>
          {iconButton}
        </CollapseDiv>
      </StandsPatternRowStyled>
      <StandsPatternRowStyled
        sx={{
          background,
          opacity: collapse ? undefined : 0,
          transition: 'opacity 1s',
        }}
      >
        <TableCell
          colSpan={isMobile ? 3 : 8}
          sx={{
            padding: '0 !important',
            transition: 'padding 0.1s',
          }}
        >
          <CollapseStyled in={collapse} collapse={collapse}>
            <RowStandInfo
              stand={stand}
              estate={estate}
              isMobile={isMobile}
              openStand={openStand}
            />
          </CollapseStyled>
        </TableCell>
      </StandsPatternRowStyled>
    </>
  );
};

export default StandsPatternRow;
