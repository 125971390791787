import { VBox } from '@simosol/boxes';
import * as React from 'react';
import { PaperProps, styled } from '@mui/material';
import Divider from 'common/Divider';
import UiText from '../../../../uiComponents/UiText';
import BlockRounded from '../../../common/BlockRounded';
import StandStepsGoal from './StandStepsGoal';
import StandStepsProcedures from './StandStepsProcedures';
import { OperationGqlType } from '../../../../generated/graphql';

const CardTitle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    letterSpacing: '0.05em',
  },
}));

type Props = {
  title: string;
  operations: OperationGqlType[];
  elevation?: PaperProps['elevation'];
};

const Content = styled(VBox)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StandStepCard: React.FC<Props> = ({
  title, operations, elevation,
}) => (
  <BlockRounded elevation={elevation}>
    <Content gap={8}>
      <CardTitle bold variant="h6" div>{title}</CardTitle>
      <StandStepsGoal data={operations[0]} icon="next" />
    </Content>
    <Divider color="#7000E0" height={1} />
    <StandStepsProcedures operations={operations.slice(1)} />
  </BlockRounded>
);

export default StandStepCard;
