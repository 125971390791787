import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { HBox } from '@simosol/boxes';
import ResultContainer from './results/ResultContainer';
import GuestArticle from '../../GuestArticle';
import EstateTable from '../estate/EstateTable';
import { app } from '../../../models/App';
import ContentBox from '../../app/ContentBox';
import LoggedArticle from '../../LoggedArticle';
import RightContainer from '../../rightContainer/RightContainer';
import { HiddenMobile } from '../../../uiComponents/Responsive';
import theme, { AppWidth, rightBarWidth } from '../../../theme';
import AfterImportDialog from '../goals/AfterImportDialog';
import AppPreloader from '../../AppPreloader';
import gphQLApi from '../../../api/GraphqlAPI';
import { LangKey } from '../../../lang/LangKey';

const MainContainer = styled('div')<{ collapsed: number }>(({ collapsed }) => ({
  width: collapsed ? '100%' : (AppWidth - rightBarWidth),
  transition: theme.transitions.create(['width'], { duration: '0.3s' }),
  backgroundColor: '#f5f5f5',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HBoxStyled = styled(HBox)({
  alignItems: 'stretch',
});

type Props = {
  disabled?: boolean;
};

const ForestPage: React.FC<{ disabled?: boolean }> = observer((props: Props) => {
  const { disabled } = props;

  React.useEffect(
    () => {
      app.collapse = false;
    },
    [],
  );

  return (
    <HBoxStyled gap={0}>
      <MainContainer collapsed={+app.collapse}>
        {(app.loading || app.estates.loading)
          ? <AppPreloader />
          : (
            <>
              <ContentBox>
                {disabled && !gphQLApi.isAuth && <GuestArticle />}
                {disabled && gphQLApi.isAuth && app.estates.estates.length === 0 && <LoggedArticle />}
                <EstateTable
                  title={LangKey.HeaderMetsaniTitle.t()}
                  estates={app.estates}
                />
                {app.estates.estates.length !== 0 && <ResultContainer model={app.compareTable} />}
              </ContentBox>
              <AfterImportDialog />
            </>
          )}
      </MainContainer>
      <HiddenMobile>
        <RightContainer />
      </HiddenMobile>
    </HBoxStyled>
  );
});

export default ForestPage;
