import * as defaultRules from '@simosol/forms/lib/rules';
import { LangKey } from '../../lang/LangKey';

export const required = defaultRules.required(LangKey.MiscRequired);
export const isNumber = defaultRules.number(LangKey.MiscNotNumber);
export const min = (valueMin: number) => (value: any) => {
  if (!value) return undefined;
  const v = typeof value !== 'number' ? value.replace(/,/g, '.') : value;
  const valueIsNumber = defaultRules.number()(v) === undefined;
  if (valueIsNumber && Number(v) < valueMin) return `Min ${valueMin}`;
  return undefined;
};
export const max = (valueMax: number) => (value: any) => {
  if (!value) return undefined;
  const v = typeof value !== 'number' ? value.replace(/,/g, '.') : value;
  const valueIsNumber = defaultRules.number()(v) === undefined;
  if (valueIsNumber && Number(v) > valueMax) return `Max ${valueMax}`;
  return undefined;
};
