import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import { observer } from 'mobx-react-lite';
import PlanDescriptionRow from './PlanDescriptionRow';
import BlockRounded from '../../../common/BlockRounded';
import CompareTable, { EPlansNames, StandOperation } from '../../../../models/CompareTable';
import {
  PlanningYearOperationInfoGqlType,
  PlanningYearTreeStandInfoGqlType,
} from '../../../../generated/graphql';
import theme from '../../../../theme';
import UiText from '../../../../uiComponents/UiText';
import { moneyFlowStr } from '../../../../utils/stringUtils';
import { app } from '../../../../models/App';
import ComparePlanGraph from '../../estate/comparePlans/ComparePlanGraph';
import { useMobile } from '../../../../uiComponents/Responsive';
import { LangKey } from '../../../../lang/LangKey';

const BlockStyled = styled(BlockRounded)<{ mobile: number }>(({ mobile }) => ({
  margin: theme.spacing(mobile ? 2 : 4),
  overflow: 'hidden',
}));
const RowStyled = styled(HBox)(() => ({
  padding: theme.spacing(1),
  width: '100%',
  alignItems: 'center',
}));
const CellStyled = styled('div')<{ last?: 1 }>({
  flex: 1,
});
const Box = styled('div')<{
  mobile: number,
}>(({ mobile }) => ({
  margin: mobile ? 0 : theme.spacing(4),
}));

const VBoxStyled = styled(VBox)({
  '& li:nth-child(odd)': {
    background: theme.palette.divider,
  },
});

type Props = {
  model: CompareTable;
  simulationPlan: PlanningYearOperationInfoGqlType[];
  alternativePlan: PlanningYearOperationInfoGqlType[];
  currentTreeStand: PlanningYearTreeStandInfoGqlType[];
  alternativeTreeStand: PlanningYearTreeStandInfoGqlType[];
  planName: string;
};

const PlanCollapse: React.FunctionComponent<Props> = observer(({
  model, simulationPlan, alternativePlan, currentTreeStand, alternativeTreeStand, planName,
}) => {
  const isMobile = useMobile();
  const tableData: StandOperation[] = [];

  const { settings, endYear, getAltOperation } = model;
  const { startYear, period } = settings;

  const thisPeriodCurrentPlanOperations = simulationPlan
    .filter((p) => p.year >= startYear && p.year < endYear);
  const thisPeriodAlternativePlanOperations = alternativePlan
    .filter((p) => p.year >= startYear && p.year < endYear);

  const curPlanTotal = thisPeriodCurrentPlanOperations
    .reduce((sum, currentValue) => sum + currentValue.totalCashFlow, 0);
  const altPlanTotal = thisPeriodAlternativePlanOperations
    .reduce((sum, currentValue) => sum + currentValue.totalCashFlow, 0);

  for (let year = startYear; year < endYear; year += period) {
    app.estates.estates.forEach((estate) => {
      estate.stands?.forEach((stand) => {
        tableData.push({
          year,
          period,
          data: stand.data,
          activeOperation: getAltOperation(thisPeriodCurrentPlanOperations, year, stand.id),
          alternativeOperation: getAltOperation(thisPeriodAlternativePlanOperations, year, stand.id),
        });
      });
    });
  }

  const dataForGraph = app.compareTable.getAltPlanForGraph(
    currentTreeStand,
    alternativeTreeStand,
  );

  const cashFlowData = app.compareTable.getCashFlowDataForGraph(
    simulationPlan,
    alternativePlan,
  );

  const tableTitle = planName === EPlansNames.NATURE ? LangKey.ResultsPlanNature
    : planName === EPlansNames.CARBON
      ? LangKey.ResultsPlanCarbon
      : LangKey.ResultsPlanEconomic;

  return (
    <>
      <BlockStyled mobile={+isMobile}>
        <VBoxStyled>
          <ListItem dense style={{ background: theme.palette.divider }}>
            <RowStyled>
              <CellStyled>
                <UiText>{LangKey.ResultsPlanYear}</UiText>
              </CellStyled>
              <CellStyled>
                <UiText>{LangKey.ResultsPlanUserPlan}</UiText>
              </CellStyled>
              <CellStyled>
                <UiText>{tableTitle}</UiText>
              </CellStyled>
            </RowStyled>
          </ListItem>
          {thisPeriodCurrentPlanOperations.map((planData, i) => (
            <PlanDescriptionRow
              year={planData.year}
              period={period}
              stands={tableData.filter((data) => (data.year === planData.year && (
                data.activeOperation.length > 0 || data.alternativeOperation.length > 0)))}
              key={`${planData.year}-${i}`}
              isEven={i % 2 === 0}
            />
          ))}
          <ListItem
            dense
            style={{
              background: thisPeriodCurrentPlanOperations.length % 2 === 0 ? undefined : theme.palette.divider,
            }}
          >
            <RowStyled>
              <CellStyled>
                <UiText bold>{LangKey.ResultsPlanInTotal}</UiText>
              </CellStyled>
              <CellStyled>
                <UiText color={curPlanTotal < 0 ? 'red' : 'inherit'}>{moneyFlowStr(curPlanTotal)}</UiText>
              </CellStyled>
              <CellStyled>
                <UiText color={altPlanTotal < 0 ? 'red' : 'inherit'}>{moneyFlowStr(altPlanTotal)}</UiText>
              </CellStyled>
            </RowStyled>
          </ListItem>
        </VBoxStyled>
      </BlockStyled>
      <BlockStyled mobile={+isMobile}>
        <Box mobile={+isMobile}>
          <ComparePlanGraph
            width={isMobile ? document.documentElement.clientWidth - 64 : 750}
            height={isMobile ? 270 : 500}
            data={cashFlowData}
            title={LangKey.ComparePlansEconomic.t()}
          />
        </Box>
        <Box mobile={+isMobile}>
          <ComparePlanGraph
            width={isMobile ? document.documentElement.clientWidth - 64 : 750}
            height={isMobile ? 270 : 500}
            data={dataForGraph.carboneData}
            title={LangKey.ComparePlansCarbon.t()}
          />
        </Box>
        <Box mobile={+isMobile}>
          <ComparePlanGraph
            width={isMobile ? document.documentElement.clientWidth - 64 : 750}
            height={isMobile ? 270 : 500}
            data={dataForGraph.natureData}
            title={LangKey.ComparePlansNature.t()}
          />
        </Box>
      </BlockStyled>
    </>
  );
});

export default PlanCollapse;
