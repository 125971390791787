import i18next from 'i18next';
import i18nextXhrBackend from 'i18next-xhr-backend';

declare global {
  interface String {
    t(p?: object): string;
  }
}
// eslint-disable-next-line no-extend-native,func-names
String.prototype.t = function (this: string): string {
  return this;
};

const localStorageKey = 'locale';
const localStorageGet = () => localStorage.getItem(localStorageKey);
/* const localStorageSet = (locale: string) => {
  localStorage.setItem(localStorageKey, locale);
}; */

type LangItem<L extends string> = { locale: L, label: string };

export class Lang<L extends string> {
  private readonly allLanguages: LangItem<L>[];

  private readonly defaultLocale: L;

  constructor(allLanguages: LangItem<L>[], defaultLocale: L) {
    this.allLanguages = allLanguages;
    this.defaultLocale = defaultLocale;
  }

  get lang(): LangItem<L> {
    const localStorageLocale = localStorageGet();
    const targetLocale = localStorageLocale || this.defaultLocale;
    const item = this.allLanguages.find((i: LangItem<L>) => i.locale === targetLocale);
    const locale = item ? item.locale : this.defaultLocale;
    const label = item ? item.label : locale;
    return { locale, label };
  }

  get locale(): L {
    return this.lang.locale;
  }

  getAllLang = (): LangItem<L>[] => this.allLanguages.map((item: LangItem<L>) => ({ ...item }));

  init = async (onFinished: () => void) => i18next.use(i18nextXhrBackend)
    .init(
      {
        fallbackLng: 'fi',
        lng: this.locale,
        debug: false,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: './locales/{{lng}}.json',
          crossDomain: true,
        },
        nsSeparator: '|',
      },
      () => {
        // eslint-disable-next-line no-extend-native,func-names
        String.prototype.t = function (this: string, p?: object): string {
          return i18next.t(this.valueOf(), p) as string;
        };
        onFinished();
      },
    );

  /* change = (locale: L) => {
    localStorageSet(locale);
    location.reload();
  }; */
}

export type Locale = 'en' | 'fi' | 'sv';
export const lang = new Lang<Locale>(
  [
    { locale: 'en', label: 'English' },
    { locale: 'fi', label: 'Suomeksi' },
    { locale: 'sv', label: 'På svenska' },
  ],
  'fi',
);

export type LocaleValue = {
  locale: Locale;
  value: string;
};
