import * as React from 'react';
import IconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton';
import Page from '../models/Pages';
import { app } from '../models/App';
import Utils from '../lang/Utils';

export type IconButtonProps = {
  page?: Page;
} & MuiIconButtonProps;

const UiIconButton = (props: IconButtonProps) => {
  const {
    page, onClick, children, ...newProps
  } = props;
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e);
    if (page !== undefined) app.browser.page = page;
  };
  return (
    <IconButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...newProps}
      onClick={onButtonClick}
    >
      {Utils.translateStringChildren(children)}
    </IconButton>
  );
};

export default UiIconButton;
