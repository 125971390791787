import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import CommonStandsHeader from '../../common/stand/CommonStandsHeader';
import UiText from '../../../uiComponents/UiText';
import StandStepCard from './plans/StandStepCard';
import StandAlternativePlans from './plans/StandAlternativePlans';
import Estate from '../../../models/estates/Estate';
import Stand, { ETreatmentsNames } from '../../../models/stands/Stand';
import MainContainer from '../../common/MainContainer';
import StandInfo from './StandInfo';
import ScaleIcon from '../../common/icons/ScaleIcon';
import { LangKey } from '../../../lang/LangKey';

const NextStepsTitle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    letterSpacing: '0.05em',
  },
}));

type Props = {
  estate: Estate,
  stand: Stand,
};

const StandHeader: React.FC<Props> = (props: Props) => {
  const { estate, stand } = props;

  return (
    <CommonStandsHeader estate={estate} stand={stand}>
      <UiText variant="h3" div>{LangKey.EstatesHeader}</UiText>
      {stand.forestRegulation && (
        <HBox gap={8}>
          <ScaleIcon />
          <UiText variant="button" div>{LangKey.StandsHeader}</UiText>
        </HBox>
      )}
      <UiText div variant="caption">{stand.description}</UiText>

      <VBox gap={24}>
        <NextStepsTitle bold variant="h3" div>
          {LangKey.StandsNextStep}
        </NextStepsTitle>

        {stand.proposalTreatment.length > 0 && (
          <StandStepCard
            title={ETreatmentsNames.proposal}
            operations={stand.proposalTreatment}
          />
        )}
        <StandAlternativePlans stand={stand} />
        <MainContainer title={LangKey.StandsBasicInfo.t()} titleOnly>
          <StandInfo stand={stand} />
        </MainContainer>
      </VBox>
    </CommonStandsHeader>
  );
};

export default StandHeader;
