import * as React from 'react';
import MainContainer from '../../../common/MainContainer';
import StandsPatternsTable from './StandsPatternsTable';
import Estate from '../../../../models/estates/Estate';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  model: Estate,
};

const StandsPattern: React.FC<Props> = (props: Props) => {
  const { model } = props;

  return (
    <MainContainer
      title={`${model.name} ${LangKey.EstatesStand.t()}`}
      description={`${model.stands?.length} ${LangKey.EstatesStands.t()}`}
      dense
    >
      <StandsPatternsTable model={model} />
    </MainContainer>
  );
};

export default StandsPattern;
