import * as React from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Utils from '../lang/Utils';
import Page from '../models/Pages';
import { app } from '../models/App';

const ButtonStyled = styled(Button)({
  borderRadius: 32,
  minHeight: 32,
  lineHeight: 1,
  '& .MuiInputBase-root': {
    border: '1px solid #15A42E',
    borderRadius: 5,
    height: 32,
  },
});

const useStyles = makeStyles(() => createStyles({
  dense: {
    lineHeight: 1.2,
    minHeight: (props: ButtonProps) => {
      let size = 36.5;
      if (props.size === 'small') {
        size = 30.35;
      } else if (props.size === 'large') {
        size = 42.25;
      } else if (props.sizeLarger) {
        size = 52.25;
      }
      return size;
    },
    borderRadius: 32,
  },
  fullHeight: {
    height: '100%',
  },
}));

export type ButtonProps = MuiButtonProps & {
  page?: Page,
  loading?: boolean,
  div?: boolean,
  fullHeight?: boolean,
  sizeLarger?: boolean,
};

const UiButton = (props: ButtonProps) => {
  const {
    loading, page, onClick, endIcon, div, fullHeight, className, ...newProps
  } = props;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(e);
    if (page !== undefined && app.browser.page !== page) {
      app.browser.page = page;
    }
  };
  const disabled = newProps.disabled || loading;
  const newEndIcon = (
    <>
      {endIcon}
      {loading
        ? (
          <CircularProgress
            className="circularProgress"
            size={16}
            color="inherit"
          />
        )
        : null}
    </>
  );
  const hasEndIcon = endIcon || loading;
  const styleClasses = useStyles(props);
  const button = (
    <ButtonStyled
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...newProps}
      className={clsx(styleClasses.dense, { [styleClasses.fullHeight]: fullHeight }, className)}
      onClick={handleClick}
      disabled={disabled}
      endIcon={hasEndIcon ? newEndIcon : undefined}
    >
      {Utils.translateStringChildren(newProps.children)}
    </ButtonStyled>
  );

  if (div) return <div>{button}</div>;
  return button;
};

export default UiButton;
