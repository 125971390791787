const PROPS = `{
  label
  value
}`;

const NAME_PROPS = `{
  label
  name
  value
}`;

export { PROPS, NAME_PROPS };
