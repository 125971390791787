import * as React from 'react';
import { Tabs, Tab, styled } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import { observer } from 'mobx-react-lite';
import { AllCategory, ArticleLabels } from '../../../../models/article/Articles';
import { app } from '../../../../models/App';

type Props = {};

const TabStyled = styled(Tab)(({ theme }) => ({
  minHeight: '28px',
  height: '28px',
  width: 'fit-content',
  alignItems: 'flex-start',
  padding: 0,
  fontSize: '10px',
  fontWeight: 800,
  color: theme.palette.text.primary,
  '&.MuiTab-root': {
    minWidth: 'fit-content',
    alignItems: 'flex-start',
    marginRight: theme.spacing(4.5),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  '&.Mui-selected': {
    color: '#D98D3B',
    textDecoration: 'underline',
  },
  '& svg': {
    '&.MuiTab-iconWrapper': {
      marginRight: 0,
      width: 'fit-content',
      alignItems: 'flex-start',
    },
  },
}));

const TabFavorite = styled(TabStyled)(({ theme }) => ({
  '&.MuiTab-root': {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(-3.5),
  },
}));

const useStyles = makeStyles(() => createStyles({
  root: {
    flexWrap: 'wrap',
  },
  indicator: {
    display: 'none',
  },
}));

const ArticleTabs: React.FC<Props> = observer(() => {
  const { articles } = app;

  const styles = useStyles();
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    articles.currentCategory = newValue;
  };

  return (
    <Tabs
      value={articles.currentCategory}
      onChange={handleChange}
      classes={{
        flexContainer: styles.root,
        indicator: styles.indicator,
      }}
      color="secondary"
      variant="fullWidth"
    >
      <TabStyled label={ArticleLabels[AllCategory[0]].t()} />
      <TabFavorite
        label={ArticleLabels[AllCategory[1]].t()}
        icon={<StarIcon style={{ width: '10px', height: '10px' }} fontSize="small" />}
        iconPosition="start"
      />
      <TabStyled label={ArticleLabels[AllCategory[2]].t()} />
      <TabStyled label={ArticleLabels[AllCategory[3]].t()} />
      <TabStyled label={ArticleLabels[AllCategory[4]].t()} />
      <TabStyled label={ArticleLabels[AllCategory[5]].t()} />
      <TabStyled label={ArticleLabels[AllCategory[6]].t()} />
      <TabStyled label={ArticleLabels[AllCategory[7]].t()} />
    </Tabs>
  );
});

export default ArticleTabs;
