import { PROPS } from '../queries/common';

const STRATA = `{
  age
  basalArea
  id
  meanDiameter
  meanHeight
  number
  pulpWoodVolume
  sawLogPercent
  sawLogVolume
  species ${PROPS}
  stemCount
  storey ${PROPS}
  volume
  volumeGrowth
}`;

export default STRATA;
