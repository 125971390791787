import * as React from 'react';
import { styled } from '@mui/material';
import UiText from '../uiComponents/UiText';
import { moneyFlowStr } from '../utils/stringUtils';

const MoneyTitle = styled(UiText)(({ theme }) => ({
  letterSpacing: '0.05em',
  color: '#6D6D6D',
  fontSize: 12,
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: '8px',
    lineHeight: '11px',
  },
}));

const MoneyValue = styled(UiText)<{ umount: number }>(({ theme, umount }) => ({
  letterSpacing: '0.05em',
  color: umount < 0 ? 'red' : '#15A42E',
  fontSize: 14,
  fontWeight: 800,
  [theme.breakpoints.down('sm')]: {
    fontSize: '11px',
    lineHeight: '15px',
  },
}));

type Props = {
  title: string,
  umount: number,
};

const Money = (props: Props) => {
  const {
    title, umount,
  } = props;
  return (
    <>
      <MoneyTitle variant="overline">{title}</MoneyTitle>
      <MoneyValue variant="subtitle1" umount={umount}>
        {moneyFlowStr(umount)}
      </MoneyValue>
    </>
  );
};

export default Money;
