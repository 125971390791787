import { action, makeObservable, observable } from 'mobx';
import graphqlAPI from '../../api/GraphqlAPI';
import { Criteria, DGoal } from '../../api/mutations/setGoals';

export type GoalsState = 'new' | 'set' | 'changed';

class Goals {
  @observable.shallow goals?: DGoal[];

  @observable updating = false;

  @observable state: GoalsState = 'new';

  @action
    fromData = (data: number[]) => {
      if (data) {
        this.state = 'set';
        this.goals = [
          { weight: data[0], criteria: Criteria.economic },
          { weight: data[1], criteria: Criteria.carbon },
          { weight: data[2], criteria: Criteria.nature },
        ];
      }
    };

  constructor() {
    makeObservable(this);
  }

  @action
    setGoals = async (data: DGoal[]) => {
      this.updating = true;
      try {
        const goals: number[] = [];
        data.forEach((g) => goals.push(g.weight));
        await graphqlAPI.setGoals({ goals });
        this.goals = data;
      } finally {
        this.updating = false;
      }
    };

  @action
    getGoals = async () => {
      this.updating = true;
      try {
        const res = await graphqlAPI.getGoals();
        this.fromData(res.userGoals.goals);
      } catch (e) {
        console.log(e);
      } finally {
        this.updating = false;
      }
    };
}

export default Goals;
