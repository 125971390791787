import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { app } from 'models/App';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useForm } from '@simosol/forms';
import { styled, Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { VBox } from '@simosol/boxes';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import IconButton from '@mui/material/IconButton/IconButton';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import UiButton from '../uiComponents/UiButton';
import FormTextField from '../uiComponents/forms/FormTextField';
import useInstance from '../utils/useInstance';
import { required } from '../uiComponents/forms/FormRules';
import { LangKey } from '../lang/LangKey';

type Props = {
  open: boolean,
  onClose: () => void;
};

const LoginContentBox = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(12.5),
  paddingBottom: theme.spacing(12.5),
  cursor: 'inherit',
}));

const LoginActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  margin: theme.spacing(2, 4),
}));

const CancelButton = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(15),
  background: '#E8E9E9',
  color: '#3C3C3C',
}));

const LoginButton = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(15),
}));

const useStyles = makeStyles((theme: Theme) => createStyles({
  loginField: {
    width: theme.spacing(42),
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(32.5),
    },
  },
  inputField: {
    width: theme.spacing(40),
    rowGap: theme.spacing(5),
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  helper: {
    padding: theme.spacing(1, 2, 0),
    margin: 0,
    backgroundColor: 'white',
  },
}));

const LoginDialog = (props: Props) => {
  const { open, onClose } = props;
  const [loading, setLoading] = React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const styles = useStyles();

  const formData = useInstance(() => ({
    email: '',
    password: '',
  }));
  const form = useForm(
    formData,
    { email: required, password: required },
  );

  const handleLogin = async () => {
    /* window.open(
      'https://tapio-login.eu.auth0.com/login?state=hKFo2SAwQ1UzMFZjT1Nvdy1ZMllteTlvMWg3dERsWmJ5U2hIeKFupWxvZ2luo3RpZNkgVmEtdGhoVGVPczhIcEFOZXRzekQ5R251RXh4dkM4WlqjY2lk2SA3bVE3V1oxUzdnMksxMm5SanBhbW81U2NNY1BNNGkxQw&client=7mQ7WZ1S7g2K12nRjpamo5ScMcPM4i1C&protocol=oauth2&scope=openid%20email%20profile&nonce=516d5e90cee46da3c1fd5fd1525088fe8b723455c432bf993372129ef950ea60&response_type=code&response_mode=query&redirect_uri=https%3A%2F%2Fwww.metsalehti.fi%2Fwp%2Findex.php%3Fauth0%3D1',
      'blank',
    ); */
    if (!form.validateAll()) return;
    try {
      setLoading(true);
      await app.login(formData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <LoginContentBox>
        <VBox gap={16} className={styles.inputField}>
          <div>
            <FormTextField
              className={styles.loginField}
              loginField
              model={form}
              field="email"
              label={LangKey.MiscEmail.t()}
              size="medium"
              fullWidth
              required
              FormHelperTextProps={{ className: styles.helper }}
            />
          </div>
          <div>
            <FormTextField
              className={styles.loginField}
              loginField
              model={form}
              field="password"
              label={LangKey.MiscPassword.t()}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(true)}
                      onMouseDown={() => {
                        setShowPassword(false);
                      }}
                      size="large"
                    >
                      {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              FormHelperTextProps={{ className: styles.helper }}
            />
          </div>
        </VBox>
      </LoginContentBox>
      <LoginActions>
        <CancelButton loading={loading} variant="outlined" onClick={onClose}>
          {LangKey.ButtonsCancel.t()}
        </CancelButton>
        <LoginButton loading={loading} variant="contained" onClick={handleLogin} color="primary">
          {LangKey.ButtonsLogin.t()}
        </LoginButton>
      </LoginActions>
    </Dialog>
  );
};

export default LoginDialog;
