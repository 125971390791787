import * as React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stars from '@mui/icons-material/Stars';
import { observer } from 'mobx-react-lite';
import { app } from '../../../models/App';
import { useMobile } from '../../../uiComponents/Responsive';
import UiButton from '../../../uiComponents/UiButton';
import { LangKey } from '../../../lang/LangKey';

const ButtonStyled = styled(UiButton)({
  position: 'absolute',
  right: 0,
  top: 24,
});

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: 0,
  top: 24,
});

const GoalsButton = observer(() => {
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <IconButtonStyled onClick={app.estates.showShowGoals}>
        <Stars color="secondary" />
      </IconButtonStyled>
    );
  }

  return (
    <ButtonStyled
      variant="contained"
      color="primary"
      onClick={app.estates.showShowGoals}
    >
      {LangKey.ButtonsMyGoals.t()}
    </ButtonStyled>
  );
});

export default GoalsButton;
