import numeral from 'numeral';

export const round = (val: number): number => Math.round(val / 10) * 10;

export const humanNumber = (value: string | number, isInteger?: boolean) => {
  if (+value === 0) return 0;
  const num: number = +value;
  const str = `${num.toFixed(isInteger ? 0 : 2)}`;
  return str.replace('.', ',');
};

export const moneyFlowStr = (value: number, isRound: boolean = false, useSign = true) => {
  const fixedValue = isRound ? round(value) : value;
  const currency = numeral.localeData().currency.symbol;
  let sign = '';
  if (useSign) {
    if (value > 0) sign = '+'; else if (fixedValue < 0) sign = '-'; else sign = '';
  }
  return `${sign} ${humanNumber(Math.abs(fixedValue) as number, true)} ${currency}`;
};
