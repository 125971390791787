import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import { PropsWithChildren } from 'react';
import { useMobile } from '../../uiComponents/Responsive';

type Props = {} & PropsWithChildren;

const VBoxStyled = styled(VBox)(({ theme }) => ({
  padding: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const ContentBox: React.FC<Props> = ({ children }) => {
  const isMobile = useMobile();

  return (
    <VBoxStyled gap={isMobile ? 16 : 64}>
      {children}
    </VBoxStyled>
  );
};

export default ContentBox;
