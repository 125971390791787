import { PROPS } from '../queries/common';
import ASSORTMENTS from './assortments';
import SPECIFICATIONS from './specifications';

const OPERATION = `{
  additionalInfo
  assortments ${ASSORTMENTS}
  cashFlow
  dateExecuted
  dateStart
  harvestVolume
  harvestVolumeHa
  id
  meanCommercialStemVolumes {
    species ${PROPS}
    stemVolume
  }
  operationType ${PROPS}
  proposalType
  specifications ${SPECIFICATIONS}
  status
  yearPlanned
}`;

export const SIMPLE_OPERATION = `{
  id
  additionalInfo
  cashFlow
  operationType ${PROPS}
  stand {
    id
    number
    extension
    estate {
      id
      name
    }
  }
  yearPlanned
}`;

export const COMPARE_OPERATION = `{
  stand {
    id
    number
    extension
    estate {
      id
      name
    }
  }
  cashFlow
  operationType ${PROPS}
}`;

export const BADGE_OPERATION = `{
  cashFlow
  operationType ${PROPS}
  yearPlanned
  additionalInfo
  harvestVolume
  assortments ${ASSORTMENTS}
}`;

export default OPERATION;
