import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import { Collapse, styled } from '@mui/material';
import BlockRounded from '../../../common/BlockRounded';
import UiText from '../../../../uiComponents/UiText';
import useCollapse from '../../../../uiComponents/useCollapse';
import Stand, { ETreatmentsNames } from '../../../../models/stands/Stand';
import StandStepCard from './StandStepCard';
import { LangKey } from '../../../../lang/LangKey';

const Header = styled(HBox)(({
  width: '100%',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

const Root = styled(BlockRounded)(({ theme }) => ({
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    overflowX: 'hidden',
  },
}));

type Props = {
  stand: Stand,
};

const StandAlternativePlans: React.FC<Props> = ({ stand }) => {
  const { collapse, iconButton, toggleCollapse } = useCollapse();

  return (
    <Root>
      <Header onClick={toggleCollapse} align="center">
        <UiText bold variant="h5">{LangKey.StandsAlternativePlan}</UiText>
        {iconButton}
      </Header>
      <Collapse in={collapse}>
        <VBox gap={24}>
          {stand.economicTreatment.length > 0 && (
          <StandStepCard
            title={ETreatmentsNames.economic}
            operations={stand.economicTreatment}
          />
          )}
          {stand.natureTreatment.length > 0 && (
          <StandStepCard
            title={ETreatmentsNames.nature}
            operations={stand.natureTreatment}
          />
          )}
          {stand.carboneTreatment.length > 0 && (
          <StandStepCard
            title={ETreatmentsNames.carbone}
            operations={stand.carboneTreatment}
          />
          )}
        </VBox>
      </Collapse>
    </Root>
  );
};

export default StandAlternativePlans;
