import { VBox } from '@simosol/boxes';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import UiText from '../../uiComponents/UiText';
import Page from '../../models/Pages';
import Links from './Links';
import HBoxVBox from '../../uiComponents/HBoxVBox';
import { useMobile } from '../../uiComponents/Responsive';

type Props = {
  title: string;
  links: {
    page: Page,
    name: string;
    id: string;
  }[];
};

const HBoxStyled = styled(HBoxVBox)(({ theme }) => ({
  background: '#fff',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.success.main}`,
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(1),
  },
}));

const ElementStyled = styled(VBox)({
  flex: 1,
});

const PageTitle: React.FC<Props> = ({ title, links }) => {
  const isMobile = useMobile();
  return (
    <HBoxStyled
      vGap={8}
      style={{ width: isMobile ? undefined : 'inherit' }}
    >
      <ElementStyled>
        <Links links={links} />
      </ElementStyled>
      {!isMobile && <ElementStyled align="center"><UiText variant="h4">{title}</UiText></ElementStyled>}
      <ElementStyled />
    </HBoxStyled>
  );
};

export default PageTitle;
