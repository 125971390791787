import * as React from 'react';
import {
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import BlockRounded from '../../../../common/BlockRounded';
import { StandsPatternRowStyled, StandsPatternTableStyled } from '../StandsPatternStyles';
import StandsPatternHeadCell from '../StandsPatternHeadCell';
import { ReactText } from '../../../../../uiComponents/UiText';
import { ReactTableRow } from '../../../../../uiComponents/ReactTable';
import { getComparator, Order } from '../../../../../models/SortTable';
import Stand from '../../../../../models/stands/Stand';
import { StratumGqlType } from '../../../../../generated/graphql';
import { humanNumber } from '../../../../../utils/stringUtils';
import { valueFormatter } from '../../StandInfo';
import { LangKey } from '../../../../../lang/LangKey';

type Props = {
  stand: Stand,
  small?: boolean,
};

const BlockRoundedStyled = styled(BlockRounded)(({
  padding: 0,
  overflow: 'hidden',
}));

const InventoryTable: React.FC<Props> = (props: Props) => {
  const { stand, small } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof StratumGqlType>('id');

  if (!stand.selectedPlanComputedTreeStand) return null;
  const { strata } = stand.selectedPlanComputedTreeStand;

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof StratumGqlType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getTotal = (key: keyof StratumGqlType): number => strata
    .reduce((tot, row) => tot + Number(row[key]), 0) ?? 0;

  return (
    <BlockRoundedStyled>
      <TableContainer>
        <StandsPatternTableStyled>
          <TableHead>
            <StandsPatternRowStyled>
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableWood.t()}
                order={order}
                orderBy={orderBy}
                headCell="species"
                sortDirection={orderBy === 'species' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTablePeriod.t()}
                order={order}
                orderBy={orderBy}
                headCell="storey"
                sortDirection={orderBy === 'storey' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableAge.t()}
                textSecondary="vuosi"
                order={order}
                orderBy={orderBy}
                headCell="age"
                sortDirection={orderBy === 'age' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableFloorArea.t()}
                textSecondary="m2/ha"
                order={order}
                orderBy={orderBy}
                headCell="basalArea"
                sortDirection={orderBy === 'basalArea' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableFrameNumber.t()}
                textSecondary="kpl/ha"
                order={order}
                orderBy={orderBy}
                headCell="number"
                sortDirection={orderBy === 'number' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableLenght.t()}
                textSecondary="m"
                order={order}
                orderBy={orderBy}
                headCell="meanHeight"
                sortDirection={orderBy === 'meanHeight' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableDiameter.t()}
                textSecondary="cm"
                order={order}
                orderBy={orderBy}
                headCell="meanDiameter"
                sortDirection={orderBy === 'meanDiameter' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableVolume.t()}
                textSecondary="m3/ha"
                order={order}
                orderBy={orderBy}
                headCell="volume"
                sortDirection={orderBy === 'volume' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableLogPercent.t()}
                order={order}
                orderBy={orderBy}
                headCell="sawLogPercent"
                sortDirection={orderBy === 'sawLogPercent' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
              <StandsPatternHeadCell
                textPrimary={LangKey.StandsTableGrowth.t()}
                textSecondary="M3/ha/v"
                order={order}
                orderBy={orderBy}
                headCell="volumeGrowth"
                sortDirection={orderBy === 'volumeGrowth' ? order : false}
                onRequestSort={handleRequestSort}
                small={small}
              />
            </StandsPatternRowStyled>
          </TableHead>
          <TableBody>
            {strata.slice().sort(getComparator(order, orderBy)).map((rowData) => (
              <ReactTableRow key={rowData.id}>
                <TableCell><ReactText small={small}>{rowData.species.label}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{rowData.storey.label}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{rowData.age}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.basalArea))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.stemCount))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.meanHeight))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.meanDiameter))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.volume))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.sawLogPercent))}</ReactText></TableCell>
                <TableCell><ReactText small={small}>{valueFormatter(humanNumber(rowData.volumeGrowth))}</ReactText></TableCell>
              </ReactTableRow>
            ))}
            <ReactTableRow key={stand.id}>
              <TableCell><ReactText bold small={small}>Yhteensä</ReactText></TableCell>
              <TableCell />
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('age') / strata.length))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('basalArea')))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('stemCount')))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('meanHeight') / strata.length))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('meanDiameter') / strata.length))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('volume')))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('sawLogPercent')))}</ReactText></TableCell>
              <TableCell><ReactText small={small}>{valueFormatter(humanNumber(getTotal('volumeGrowth')))}</ReactText></TableCell>
            </ReactTableRow>
          </TableBody>
        </StandsPatternTableStyled>
      </TableContainer>
    </BlockRoundedStyled>
  );
};

export default InventoryTable;
