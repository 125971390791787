import * as React from 'react';
import StrataTableShort from './StrataTableShort';
import InventoryTable from './InventoryTable';
import { useMobile } from '../../../../../uiComponents/Responsive';
import Stand from '../../../../../models/stands/Stand';

type Props = {
  stand: Stand,
  small?: boolean,
};

const StrataTable: React.FC<Props> = (props: Props) => {
  const { stand, small } = props;

  if (!stand.selectedPlanComputedTreeStand || stand.selectedPlanComputedTreeStand.strata.length === 0) return null;

  const isMobile = useMobile();
  return isMobile
    ? <StrataTableShort rowData={stand.selectedPlanComputedTreeStand.strata} />
    : <InventoryTable stand={stand} small={small} />;
};

export default StrataTable;
