import * as React from 'react';
import { styled } from '@mui/material/styles';
import { HBox, VBox } from '@simosol/boxes';
import { Info } from '@mui/icons-material';
import UiText from '../../../../../uiComponents/UiText';
import UiIconButton from '../../../../../uiComponents/UiIconButton';
import BlockRounded from '../../../../common/BlockRounded';

type Props = {
  icon: React.ReactElement;
  title?: string;
  subtitle?: string;
  tooltip?: boolean;
};

const HBoxStyled = styled(HBox)({
  width: '100',
  justifyContent: 'space-between',
});

const BlockRoundedStyled = styled(BlockRounded)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StandFeature: React.FC<Props> = ({
  icon, title, subtitle, tooltip,
}) => (
  <BlockRoundedStyled>
    <HBoxStyled>
      <HBox gap={8} align="center">
        {icon}
        <VBox gap={0}>
          <UiText div variant="h5" bold>{title}</UiText>
          {subtitle && <UiText div variant="body1">{subtitle}</UiText>}
        </VBox>
      </HBox>
      {tooltip && (
        <UiIconButton>
          <Info color="success" />
        </UiIconButton>
      )}
    </HBoxStyled>
  </BlockRoundedStyled>
);

export default StandFeature;
