import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import { observer } from 'mobx-react-lite';
import UiText from '../../../../uiComponents/UiText';
import Operation from './Operation';
import MainContainer from '../../../common/MainContainer';
import { app } from '../../../../models/App';
import useCollapse from '../../../../uiComponents/useCollapse';
import gphQLApi from '../../../../api/GraphqlAPI';
import { LangKey } from '../../../../lang/LangKey';
import { Image } from '../../../Article';
import { useMobile } from '../../../../uiComponents/Responsive';

const HBoxStyled = styled(HBox)({
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: '#15A42E',
});

const PaginationStyled = styled(Pagination)({
  margin: 'auto 32px',
});

const Center = styled('div')({
  margin: '8px',
  display: 'flex',
  justifyContent: 'center',
});

const ShowMoreButton = styled(UiText)({
  textDecoration: 'underline',
});

const Operations: React.FC<{ disabled?: boolean }> = observer(({ disabled: disabledProp }) => {
  const { collapse, toggleCollapse, iconButton } = useCollapse({ color: 'primary', disabled: disabledProp });
  const isMobile = useMobile();

  const { nextOperations } = app;

  const disabled = !gphQLApi.isAuth || disabledProp;

  const handleChange = (_e: React.ChangeEvent<unknown>, page: number) => {
    nextOperations.currentPage = page;
  };

  return (
    <MainContainer title={(app.estates.estates.length !== 0 || app.browser.page.p !== 'forest') ? LangKey.OperationsTitle.t() : ''} titleOnly>
      {disabled && app.browser.page.p !== 'forest' && (
        <VBox gap={8}>
          <Image src="./temp/img/guest2.png" alt="Operation preview_1" isMobile={+isMobile} isFullWidth />
        </VBox>
      )}
      <VBox gap={8} style={{ marginBottom: 8 }}>
        {nextOperations.operationPerPage.slice(0, 3).map((operation) => (
          <Operation
            key={operation.id}
            operationType={operation.operationType.label}
            estateId={operation.stand.estate.id}
            estateName={operation.stand.estate.name}
            yearPlanned={operation.yearPlanned}
            cashFlow={operation.cashFlow}
            additionalInfo={operation.additionalInfo}
            disabled={disabled}
            withEstate
          />
        ))}
      </VBox>
      <Collapse in={collapse}>
        <VBox gap={8}>
          {nextOperations.operationPerPage.slice(3).map((operation) => (
            <Operation
              key={operation.id}
              operationType={operation.operationType.label}
              estateId={operation.stand.estate.id}
              estateName={operation.stand.estate.name}
              yearPlanned={operation.yearPlanned}
              cashFlow={operation.cashFlow}
              additionalInfo={operation.additionalInfo}
              disabled={disabled}
              withEstate
            />
          ))}
        </VBox>
        {nextOperations.nextOperation.length > 10 && (
          <Center>
            <PaginationStyled
              count={nextOperations.currentOperationPage}
              page={nextOperations.currentPage}
              showFirstButton
              showLastButton
              size="medium"
              color="primary"
              onChange={handleChange}
            />
          </Center>
        )}
      </Collapse>
      {nextOperations.operationPerPage.length > 2 && (
        <HBoxStyled onClick={disabled ? undefined : toggleCollapse}>
          <HBox align="center">
            <ShowMoreButton div variant="overline">
              {collapse
                ? LangKey.ButtonsShowMore.t()
                : LangKey.ButtonsShowLess.t()}
            </ShowMoreButton>
            {iconButton}
          </HBox>
        </HBoxStyled>
      )}
    </MainContainer>
  );
});

export default Operations;
