import { makeObservable, observable } from 'mobx';
import { OperationGqlType } from '../../generated/graphql';
import gphQLApi from '../../api/GraphqlAPI';

const OPERATION_PER_PAGE = 10;

class NextOperations {
  @observable.shallow
  private _nextOperation: OperationGqlType[] = [];

  @observable currentPage: number = 1;

  constructor() {
    makeObservable(this);
  }

  get nextOperation() {
    return this._nextOperation;
  }

  fromData = (op: OperationGqlType[]) => {
    this._nextOperation = op;
  };

  getData = async () => {
    const estatesNextOperationsGet = await gphQLApi.estatesNextOperationsGet();
    this.fromData(estatesNextOperationsGet.estatesNextOperationsForSelectedPlan.edges.map((o) => o.node));
  };

  get operationPerPage() {
    const start = (this.currentPage - 1) * OPERATION_PER_PAGE;
    const end = this.currentPage * OPERATION_PER_PAGE;
    return this._nextOperation.slice(start, end);
  }

  get currentOperationPage() {
    return ((this._nextOperation.length % OPERATION_PER_PAGE)
      ? Math.floor(this._nextOperation.length / OPERATION_PER_PAGE) + 1
      : Math.floor(this._nextOperation.length / OPERATION_PER_PAGE));
  }
}

export default NextOperations;
