import * as React from 'react';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { breakpointXXS } from '../theme';

export const HiddenDesktop = ({ children }: { children: React.ReactNode }) => (
  <Hidden mdUp>{children}</Hidden>
);

export const HiddenMobile = ({ children }: { children: React.ReactNode }) => (
  <Hidden mdDown>{children}</Hidden>
);

export const useMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const useSmallMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useExtraSmallMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpointXXS));
};
