export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  ExpectedError: any;
  GISScalar: any;
  GlobalID: any;
  float: any;
};

export type AccessibilityGqlType = {
  __typename?: 'AccessibilityGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type ArchiveOrDeleteMixinInput = {
  password: Scalars['String'];
};

export type AssortmentGqlType = Node & {
  __typename?: 'AssortmentGqlType';
  assortmentType?: Maybe<AssortmentTypeGqlType>;
  id: Scalars['GlobalID'];
  operation: OperationGqlType;
  percent?: Maybe<Scalars['Decimal']>;
  species?: Maybe<SpeciesGqlType>;
  volume?: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type AssortmentGqlTypeConnection = {
  __typename?: 'AssortmentGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<AssortmentGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type AssortmentGqlTypeEdge = {
  __typename?: 'AssortmentGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AssortmentGqlType;
};

export type AssortmentTypeGqlType = {
  __typename?: 'AssortmentTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type AuthMutation = {
  __typename?: 'AuthMutation';
  /**
   * Archive account and revoke refresh tokens.
   * User must be verified and confirm password.
   */
  archiveAccount: GqlAuthErrorMutationNormalOutput;
  /**
   * Delete account permanently or make `user.is_active=False`.
   *
   * The behavior is defined on settings.
   * Anyway user refresh tokens are revoked.
   *
   * User must be verified and confirm password.
   */
  deleteAccount: GqlAuthErrorMutationNormalOutput;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange: GqlAuthErrorObtainJsonWebTokenType;
  /**
   * Update user model fields, defined on settings.
   *
   * User must be verified.
   */
  updateAccount: GqlAuthErrorMutationNormalOutput;
};

export type AuthMutationArchiveAccountArgs = {
  input: ArchiveOrDeleteMixinInput;
};

export type AuthMutationDeleteAccountArgs = {
  input: ArchiveOrDeleteMixinInput;
};

export type AuthMutationPasswordChangeArgs = {
  input: PasswordChangeInput;
};

export type AuthMutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};

/** An enumeration. */
export enum CertificationTypeEnum {
  Fcs = 'FCS',
  Pefc = 'PEFC',
  PefcAndFcs = 'PEFC_AND_FCS',
}

export type DeadTreeStratumGqlType = Node & {
  __typename?: 'DeadTreeStratumGqlType';
  deadTreeType: DeadTreeTypeGqlType;
  id: Scalars['GlobalID'];
  meanDiameter?: Maybe<Scalars['Decimal']>;
  species: SpeciesGqlType;
  treeStand: TreeStandGqlType;
  volume?: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type DeadTreeStratumGqlTypeConnection = {
  __typename?: 'DeadTreeStratumGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<DeadTreeStratumGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type DeadTreeStratumGqlTypeEdge = {
  __typename?: 'DeadTreeStratumGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: DeadTreeStratumGqlType;
};

export type DeadTreeTypeGqlType = {
  __typename?: 'DeadTreeTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type DevelopmentClassGqlType = {
  __typename?: 'DevelopmentClassGqlType';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DjangoModelType = {
  __typename?: 'DjangoModelType';
  pk: Scalars['ID'];
};

export type DrainageStateGqlType = {
  __typename?: 'DrainageStateGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type EstateDeletePayload = EstateGqlType | OperationInfo;

export type EstateGqlType = Node & {
  __typename?: 'EstateGqlType';
  areaHa: Scalars['float'];
  areaM2: Scalars['float'];
  certification?: Maybe<CertificationTypeEnum>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  geometry?: Maybe<Scalars['GISScalar']>;
  id: Scalars['GlobalID'];
  lastSimulation?: Maybe<EstateSimulationGqlType>;
  municipality?: Maybe<MunicipalityGqlType>;
  name: Scalars['String'];
  planningYearOperationsSummaryForPlainPlans: Array<PlanningYearOperationSummaryGqlType>;
  planningYearOperationsSummaryForSelectedPlan: Array<PlanningYearOperationInfoGqlType>;
  planningYearTreeStandsSummaryForPlainPlans: Array<PlanningYearTreeStandSummaryGqlType>;
  planningYearTreeStandsSummaryForSelectedPlan: Array<PlanningYearTreeStandInfoGqlType>;
  selectedPlanEconomyCriteriaImprovement: Scalars['float'];
  stands: Array<StandGqlType>;
  updatedAt: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type EstateGqlTypeConnection = {
  __typename?: 'EstateGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<EstateGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type EstateGqlTypeEdge = {
  __typename?: 'EstateGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: EstateGqlType;
};

export type EstateSimulationGqlType = Node & {
  __typename?: 'EstateSimulationGqlType';
  createdAt: Scalars['DateTime'];
  estate: EstateGqlType;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['GlobalID'];
  status: SimulationStatusEnum;
};

export type EstateSimulationResultGqlType = {
  __typename?: 'EstateSimulationResultGqlType';
  estates: Array<EstateGqlType>;
};

/** Input data for `estatesSimulationMutation` mutation */
export type EstatesSimulationMutationInput = {
  estateIds: Array<Scalars['GlobalID']>;
};

export type EstatesSimulationMutationPayload = EstateSimulationResultGqlType | OperationInfo;

export type ExtendedVerifyTokenType = OutputInterface & {
  __typename?: 'ExtendedVerifyTokenType';
  errors?: Maybe<Scalars['ExpectedError']>;
  success: Scalars['Boolean'];
  token?: Maybe<TokenType>;
  user?: Maybe<UserType>;
};

export type FertilityClassGqlType = {
  __typename?: 'FertilityClassGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type GqlAuthError = {
  __typename?: 'GQLAuthError';
  code: GqlAuthErrors;
  message?: Maybe<Scalars['String']>;
};

export type GqlAuthErrorAuthMutation = AuthMutation | GqlAuthError;

export type GqlAuthErrorMutationNormalOutput = GqlAuthError | MutationNormalOutput;

export type GqlAuthErrorMyAuthorizedQueries = GqlAuthError | MyAuthorizedQueries;

export type GqlAuthErrorObtainJsonWebTokenType = GqlAuthError | ObtainJsonWebTokenType;

/** An enumeration. */
export enum GqlAuthErrors {
  ExpiredToken = 'EXPIRED_TOKEN',
  InvalidToken = 'INVALID_TOKEN',
  NotVerified = 'NOT_VERIFIED',
  NoSufficientPermissions = 'NO_SUFFICIENT_PERMISSIONS',
  Unauthenticated = 'UNAUTHENTICATED',
}

/** Input data for `importOpenData` mutation */
export type ImportOpenDataInput = {
  certification?: InputMaybe<CertificationTypeEnum>;
  estateCode: Scalars['String'];
  needSimulate?: InputMaybe<Scalars['Boolean']>;
  planningArea?: InputMaybe<Scalars['String']>;
  simulationType?: InputMaybe<SimulationTypeEnum>;
};

export type ImportOpenDataPayload = ImportOpenDataResultGqlType | OperationInfo;

export type ImportOpenDataResultGqlType = {
  __typename?: 'ImportOpenDataResultGqlType';
  estates: Array<EstateGqlType>;
};

export type MainGroupGqlType = {
  __typename?: 'MainGroupGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type MeanCommercialStemVolumeGqlType = Node & {
  __typename?: 'MeanCommercialStemVolumeGqlType';
  id: Scalars['GlobalID'];
  operation: OperationGqlType;
  species?: Maybe<SpeciesGqlType>;
  stemVolume?: Maybe<Scalars['float']>;
};

/** A connection to a list of items. */
export type MeanCommercialStemVolumeGqlTypeConnection = {
  __typename?: 'MeanCommercialStemVolumeGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<MeanCommercialStemVolumeGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MeanCommercialStemVolumeGqlTypeEdge = {
  __typename?: 'MeanCommercialStemVolumeGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: MeanCommercialStemVolumeGqlType;
};

export type MunicipalityGqlType = {
  __typename?: 'MunicipalityGqlType';
  label: Scalars['String'];
  region: Scalars['Int'];
  value: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authEntry: GqlAuthErrorAuthMutation;
  estateDelete: EstateDeletePayload;
  estatesSimulationMutation: EstatesSimulationMutationPayload;
  importOpenData: ImportOpenDataPayload;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset: MutationNormalOutput;
  /**
   * Set user password - for password-less registration
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, set
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordSet: MutationNormalOutput;
  presentStateSimulationMutation: PresentStateSimulationMutationPayload;
  /**
   * ### refreshToken to generate a new login token:
   * *Use this only if `JWT_LONG_RUNNING_REFRESH_TOKEN` is True*
   *
   * using the refresh-token you already got during authorization, and
   * obtain a brand-new token (and possibly a new refresh token if you revoked the previous).
   * This is an alternative to log in when your token expired.
   */
  refreshToken: ObtainJsonWebTokenType;
  /**
   * Register user with fields defined in the settings.
   * If the email field of the user model is part of the
   * registration fields (default), check if there is
   * no user with that email.
   *
   * If it exists, it does not register the user,
   * even if the email field is not defined as unique
   * (default of the default django user model).
   *
   * When creating the user, it also creates a `UserStatus`
   * related to that user, making it possible to track
   * if the user is archived / verified.
   *
   * Send account verification email.
   *
   * If allowed to not verified users login, return token.
   */
  register: MutationNormalOutput;
  /**
   * Sends activation email.
   *
   * It is called resend because theoretically
   * the first activation email was sent when
   * the user registered.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  resendActivationEmail: MutationNormalOutput;
  /**
   * ### Suspends a refresh token.
   * *token must exist to be revoked.*
   */
  revokeToken: RevokeRefreshTokenType;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail: MutationNormalOutput;
  standsDelete: StandsDeletePayload;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * The fields are defined on settings.
   *
   * Not verified users can log in by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchived and
   * return `unarchiving=True` on OutputBase.
   */
  tokenAuth: ObtainJsonWebTokenType;
  userGoalsSet: UserGoalsSetPayload;
  /**
   * Verify user account.
   *
   * Receive the token that was sent by email.
   * If the token is valid, make the user verified
   * by making the `user.status.verified` field true.
   */
  verifyAccount: MutationNormalOutput;
  verifyToken: ExtendedVerifyTokenType;
};

export type MutationEstateDeleteArgs = {
  input: NodeInput;
};

export type MutationEstatesSimulationMutationArgs = {
  input: EstatesSimulationMutationInput;
};

export type MutationImportOpenDataArgs = {
  input: ImportOpenDataInput;
};

export type MutationPasswordResetArgs = {
  input: PasswordResetInput;
};

export type MutationPasswordSetArgs = {
  input: PasswordSetInput;
};

export type MutationPresentStateSimulationMutationArgs = {
  input: PresentStateSimulationMutationInput;
};

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationRegisterArgs = {
  input: RegisterInput;
};

export type MutationResendActivationEmailArgs = {
  input: ResendActivationEmailInput;
};

export type MutationRevokeTokenArgs = {
  input: RevokeTokenInput;
};

export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

export type MutationStandsDeleteArgs = {
  input: NodeInput;
};

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};

export type MutationUserGoalsSetArgs = {
  input: UserGoalsSetInput;
};

export type MutationVerifyAccountArgs = {
  input: VerifyAccountInput;
};

export type MutationVerifyTokenArgs = {
  input: VerifyTokenInput;
};

export type MutationNormalOutput = {
  __typename?: 'MutationNormalOutput';
  errors?: Maybe<Scalars['ExpectedError']>;
  success: Scalars['Boolean'];
};

export type MyAuthorizedQueries = {
  __typename?: 'MyAuthorizedQueries';
  me?: Maybe<UserType>;
  publicUser?: Maybe<UserType>;
  securedString: Scalars['String'];
};

/** An object with a Globally Unique ID */
export type Node = {
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID'];
};

/** Input of an object that implements the `Node` interface. */
export type NodeInput = {
  id: Scalars['GlobalID'];
};

export type ObtainJsonWebTokenInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

/**
 * encapsulates token data, and refresh token data if `JWT_LONG_RUNNING_REFRESH_TOKEN` is on.
 * with an output interface.
 */
export type ObtainJsonWebTokenType = OutputInterface & {
  __typename?: 'ObtainJSONWebTokenType';
  errors?: Maybe<Scalars['ExpectedError']>;
  refreshToken?: Maybe<RefreshTokenType>;
  success: Scalars['Boolean'];
  token?: Maybe<TokenType>;
  user?: Maybe<UserType>;
};

export type OperationComputedPlanGqlType = {
  __typename?: 'OperationComputedPlanGqlType';
  operations: Array<OperationGqlType>;
  treatmentType: TreatmentTypeEnum;
};

export type OperationGqlType = Node & {
  __typename?: 'OperationGqlType';
  additionalInfo: Scalars['String'];
  assortments: Array<AssortmentGqlType>;
  cashFlow?: Maybe<Scalars['float']>;
  dateExecuted?: Maybe<Scalars['Date']>;
  dateStart?: Maybe<Scalars['Date']>;
  harvestVolume?: Maybe<Scalars['float']>;
  harvestVolumeHa?: Maybe<Scalars['float']>;
  id: Scalars['GlobalID'];
  meanCommercialStemVolumes: Array<MeanCommercialStemVolumeGqlType>;
  operationType: OperationTypeGqlType;
  proposalType?: Maybe<OperationProposalTypeEnum>;
  specifications: Array<SpecificationGqlType>;
  stand: StandGqlType;
  status?: Maybe<OperationStatusEnum>;
  yearPlanned?: Maybe<Scalars['Int']>;
};

/** A connection to a list of items. */
export type OperationGqlTypeConnection = {
  __typename?: 'OperationGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<OperationGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type OperationGqlTypeEdge = {
  __typename?: 'OperationGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: OperationGqlType;
};

/** Multiple messages returned by an operation. */
export type OperationInfo = {
  __typename?: 'OperationInfo';
  /** List of messages returned by the operation. */
  messages: Array<OperationMessage>;
};

/** An error that happened while executing an operation. */
export type OperationMessage = {
  __typename?: 'OperationMessage';
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The kind of this message. */
  kind: OperationMessageKind;
  /** The error message. */
  message: Scalars['String'];
};

/** The kind of the returned message. */
export enum OperationMessageKind {
  Error = 'ERROR',
  Info = 'INFO',
  Permission = 'PERMISSION',
  Validation = 'VALIDATION',
  Warning = 'WARNING',
}

/** An enumeration. */
export enum OperationProposalTypeEnum {
  Field = 'FIELD',
  Simulated = 'SIMULATED',
}

/** An enumeration. */
export enum OperationStatusEnum {
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Planning = 'PLANNING',
  Unassigned = 'UNASSIGNED',
}

export type OperationTypeGqlType = {
  __typename?: 'OperationTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type OutputInterface = {
  errors?: Maybe<Scalars['ExpectedError']>;
  success: Scalars['Boolean'];
};

/** Information to aid in pagination. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PasswordChangeInput = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type PasswordResetInput = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordSetInput = {
  newPassword1: Scalars['String'];
  newPassword2: Scalars['String'];
  token: Scalars['String'];
};

export type PlanningAreaGqlType = Node & {
  __typename?: 'PlanningAreaGqlType';
  id: Scalars['GlobalID'];
  name: Scalars['String'];
  stand: StandGqlType;
};

/** A connection to a list of items. */
export type PlanningAreaGqlTypeConnection = {
  __typename?: 'PlanningAreaGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<PlanningAreaGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type PlanningAreaGqlTypeEdge = {
  __typename?: 'PlanningAreaGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: PlanningAreaGqlType;
};

export type PlanningYearOperationInfoGqlType = {
  __typename?: 'PlanningYearOperationInfoGqlType';
  operations: Array<OperationGqlType>;
  totalCashFlow: Scalars['float'];
  year: Scalars['Int'];
};

export type PlanningYearOperationSummaryGqlType = {
  __typename?: 'PlanningYearOperationSummaryGqlType';
  treatmentType: TreatmentTypeEnum;
  yearInfo: Array<PlanningYearOperationInfoGqlType>;
};

export type PlanningYearTreeStandInfoGqlType = {
  __typename?: 'PlanningYearTreeStandInfoGqlType';
  totalCarbonStorage: Scalars['float'];
  totalNatureValueIndex: Scalars['float'];
  treeStands: Array<TreeStandGqlType>;
  year: Scalars['Int'];
};

export type PlanningYearTreeStandSummaryGqlType = {
  [x: string]: any;
  __typename?: 'PlanningYearTreeStandSummaryGqlType';
  treatmentType: TreatmentTypeEnum;
  yearInfo: Array<PlanningYearTreeStandInfoGqlType>;
};

/** Input data for `presentStateSimulationMutation` mutation */
export type PresentStateSimulationMutationInput = {
  standIds: Array<Scalars['GlobalID']>;
};

export type PresentStateSimulationMutationPayload = OperationInfo | PresentStateSimulationResultGqlType;

export type PresentStateSimulationResultGqlType = {
  __typename?: 'PresentStateSimulationResultGqlType';
  stands: Array<StandGqlType>;
};

export type Query = {
  __typename?: 'Query';
  assortmentConnection: AssortmentGqlTypeConnection;
  assortmentNode?: Maybe<AssortmentGqlType>;
  authEntry: GqlAuthErrorMyAuthorizedQueries;
  deadTreeStrataConnection: DeadTreeStratumGqlTypeConnection;
  deadTreeStrataNode?: Maybe<DeadTreeStratumGqlType>;
  estateConnection: EstateGqlTypeConnection;
  estateNode?: Maybe<EstateGqlType>;
  estatesNextOperationsForSelectedPlan: OperationGqlTypeConnection;
  estatesTotalEconomyCriteriaImprovementForSelectedPlan: Scalars['float'];
  meanCommercialStemVolumeConnection: MeanCommercialStemVolumeGqlTypeConnection;
  meanCommercialStemVolumeNode?: Maybe<MeanCommercialStemVolumeGqlType>;
  node?: Maybe<Node>;
  operationConnection: OperationGqlTypeConnection;
  operationNode?: Maybe<OperationGqlType>;
  planningAreaConnection: PlanningAreaGqlTypeConnection;
  planningAreaNode?: Maybe<PlanningAreaGqlType>;
  planningYearOperationsSummaryForPlainPlans: Array<PlanningYearOperationSummaryGqlType>;
  planningYearOperationsSummaryForSelectedPlan: Array<PlanningYearOperationInfoGqlType>;
  planningYearTreeStandsSummaryForPlainPlans: Array<PlanningYearTreeStandSummaryGqlType>;
  planningYearTreeStandsSummaryForSelectedPlan: Array<PlanningYearTreeStandInfoGqlType>;
  restrictionConnection: RestrictionGqlTypeConnection;
  restrictionNode?: Maybe<RestrictionGqlType>;
  specialFeatureConnection: SpecialFeatureGqlTypeConnection;
  specialFeatureNode?: Maybe<SpecialFeatureGqlType>;
  specificationConnection: SpecificationGqlTypeConnection;
  specificationNode?: Maybe<SpecificationGqlType>;
  standConnection: StandGqlTypeConnection;
  standNode?: Maybe<StandGqlType>;
  strataConnection: StratumGqlTypeConnection;
  strataNode?: Maybe<StratumGqlType>;
  treeStandConnection: TreeStandGqlTypeConnection;
  treeStandNode?: Maybe<TreeStandGqlType>;
  userGoals?: Maybe<UserGoalGqlType>;
};

export type QueryAssortmentConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryAssortmentNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryDeadTreeStrataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryDeadTreeStrataNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryEstateConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryEstateNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryEstatesNextOperationsForSelectedPlanArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryMeanCommercialStemVolumeConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryMeanCommercialStemVolumeNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryOperationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryOperationNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryPlanningAreaConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryPlanningAreaNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryRestrictionConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryRestrictionNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QuerySpecialFeatureConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerySpecialFeatureNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QuerySpecificationConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerySpecificationNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryStandConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryStandNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryStrataConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryStrataNodeArgs = {
  id: Scalars['GlobalID'];
};

export type QueryTreeStandConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryTreeStandNodeArgs = {
  id: Scalars['GlobalID'];
};

/** If `revoke_refresh_token` is true, revokes to refresh token an creates a new one. */
export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
  /** revokes the previous refresh token, and will generate new one. */
  revokeRefreshToken?: Scalars['Boolean'];
};

/**
 * Refresh token can be used to obtain a new token instead of log in again
 * when the token expires.
 *
 * *This is only used if `JWT_LONG_RUNNING_REFRESH_TOKEN` is set to True.*
 */
export type RefreshTokenType = {
  __typename?: 'RefreshTokenType';
  created: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  isExpired: Scalars['Boolean'];
  revoked?: Maybe<Scalars['DateTime']>;
  /** randomly generated token that is attached to a FK user. */
  token: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
};

export type ResendActivationEmailInput = {
  email: Scalars['String'];
};

export type RestrictionGqlType = Node & {
  __typename?: 'RestrictionGqlType';
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['GlobalID'];
  restrictionType: RestrictionTypeGqlType;
  stand: StandGqlType;
};

/** A connection to a list of items. */
export type RestrictionGqlTypeConnection = {
  __typename?: 'RestrictionGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<RestrictionGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type RestrictionGqlTypeEdge = {
  __typename?: 'RestrictionGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: RestrictionGqlType;
};

export type RestrictionTypeGqlType = {
  __typename?: 'RestrictionTypeGqlType';
  code: Scalars['Int'];
  group: Scalars['Int'];
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type RevokeRefreshTokenType = {
  __typename?: 'RevokeRefreshTokenType';
  errors?: Maybe<Scalars['ExpectedError']>;
  refreshToken?: Maybe<RefreshTokenType>;
  success: Scalars['Boolean'];
};

export type RevokeTokenInput = {
  refreshToken: Scalars['String'];
};

export type SendPasswordResetEmailInput = {
  email: Scalars['String'];
};

/** An enumeration. */
export enum SimulationStatusEnum {
  Done = 'DONE',
  Failed = 'FAILED',
  Running = 'RUNNING',
}

/** An enumeration. */
export enum SimulationTypeEnum {
  Estate = 'ESTATE',
  PresentState = 'PRESENT_STATE',
}

export type SoilTypeGqlType = {
  __typename?: 'SoilTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type SpecialFeatureAdditionalCodeGqlType = {
  __typename?: 'SpecialFeatureAdditionalCodeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type SpecialFeatureCodeGqlType = {
  __typename?: 'SpecialFeatureCodeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type SpecialFeatureGqlType = Node & {
  __typename?: 'SpecialFeatureGqlType';
  endDate?: Maybe<Scalars['Date']>;
  featureAdditionalCode?: Maybe<SpecialFeatureAdditionalCodeGqlType>;
  featureCode?: Maybe<SpecialFeatureCodeGqlType>;
  featureType?: Maybe<SpecialFeatureTypeGqlType>;
  id: Scalars['GlobalID'];
  stand: StandGqlType;
  startDate?: Maybe<Scalars['Date']>;
};

/** A connection to a list of items. */
export type SpecialFeatureGqlTypeConnection = {
  __typename?: 'SpecialFeatureGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<SpecialFeatureGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SpecialFeatureGqlTypeEdge = {
  __typename?: 'SpecialFeatureGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: SpecialFeatureGqlType;
};

export type SpecialFeatureTypeGqlType = {
  __typename?: 'SpecialFeatureTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type SpeciesGqlType = {
  __typename?: 'SpeciesGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type SpecificationGqlType = Node & {
  __typename?: 'SpecificationGqlType';
  id: Scalars['GlobalID'];
  operation: OperationGqlType;
  specificationType: SpecificationTypeGqlType;
};

/** A connection to a list of items. */
export type SpecificationGqlTypeConnection = {
  __typename?: 'SpecificationGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<SpecificationGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SpecificationGqlTypeEdge = {
  __typename?: 'SpecificationGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: SpecificationGqlType;
};

export type SpecificationTypeGqlType = {
  __typename?: 'SpecificationTypeGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type StandGqlType = Node & {
  __typename?: 'StandGqlType';
  accessibility?: Maybe<AccessibilityGqlType>;
  /** Tree stands for Economic plan for years in range [year_zero, +5, +10, +20] */
  activePlanComputedTreeStands: Array<TreeStandGqlType>;
  areaHa: Scalars['float'];
  areaM2: Scalars['float'];
  dataDate?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  developmentClass?: Maybe<DevelopmentClassGqlType>;
  ditchingYear?: Maybe<Scalars['Int']>;
  drainageState?: Maybe<DrainageStateGqlType>;
  estate: EstateGqlType;
  extension: Scalars['String'];
  fertilityClass?: Maybe<FertilityClassGqlType>;
  fieldProposalOperations: Array<OperationGqlType>;
  geometry: Scalars['GISScalar'];
  goalText: Scalars['String'];
  hasForestRegulation: Scalars['Boolean'];
  id: Scalars['GlobalID'];
  inventoryTreeStand?: Maybe<TreeStandGqlType>;
  isValid: Scalars['Boolean'];
  mainGroup: MainGroupGqlType;
  mainTreeSpecies?: Maybe<SpeciesGqlType>;
  needUpdatePresentState: Scalars['Boolean'];
  number: Scalars['Int'];
  operationComputedPlans: Array<OperationComputedPlanGqlType>;
  optimizedTreatmentType?: Maybe<TreatmentTypeEnum>;
  ownText: Scalars['String'];
  parcelId: Scalars['String'];
  parcelNumber?: Maybe<Scalars['Int']>;
  planningAreas: Array<PlanningAreaGqlType>;
  publicText: Scalars['String'];
  quality?: Maybe<StandQualityGqlType>;
  restrictions: Array<RestrictionGqlType>;
  selectedPlanComputedTreeStand?: Maybe<TreeStandGqlType>;
  selectedPlanEconomyCriteriaImprovement: Scalars['float'];
  selectedPlanEstimatedNatureValue?: Maybe<Scalars['float']>;
  selectedPlanEstimatedTotalVolume?: Maybe<Scalars['float']>;
  selectedPlanEstimatedTreeCarbonFlux?: Maybe<Scalars['float']>;
  selectedPlanEstimatedValue?: Maybe<Scalars['float']>;
  selectedPlanNextOperations: Array<OperationGqlType>;
  selectedTreatmentType: TreatmentTypeEnum;
  simulationErrors: Scalars['String'];
  soilType?: Maybe<SoilTypeGqlType>;
  specialFeatures: Array<SpecialFeatureGqlType>;
  subGroup?: Maybe<SubGroupGqlType>;
  treeStandComputedPlans: Array<TreeStandComputedPlanGqlType>;
  validationErrors: Scalars['String'];
};

/** A connection to a list of items. */
export type StandGqlTypeConnection = {
  __typename?: 'StandGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<StandGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type StandGqlTypeEdge = {
  __typename?: 'StandGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StandGqlType;
};

export type StandQualityGqlType = {
  __typename?: 'StandQualityGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type StandsDeletePayload = OperationInfo | StandGqlType;

export type StoreyGqlType = {
  __typename?: 'StoreyGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type StratumGqlType = Node & {
  __typename?: 'StratumGqlType';
  age: Scalars['float'];
  basalArea?: Maybe<Scalars['Decimal']>;
  id: Scalars['GlobalID'];
  meanDiameter?: Maybe<Scalars['Decimal']>;
  meanHeight?: Maybe<Scalars['Decimal']>;
  number: Scalars['Int'];
  pulpWoodVolume?: Maybe<Scalars['Decimal']>;
  sawLogPercent?: Maybe<Scalars['Decimal']>;
  sawLogVolume?: Maybe<Scalars['Decimal']>;
  species: SpeciesGqlType;
  stemCount?: Maybe<Scalars['float']>;
  storey: StoreyGqlType;
  treeStand: TreeStandGqlType;
  volume?: Maybe<Scalars['Decimal']>;
  volumeGrowth?: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type StratumGqlTypeConnection = {
  __typename?: 'StratumGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<StratumGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type StratumGqlTypeEdge = {
  __typename?: 'StratumGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: StratumGqlType;
};

export type SubGroupGqlType = {
  __typename?: 'SubGroupGqlType';
  label: Scalars['String'];
  value: Scalars['Int'];
};

/** the data that was used to create the token. */
export type TokenPayloadType = {
  __typename?: 'TokenPayloadType';
  email: Scalars['String'];
  /** when the token will be expired */
  exp: Scalars['DateTime'];
  /** when the token was created */
  origIat: Scalars['DateTime'];
};

/** encapsulates the token with the payload that was used to create the token. */
export type TokenType = {
  __typename?: 'TokenType';
  payload: TokenPayloadType;
  /** The encoded payload, namely a token. */
  token: Scalars['String'];
};

/** An enumeration. */
export enum TreatmentTypeEnum {
  Carbon = 'CARBON',
  Economic = 'ECONOMIC',
  EconomicField = 'ECONOMIC_FIELD',
  Nature = 'NATURE',
  PureField = 'PURE_FIELD',
}

export type TreeStandComputedPlanGqlType = {
  __typename?: 'TreeStandComputedPlanGqlType';
  treatmentType: TreatmentTypeEnum;
  treeStands: Array<TreeStandGqlType>;
};

export type TreeStandGqlType = Node & {
  __typename?: 'TreeStandGqlType';
  age?: Maybe<Scalars['float']>;
  basalArea?: Maybe<Scalars['float']>;
  carbonStorage?: Maybe<Scalars['float']>;
  dataDate: Scalars['Date'];
  deadTreeStrata: Array<DeadTreeStratumGqlType>;
  developmentClass?: Maybe<DevelopmentClassGqlType>;
  id: Scalars['GlobalID'];
  mainSpecies?: Maybe<SpeciesGqlType>;
  meanDiameter?: Maybe<Scalars['float']>;
  meanHeight?: Maybe<Scalars['float']>;
  natureValue?: Maybe<Scalars['float']>;
  productiveValue?: Maybe<Scalars['float']>;
  pulpWoodVolume?: Maybe<Scalars['float']>;
  sawLogVolume?: Maybe<Scalars['float']>;
  soilCarbonStorage?: Maybe<Scalars['float']>;
  stand: StandGqlType;
  stemCount?: Maybe<Scalars['float']>;
  strata: Array<StratumGqlType>;
  treeCarbonFlux?: Maybe<Scalars['float']>;
  treeCarbonStorage?: Maybe<Scalars['float']>;
  treeStandType?: Maybe<TreeStandTypeEnum>;
  value?: Maybe<Scalars['float']>;
  valueGrowthPercent?: Maybe<Scalars['float']>;
  volume?: Maybe<Scalars['float']>;
  volumeGrowth?: Maybe<Scalars['float']>;
};

/** A connection to a list of items. */
export type TreeStandGqlTypeConnection = {
  __typename?: 'TreeStandGqlTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<TreeStandGqlTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type TreeStandGqlTypeEdge = {
  __typename?: 'TreeStandGqlTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: TreeStandGqlType;
};

/** An enumeration. */
export enum TreeStandTypeEnum {
  Estimate = 'ESTIMATE',
  Inventory = 'INVENTORY',
  Projection = 'PROJECTION',
}

export type UpdateAccountInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserGoalGqlType = {
  __typename?: 'UserGoalGqlType';
  goals: Array<Scalars['Int']>;
};

/** Input data for `userGoalsSet` mutation */
export type UserGoalsSetInput = {
  goals: Array<Scalars['Int']>;
};

export type UserGoalsSetPayload = OperationInfo | UserGoalGqlType;

export type UserStatusType = {
  __typename?: 'UserStatusType';
  archived: Scalars['Boolean'];
  verified: Scalars['Boolean'];
};

export type UserType = {
  __typename?: 'UserType';
  archived: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  logentrySet: Array<DjangoModelType>;
  status?: Maybe<UserStatusType>;
  verified: Scalars['Boolean'];
};

export type VerifyAccountInput = {
  token: Scalars['String'];
};

export type VerifyTokenInput = {
  token: Scalars['String'];
};
