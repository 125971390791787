import * as React from 'react';
import { styled } from '@mui/material/styles';
import BlockRounded from '../../../../common/BlockRounded';
import InventoryTableShortRow from './InventoryTableShortRow';
import { StratumGqlType } from '../../../../../generated/graphql';

type Props = {
  rowData: StratumGqlType[],
};

const BlockRoundedStyled = styled(BlockRounded)(({
  padding: 0,
  overflow: 'hidden',
}));

const StrataTableShort: React.FC<Props> = (props: Props) => {
  const { rowData } = props;
  return (
    <BlockRoundedStyled>
      {rowData.map((data) => (
        <InventoryTableShortRow data={data} key={data.id} />
      ))}
    </BlockRoundedStyled>
  );
};

export default StrataTableShort;
