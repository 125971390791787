import * as React from 'react';
import { VBox } from '@simosol/boxes';
import { Button, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import MapCommon from '../../common/map/MapCommon';
import UiText from '../../../uiComponents/UiText';
import Estate from '../../../models/estates/Estate';
import Stand from '../../../models/stands/Stand';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';

const Root = styled(VBox)<{ isBig: number }>(({ theme, isBig }) => ({
  height: isBig ? 920 : 460,
  transition: theme.transitions.create(['height'], { duration: '0.3s' }),
  flex: 1,
}));

const MapBlock = styled('div')({
  width: '100%',
  height: 'inherit',
  position: 'relative',
});

const MapContainer = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  borderRadius: 20,
});

type Props = {
  estate: Estate,
  stand?: Stand,
};

const EstatesHeaderMap: React.FC<Props> = observer((props: Props) => {
  const { estate, stand } = props;

  return (
    <Root gap={16} align="end" isBig={+app.collapse}>
      <MapBlock>
        <MapContainer>
          <MapCommon estate={estate} stand={stand} />
        </MapContainer>
      </MapBlock>
      <Button onClick={app.toggleCollapsed}>
        <UiText variant="button">
          {app.collapse ? LangKey.ButtonsUncollapseMap : LangKey.ButtonsCollapseMap}
        </UiText>
      </Button>
    </Root>
  );
});

export default EstatesHeaderMap;
