import * as React from 'react';
import {
  ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { observer } from 'mobx-react-lite';
import Stars from '@mui/icons-material/Stars';
import UiButton from '../../uiComponents/UiButton';
import gphQLApi from '../../api/GraphqlAPI';
import { app } from '../../models/App';
import { LangKey } from '../../lang/LangKey';
import LogOutButton from '../app/LogOutButton';

type Props = {};

const MobileMenu: React.FunctionComponent<Props> = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setAnchorEl(null);
  }, [app.estates.showGoals]);

  return (
    <div style={{ position: 'absolute', right: 0 }}>
      <Button onClick={handleClick}>
        <MenuIcon color="secondary" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>
          <UiButton
            variant="text"
            color="inherit"
            onClick={() => {
              if (!gphQLApi.isAuth) return;
              app.browser.page = { p: 'forest' };
            }}
          >
            {LangKey.HeaderMetsaniTitle.t()}
          </UiButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <UiButton
            color="inherit"
            variant="text"
          >
            {LangKey.HeaderVerotyokalutTitle.t()}
          </UiButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <UiButton variant="text" color="inherit">
            {LangKey.HeaderMuistiinpanotTitle.t()}
          </UiButton>
        </MenuItem>
        <MenuItem onClick={app.estates.showShowGoals}>
          <ListItemIcon>
            <Stars color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Goals" />
        </MenuItem>
        <MenuItem disabled={!gphQLApi.isAuth}>
          <ListItemIcon>
            <LogOutButton />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </div>
  );
});

export default MobileMenu;
