import * as React from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis,
} from 'recharts';
import { styled } from '@mui/material/styles';
import UiText from '../../../../uiComponents/UiText';
import { LangKey } from '../../../../lang/LangKey';

const UiTextStyled = styled(UiText)(({ theme }) => ({
  margin: theme.spacing(2),
}));

export type GraphType = {
  year: string,
  current: number,
  alt: number,
}[];

type Props = {
  title: string,
  width: number,
  height: number,
  data: GraphType,
};

const ComparePlanGraph = (props: Props) => {
  const {
    title, data, width, height,
  } = props;

  return (
    <>
      <UiTextStyled div variant="h5">{title}</UiTextStyled>
      <BarChart
        width={width}
        height={height}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis dataKey="current" />
        <Tooltip />
        <Legend />
        <Bar dataKey="current" fill="#15a42e" name={LangKey.ResultsPlanUserPlan.t()} legendType="circle" />
        <Bar dataKey="alt" fill="#8204FF" name={LangKey.ResultsPlanCurrentPlan.t()} legendType="circle" />
      </BarChart>
    </>
  );
};

export default ComparePlanGraph;
