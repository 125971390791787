import { AxiosError } from 'axios';

type APIErrorData = { error: string, message?: string, details?: any } | undefined;
type APIErrorType = 'network' | 'other' | 'critical';

export default class APIError {
  type: APIErrorType;

  originalError: AxiosError<APIErrorData>;

  data?: APIErrorData;

  constructor(type: APIErrorType, originalError: AxiosError<APIErrorData>, data?: APIErrorData) {
    this.type = type;
    this.originalError = originalError;
    this.data = data;
  }
}
