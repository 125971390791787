import * as React from 'react';
import Paper from '@mui/material/Paper';
import { VBox } from '@simosol/boxes';
import { useForm } from '@simosol/forms';
import * as defaultRules from '@simosol/forms/lib/rules';
import { styled } from '@mui/material/styles';
import useInstance from '../../utils/useInstance';
import FormTextField from '../../uiComponents/forms/FormTextField';
import UiText from '../../uiComponents/UiText';
import { app } from '../../models/App';
import ResultSnackBar from '../../uiComponents/ResultSnackBar';
import UiButton from '../../uiComponents/UiButton';
import { LangKey } from '../../lang/LangKey';

type Props = {};

const MainDiv = styled(VBox)(() => ({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  background: 'rgba(112, 0, 224, 0.3)',
}));

const FormDiv = styled(Paper)({
  width: '50%',
  height: '50%',
  maxWidth: 300,
  maxHeight: 140,
  borderRadius: 20,
  padding: 24,
  justifyContent: 'center',
});

const TestPageAuthorization: React.FC<Props> = () => {
  const model = useForm(useInstance(() => ({
    value: '',
  })), { value: [defaultRules.required()] });

  const onLoginClick = () => {
    app.guard.setTestToken(model.getValue('value'));
    if (app.guard.isTestTokenError) {
      ResultSnackBar.error(LangKey.ErrorsIncorrectPassword.t());
    }
  };
  return (
    <MainDiv align="center">
      <FormDiv elevation={4}>
        <VBox gap={16} align="stretch" style={{ width: '100%' }}>
          <UiText div style={{ display: 'flex', justifyContent: 'center' }} bold variant="h4">
            {LangKey.AuthTitle.t()}
          </UiText>
          <FormTextField
            model={model}
            field="value"
            type="password"
            disableErrors
          />
          <UiButton
            color="secondary"
            variant="contained"
            onClick={onLoginClick}
            style={{ marginTop: 30 }}
          >
            {LangKey.ButtonsLogin.t()}
          </UiButton>
        </VBox>
      </FormDiv>
    </MainDiv>
  );
};

export default TestPageAuthorization;
