import { computed, makeObservable, observable } from 'mobx';
import Stand from '../stands/Stand';
import {
  EstateGqlType,
  StandGqlType,
  SimulationStatusEnum,
} from '../../generated/graphql';
import { UtilsMap } from '../../utils/UtilsMap';
import { moneyFlowStr } from '../../utils/stringUtils';

export type SimulationStatus = SimulationStatusEnum | null;

export default class Estate {
  private _data: EstateGqlType;

  private readonly _geoJSON: string;

  @observable.shallow
  private _stands?: Stand[] = [];

  @observable
  private _simulationStatus: SimulationStatus = null;

  constructor(data: EstateGqlType) {
    makeObservable(this);
    this._data = data;
    this._simulationStatus = this._data.lastSimulation?.status ?? null;
    this._geoJSON = JSON.stringify(this._data.geometry);
  }

  addStands = (stands: StandGqlType[]) => {
    this._stands = stands
      .sort((a, b) => Number(a.number) - Number(b.number))
      .map((stand: StandGqlType) => new Stand(stand));
  };

  addPlans = (data: EstateGqlType) => {
    this._data.planningYearOperationsSummaryForSelectedPlan = data.planningYearOperationsSummaryForSelectedPlan;
    this._data.planningYearOperationsSummaryForPlainPlans = data.planningYearOperationsSummaryForPlainPlans;
    this._data.planningYearTreeStandsSummaryForSelectedPlan = data.planningYearTreeStandsSummaryForSelectedPlan;
    this._data.planningYearTreeStandsSummaryForPlainPlans = data.planningYearTreeStandsSummaryForPlainPlans;
  };

  @computed
  get withStands() {
    return this._stands?.length ?? 0;
  }

  get id() {
    return this._data.id;
  }

  get geoJSON() {
    return UtilsMap.geomToGeoJSON(this._geoJSON);
  }

  get name() {
    return this._data.name;
  }

  @computed
  get simulationStatus() { return this._simulationStatus; }

  set simulationStatus(v: SimulationStatus) { this._simulationStatus = v; }

  get umount() {
    return moneyFlowStr(this._data.selectedPlanEconomyCriteriaImprovement ?? 0);
  }

  get municipality() {
    return this._data.municipality;
  }

  get code() {
    return this._data.code;
  }

  get area() {
    return this._data.areaHa;
  }

  get areaM2() {
    return this._data.areaM2;
  }

  get description() {
    return this._data.description;
  }

  get planningYearTreeStandsSummaryForPlainPlans() {
    return this._data.planningYearTreeStandsSummaryForPlainPlans;
  }

  get planningYearTreeStandsSummaryForSelectedPlan() {
    return this._data.planningYearTreeStandsSummaryForSelectedPlan;
  }

  get planningYearOperationsSummaryForPlainPlans() {
    return this._data.planningYearOperationsSummaryForPlainPlans;
  }

  get planningYearOperationsSummaryForSelectedPlan() {
    return this._data.planningYearOperationsSummaryForSelectedPlan;
  }

  get stands() {
    return this._stands;
  }

  @computed
  get standsTotalArea() {
    return this._stands?.reduce((sum, stand) => sum + stand.areaM2, 0);
  }
}
