import INFO from './common';

export type DGoal = {
  weight: number,
  criteria: Criteria,
};

export enum Criteria {
  economic = 1,
  carbon = 2,
  nature = 3,
}

const SET_GOALS = `
  mutation($goals: [Int!]!) {
    userGoalsSet(input: {goals: $goals}) {
      ... on UserGoalGqlType {
        goals
      }
      ... on OperationInfo {
        ${INFO} 
      }
    }
  }
`;

export type GoalsInputType = {
  goals: number[],
};

export default SET_GOALS;
