import { ObtainJsonWebTokenInput } from '../../../generated/graphql';

const TOKEN_AUTH = `
  mutation TokenAuth($input: ObtainJSONWebTokenInput!) {
    tokenAuth(input: $input) {
      success
      errors
      user {
        isSuperuser
        lastLogin
        isStaff
        isActive
        dateJoined
        status {
          verified
          archived
        }
        id
        email
        lastName
        firstName
        archived
        verified
      }
      refreshToken {
        token
      }
      token {
        token
        payload {
          exp
        }
      }
    }
  }
`;

export type AuthTokenType = {
  input: ObtainJsonWebTokenInput,
};

export default TOKEN_AUTH;
