import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import { Breadcrumbs, styled } from '@mui/material';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import UiText from '../../../../uiComponents/UiText';
import Money from '../../../../common/Money';
import OperationInfo from './OperationInfo';
import { app } from '../../../../models/App';
import { LangKey } from '../../../../lang/LangKey';

const PaperStyled = styled(Paper)(({
  overflow: 'hidden',
  borderRadius: '20px',
  padding: '8px 12px',
}));

const HBoxStyled = styled(HBox)(({
  flex: 1,
  justifyContent: 'center',
  position: 'relative',
}));
const ItemStyled = styled(VBox)(({
  flex: 1,
  paddingLeft: '15%',
  paddingTop: 8,
}));

const YearStyled = styled('div')<{ short?: boolean }>(({ short }) => ({
  width: 50,
  background: '#366E4A',
  borderRadius: '10px',
  position: short ? 'inherit' : 'absolute',
  bottom: 0,
  right: 0,
  textAlign: 'center',
  color: 'white',
}));

const BigItemStyled = styled(VBox)({
  flex: 2,
});

const ImgStyled = styled('img')({
  width: '36px',
  height: '36px',
  marginRight: '8px',
});

const EstateInfoContainer = styled(VBox)({
  maxWidth: '35%',
  width: '35%',
});

const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
  lineHeight: 2,
}));

const OperationType = styled(UiText)(({ theme }) => ({
  letterSpacing: '0.05em',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}));

type Props = {
  estateId: string,
  estateName: string,
  operationType: string,
  yearPlanned?: number | null,
  cashFlow?: number | null,
  additionalInfo?: string,
  disabled?: boolean;
  withEstate?: boolean;
};

const MobileOperation = (props: Props) => {
  const {
    operationType, estateId, estateName, yearPlanned, cashFlow, additionalInfo, disabled, withEstate,
  } = props;

  const handleEstateClick = () => {
    if (disabled) return;
    app.browser.page = { p: 'estate', p1: estateId };
  };

  return (
    <PaperStyled elevation={3} style={{ opacity: disabled ? 0.5 : undefined }}>
      <HBox align="center">
        <ImgStyled src="./temp/img/3.png" alt="alt" />
        <HBoxStyled align="center">
          <EstateInfoContainer align="start">
            <BigItemStyled>
              <OperationType variant="h5">{operationType}</OperationType>
              {!withEstate && yearPlanned && (
                <YearStyled short>
                  <UiText div variant="body1">{yearPlanned}</UiText>
                </YearStyled>
              )}
              <Breadcrumbs separator="•">
                {withEstate && (
                  <LinkStyled variant="caption" onClick={() => handleEstateClick()}>{estateName}</LinkStyled>
                )}
              </Breadcrumbs>
            </BigItemStyled>
          </EstateInfoContainer>
          <ItemStyled>
            <Money
              title={cashFlow && (cashFlow > 0)
                ? LangKey.CashTitlePositive.t({ value: '' })
                : LangKey.CashTitleNegative.t({ value: '' })}
              umount={cashFlow ?? 0}
            />
          </ItemStyled>
          <OperationInfo
            disabled={disabled}
            description={additionalInfo}
            mobile
            short={!withEstate}
          />
          {withEstate && yearPlanned && (
          <YearStyled>
            <UiText div variant="body1">{yearPlanned}</UiText>
          </YearStyled>
          )}
        </HBoxStyled>
      </HBox>
    </PaperStyled>
  );
};

export default MobileOperation;
