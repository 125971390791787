import moment, { Moment } from 'moment/moment';
import { StandPriority } from '../stands/StandTypes';

const currentYear = (moment() as Moment).year();

const getPriority = (opYear?: number | null) => {
  if (!opYear) return StandPriority.low;
  if (opYear <= currentYear) return StandPriority.urgent;
  if (opYear === currentYear + 1) return StandPriority.high;
  if (opYear >= currentYear + 2 && opYear <= currentYear + 9) return StandPriority.normal;
  return StandPriority.low;
};
export default getPriority;
