import { observable, makeObservable } from 'mobx';

type Page = { p: '' }
| { p: 'forest' }
| { p: 'estate', p1?: string, p2?: 'stand', p3?: string };

export class PageModel {
  @observable page: Page = { p: '' };

  constructor() {
    makeObservable(this);
  }
}

export default Page;
