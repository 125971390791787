import * as React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

type SnackBarContent = string | React.ReactNode;

const model = observable({
  open: false,
  text: '' as SnackBarContent,
  error: false,
});

export default class ResultSnackBar {
  @action
  static show(text: SnackBarContent, error: boolean = false) {
    model.open = true;
    model.text = text || '';
    model.error = error;
  }

  static error = (text: SnackBarContent) => {
    ResultSnackBar.show(text, true);
  };

  static hide() {
    model.open = false;
  }
}

export const ResultSnackBarView = observer(() => {
  const onClose = () => {
    ResultSnackBar.hide();
  };
  return (
    <Snackbar
      sx={{
        width: '100%',
        justifyContent: 'center',
        '& .MuiAlert-icon': {
          padding: '4px 0',
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={model.open}
      autoHideDuration={100000}
      onClose={onClose}
    >
      {model.error
        ? <Alert variant="filled" severity="error">{model.text}</Alert>
        : <Alert variant="filled" severity="success" sx={{ backgroundColor: '#65BC6A' }}>{model.text}</Alert>}
    </Snackbar>
  );
});
