import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import UiText from '../../../uiComponents/UiText';
import ArticleAddToFavorite from './ArticleAddToFavorite';
import ArticleBlockDescription from './ArticleBlockDescription';
import ArticleText from './ArticleText';
import ArticleProfileRoot from './profile/ArticleProfileRoot';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';
import { ArticleCategory } from '../../../models/article/Articles';
import { HiddenDesktop, HiddenMobile, useMobile } from '../../../uiComponents/Responsive';

type Props = {
  expanded: boolean,
  small?: boolean,
};

const YearTextStyled = styled(UiText)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  color: isMobile ? theme.palette.secondary.main : theme.palette.secondary.light,
}));

const TitleStyled = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]:
    {
      fontFamily: 'Open Sans',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '30px',
    },
}));

const BackButton = styled(Button)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  fontSize: isMobile ? '12px' : 'inherit',
  height: theme.spacing(4),
  textDecoration: 'underline',
  marginLeft: isMobile ? theme.spacing(-4) : theme.spacing(-3),
  marginTop: isMobile ? theme.spacing(-8) : theme.spacing(-6),
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
}));

const RootStyled = styled(VBox)<{
  expanded: boolean,
  small?: boolean,
}>(({ theme, expanded, small }) => ({
  padding: theme.spacing(2),
  borderLeft: small ? 'none' : `2px solid ${theme.palette.secondary.main}`,
  display: expanded ? 'block' : 'none',
}));

const ImgStyled = styled('img')<{ small?: boolean }>(({ small }) => ({
  width: small ? '100%' : '90%',
}));

const DescriptionStyled = styled(UiText)({
  fontWeight: 300,
});

const ArticleContent: React.FC<Props> = observer(({ expanded, small }) => {
  const article = app.articles.currentArticle;
  const isMobile = useMobile();
  if (!article) return null;

  const handleBack = () => {
    app.articles.setCurrentArticle(undefined);
    app.articles.currentCategory = ArticleCategory.All;
  };

  return (
    <RootStyled align="center" gap={isMobile ? 32 : 64} expanded={expanded} small={small}>
      {small
      && (
      <BackButton color={isMobile ? 'secondary' : 'warning'} startIcon={<ArrowBackIosIcon />} onClick={handleBack} isMobile={isMobile}>
        {LangKey.ButtonsBack.t()}
      </BackButton>
      )}
      <VBox align="center" gap={16}>
        <YearTextStyled isMobile={isMobile} div variant="button">{article?.date}</YearTextStyled>
        <HiddenDesktop>
          <ImgStyled small={small} src={article.img} />
        </HiddenDesktop>
        <TitleStyled variant={small ? 'h3' : 'h1'} div align="center">
          {article?.title}
        </TitleStyled>
        <DescriptionStyled variant={small ? 'h5' : 'h3'} div align="center">
          {article?.description}
        </DescriptionStyled>
        <ArticleAddToFavorite article={article} />
      </VBox>
      <HiddenMobile>
        <ImgStyled small={small} src={article.img} />
      </HiddenMobile>
      <ArticleBlockDescription small={small} />
      <ArticleText text={article.text} small={small} />
      <ArticleProfileRoot author={article.author} small={small} mobile={isMobile} />
    </RootStyled>
  );
});

export default ArticleContent;
