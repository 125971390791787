import * as React from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../../models/Pages';
import { app } from '../../models/App';
import { LangKey } from '../../lang/LangKey';
import theme from '../../theme';

const LinkStyled = styled(Link)<{ last: boolean }>(({ last }) => ({
  fontWeight: last ? 700 : 'inherit',
}));

type Props = {
  links: {
    page: Page,
    name: string;
    id: string;
  }[];
};

const Links: React.FC<Props> = ({ links }) => (
  <Breadcrumbs separator=">">
    <Link underline="hover" color="inherit" href="#forest">
      {LangKey.HeaderMetsaniTitle.t()}
    </Link>
    {
        links.map((link, key) => (
          <LinkStyled
            key={link.id}
            last={key === links.length - 1}
            color={key === links.length - 1 ? theme.palette.text.primary : 'inherit'}
            href={`#${app.browser.getHash(link.page)}`}
          >
            {link.name}
          </LinkStyled>
        ))
      }
  </Breadcrumbs>
);

export default Links;
