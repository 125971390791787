import * as React from 'react';
import { observer } from 'mobx-react-lite';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Estate from '../../../../models/estates/Estate';
import {
  PlanningYearTreeStandSummaryGqlType,
  TreatmentTypeEnum,
} from '../../../../generated/graphql';
import { app } from '../../../../models/App';
import { EPlansNames } from '../../../../models/CompareTable';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  estate: Estate,
};

const ComparePlanSelect = observer((props: Props) => {
  const { estate } = props;

  const onChange = (e: SelectChangeEvent<TreatmentTypeEnum>) => {
    app.compareTable.plan = e.target.value as TreatmentTypeEnum;
  };

  return (
    <Select
      value={app.compareTable.plan}
      onChange={onChange}
    >
      {
        estate.planningYearTreeStandsSummaryForPlainPlans.map((item: PlanningYearTreeStandSummaryGqlType) => (
          <MenuItem
            key={item.treatmentType}
            value={item.treatmentType}
          >
            {`${EPlansNames[item.treatmentType]} ${LangKey.ComparePlansPlan.t()}`}
          </MenuItem>
        ))
      }
    </Select>
  );
});

export default ComparePlanSelect;
