import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import UiText from '../../../../uiComponents/UiText';
import theme from '../../../../theme';
import PlanCollapse from './PlanCollapse';
import useCollapse from '../../../../uiComponents/useCollapse';
import {
  PlanningYearOperationInfoGqlType,
  PlanningYearTreeStandInfoGqlType,
} from '../../../../generated/graphql';
import CompareTable from '../../../../models/CompareTable';

type Props = {
  model: CompareTable;
  simulationPlan: PlanningYearOperationInfoGqlType[];
  alternativePlan: PlanningYearOperationInfoGqlType[];
  currentTreeStand: PlanningYearTreeStandInfoGqlType[];
  alternativeTreeStand: PlanningYearTreeStandInfoGqlType[];
  planName: string;
  isEven?: boolean;
  defaultCollapse?: boolean;
};

const ComparePlans: React.FunctionComponent<Props> = ({
  model, simulationPlan, alternativePlan, currentTreeStand, alternativeTreeStand, planName, isEven, defaultCollapse,
}) => {
  const { collapse, toggleCollapse, iconButton } = useCollapse({ default: defaultCollapse });

  return (
    <>
      <ListItem
        button
        onClick={toggleCollapse}
        style={{
          background: isEven ? undefined : theme.palette.divider,
        }}
      >
        <ListItemText>
          <UiText variant="h5">{planName}</UiText>
        </ListItemText>
        <ListItemSecondaryAction>
          {iconButton}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={collapse}>
        <PlanCollapse
          model={model}
          simulationPlan={simulationPlan}
          alternativePlan={alternativePlan}
          currentTreeStand={currentTreeStand}
          alternativeTreeStand={alternativeTreeStand}
          planName={planName}
        />
      </Collapse>
    </>
  );
};

export default ComparePlans;
