import * as React from 'react';
import { styled } from '@mui/material/styles';

const DivStyled = styled('div')({
  flexGrow: 1,
});

const Spacer = () => (<DivStyled />);

export default Spacer;
