import INFO from './common';

const ESTATE_SIMULATION = `
  mutation($estateIds: [GlobalID!]!) {
    estatesSimulationMutation(input: {estateIds: $estateIds}) {
      ${INFO} 
      ... on EstateSimulationResultGqlType {
        estates {
          id
          name
        }
      }
    }
  }
`;

export type EstatesInputType = {
  estateIds: string[],
};

export default ESTATE_SIMULATION;
