import * as React from 'react';
import { HBox, VBox } from '@simosol/boxes';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Info from '@mui/icons-material/Info';
import { observer } from 'mobx-react-lite';
import { Link } from '@mui/material';
import UiText from '../uiComponents/UiText';
import Divider from '../common/Divider';
import EstateMenu from './pages/estate/EstateMenu';
import Estate from '../models/estates/Estate';
import HBoxVBox from '../uiComponents/HBoxVBox';
import useCollapse from '../uiComponents/useCollapse';
import { app } from '../models/App';
import { SimulationStatusEnum } from '../generated/graphql';
import { humanNumber } from '../utils/stringUtils';
import { LangKey } from '../lang/LangKey';
import theme from '../theme';

const VBoxRoot = styled(VBox)<{ loading: number }>(({ loading }) => ({
  backgroundColor: '#FFF',
  borderRadius: '16px',
  position: 'relative',
  pointerEvents: !loading ? 'none' : undefined,
  '@keyframes pulsate': {
    from: {
      opacity: 0.3,
    },
    to: {
      opacity: 0.7,
    },
  },
  animation: !loading ? 'pulsate 1s infinite alternate ease' : undefined,
}));
const ImgStyled = styled('img')({
  width: '44px',
  height: '44px',
  marginRight: '16px',
});
const UiTextStyled = styled(UiText)({
  color: '#6D6D6D',
  fontSize: 12,
  fontWeight: 400,
  [theme.breakpoints.down('md')]: {
    fontSize: 8,
    lineHeight: 0.72,
  },
});

const EstateName = styled(UiText)({
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
});

const AdditionalInfo = styled(UiText)({
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
  },
});

const UiTextSum = styled(UiText)({
  fontSize: 16,
  fontWeight: 700,
});

const UiTextContainer = styled(HBoxVBox)<{ done: number }>(({ done }) => ({
  padding: 12,
  cursor: done ? 'pointer' : undefined,
  [theme.breakpoints.down('sm')]: {
    padding: '12px 12px 0 12px',
  },
}));

const VBoxCollapse = styled(VBox)({
  marginLeft: 60,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 8,
  },
});

const VBoxAdditional = styled(VBox)({
  flex: 3,
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
  },
});

const DesctiptionCollapse = styled(Collapse)({
  '& .MuiCollapse-wrapperInner': {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
});

type Props = {
  model: Estate;
  disabled: boolean;
};

const EstateCard: React.FC<Props> = observer(({ model, disabled }) => {
  const { collapse, iconButton } = useCollapse({ disabled });

  const onEstateClick = () => {
    if (model.simulationStatus !== 'DONE' || disabled) return;
    app.browser.page = { p: 'estate', p1: model.id };
  };

  return (
    <VBoxRoot gap={4} loading={+model.withStands} style={{ opacity: disabled ? '0.5' : undefined }}>
      <UiTextContainer xxs onClick={onEstateClick} done={+(model.simulationStatus === SimulationStatusEnum.Done)}>
        {(model.simulationStatus === null || model.simulationStatus === SimulationStatusEnum.Running) && <ImgStyled src="./media/estate_run.svg" alt="estate_run" />}
        {model.simulationStatus === SimulationStatusEnum.Done && <ImgStyled src="./media/estate_done.svg" alt="estate_done" />}
        {model.simulationStatus === SimulationStatusEnum.Failed && <ImgStyled src="./media/estate_fail.svg" alt="estate_fail" />}

        <VBox gap={0} style={{ flex: 5 }}>
          <EstateName
            variant="h4"
            color={model.simulationStatus === SimulationStatusEnum.Failed ? 'red' : 'inherit'}
          >
            {model.name}
          </EstateName>
          <HBox gap={16}>
            <AdditionalInfo div variant="subtitle2">{model.code}</AdditionalInfo>
            <AdditionalInfo div variant="subtitle2">{model.municipality?.label}</AdditionalInfo>
          </HBox>
          <AdditionalInfo div variant="body1">{`${model?.stands?.length} ${LangKey.EstatesStands.t()} 𐤟 ${humanNumber(model.area)} ha`}</AdditionalInfo>
        </VBox>
        <VBoxAdditional>
          <UiTextStyled variant="overline">{LangKey.EstatesProfit}</UiTextStyled>
          {model.simulationStatus === null && <UiTextSum color="orange">{LangKey.EstatesSimulationNotStarted.t()}</UiTextSum>}
          {model.simulationStatus === SimulationStatusEnum.Done && <UiTextSum color="green">{model.umount}</UiTextSum>}
          {model.simulationStatus === SimulationStatusEnum.Running && <UiTextSum color="orange">{LangKey.EstatesSimulationRunning.t()}</UiTextSum>}
          {model.simulationStatus === SimulationStatusEnum.Failed && <UiTextSum color="red">{LangKey.EstatesSimulationFailed.t()}</UiTextSum>}
        </VBoxAdditional>
      </UiTextContainer>
      <EstateMenu model={model} disabled={disabled} />
      {model.simulationStatus === 'DONE' && (
        <>
          <Divider color={theme.palette.secondary.main} height={1} />
          <VBoxCollapse>
            <HBox gap={8} align="center">
              <UiText div variant="overline">{LangKey.EstatesAdditional}</UiText>
              {iconButton}
            </HBox>
            <DesctiptionCollapse in={collapse}>
              {model.description}
            </DesctiptionCollapse>
          </VBoxCollapse>
        </>
      )}
      {model.simulationStatus === SimulationStatusEnum.Running && (
        <HBox gap={24} align="center">
          <div><Info fontSize="large" color="warning" /></div>
          <UiText div variant="body1">
            {LangKey.MessagesSimulationRunning.t()}
          </UiText>
        </HBox>
      )}
      {model.simulationStatus === SimulationStatusEnum.Failed && (
        <HBox gap={24} align="center">
          <div><Info fontSize="large" color="error" /></div>
          <UiText div variant="body1" color="red">
            {LangKey.MessagesSimulationFailed.t()}
            {' '}
            <Link href="mailto:asiakaspalvelu@metsälehti.fi" color="blue">asiakaspalvelu@metsälehti.fi</Link>
          </UiText>
        </HBox>
      )}
    </VBoxRoot>
  );
});

export default EstateCard;
