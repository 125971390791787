import INFO from './common';
import ESTATE from '../instanses/estate';

const IMPORT_OPEN_DATA = `
  mutation ($estateCode: String!) {
    importOpenData(input: {
      estateCode: $estateCode
      needSimulate: true
      simulationType: ESTATE
    }) {
      ${INFO}
      ... on ImportOpenDataResultGqlType {
        estates ${ESTATE}
      }
    }
  }
`;

export type EstateImportType = {
  estateCode: string,
};

export default IMPORT_OPEN_DATA;
