import { VBox } from '@simosol/boxes';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useMobile } from '../../../../uiComponents/Responsive';
import Estate from '../../../../models/estates/Estate';
import { app } from '../../../../models/App';
import ComparePlanGraph from './ComparePlanGraph';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  estate: Estate,
};

const ComparePlansGraphs: React.FC<Props> = observer((props: Props) => {
  const { estate } = props;
  const isMobile = useMobile();

  const data = app.compareTable.createDataForGraph(
    estate.planningYearTreeStandsSummaryForSelectedPlan,
    estate.planningYearTreeStandsSummaryForPlainPlans,
  );

  const cashFlowData = app.compareTable.createCashFlowDataForGraph(
    estate.planningYearOperationsSummaryForSelectedPlan,
    estate.planningYearOperationsSummaryForPlainPlans,
  );

  return (
    <VBox gap={24}>
      <ComparePlanGraph
        width={isMobile ? document.documentElement.clientWidth - 64 : 390}
        height={270}
        data={cashFlowData}
        title={LangKey.ComparePlansEconomic.t()}
      />
      <ComparePlanGraph
        width={isMobile ? document.documentElement.clientWidth - 64 : 390}
        height={270}
        data={data.carboneData}
        title={LangKey.ComparePlansCarbon.t()}
      />
      <ComparePlanGraph
        width={isMobile ? document.documentElement.clientWidth - 64 : 390}
        height={270}
        data={data.natureData}
        title={LangKey.ComparePlansNature.t()}
      />
    </VBox>
  );
});

export default ComparePlansGraphs;
