import { computed, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { SlidersModel } from './SlidersModel';
import { LangKey } from '../../lang/LangKey';

export type SliderData = { title: string, description: string };

export class SliderModel<D extends SliderData = SliderData> {
  title: string;

  description: string;

  @observable
  private _value: number = 0;

  private _parent: SlidersModel<D>;

  data: D;

  constructor(parent: SlidersModel<D>, data: D) {
    makeObservable(this);
    this._parent = parent;
    this.title = data.title;
    this.description = data.description;
    this.data = data;
  }

  @computed
  get value() { return this._value; }

  set value(newValue: number) {
    if (newValue === this._value) return;
    const maxValue = this._value + this._parent.pointsLeft;
    const oldValue = this._value;
    this._value = Math.min(maxValue, newValue);
    if (newValue > maxValue && this._value === oldValue) {
      this._parent.error = LangKey.ErrorsTooManyPoint.t();
    } else {
      this._parent.error = undefined;
    }
  }

  @computed
  get disabled(): boolean { return !this._parent.hasPoints; }

  get max() { return this._parent.slidersMax; }

  get error() { return this._parent.error; }
}
