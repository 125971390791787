import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import { ReactText } from '../../../uiComponents/UiText';

type Props = {
  text: string;
  small?: boolean
};

const RootStyled = styled(VBox)<{ small?: boolean }>(({ small }) => ({
  width: small ? '100%' : '90%',
  maxWidth: 800,
}));

const StyledText = styled(ReactText)(({
  fontFamily: 'Open Sans',
  margin: '0 auto',
  whiteSpace: 'break-spaces',
}));

const ArticleText: React.FC<Props> = ({ text, small }) => (
  <RootStyled gap={24} small={small}>
    <StyledText div>
      {text}
    </StyledText>
  </RootStyled>
);

export default ArticleText;
