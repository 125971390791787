import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { HBox } from '@simosol/boxes';
import { observer } from 'mobx-react-lite';
import theme from '../theme';

const RootStyled = styled(HBox)({
  width: '100%',
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
});
const LinearProgressStyled = styled(LinearProgress)({
  width: '100%',
  maxWidth: 200,
  height: theme.spacing(2),
});

const AppPreloader = observer(() => (
  <RootStyled>
    <LinearProgressStyled variant="indeterminate" />
  </RootStyled>
));

export default AppPreloader;
