import * as React from 'react';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Pending from '@mui/icons-material/Pending';
import Delete from '@mui/icons-material/Delete';
// import Insights from '@mui/icons-material/Insights';
import UiIconButton from '../../../uiComponents/UiIconButton';
import Estate from '../../../models/estates/Estate';
import { app } from '../../../models/App';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';
// import { SimulationStatusEnum } from '../../../generated/graphql';
import Confirm from '../../../uiComponents/Confirm';
import { LangKey } from '../../../lang/LangKey';
import { useMobile } from '../../../uiComponents/Responsive';

type Props = {
  model: Estate;
  disabled: boolean;
};

const EstateMenu = (props: Props) => {
  const { model, disabled } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useMobile();
  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  // eslint-disable-next-line no-nested-ternary
  const iconColor = model.simulationStatus === 'DONE'
    ? 'primary'
    : model.simulationStatus === 'FAILED'
      ? 'error'
      : 'warning';

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    const res = await app.estates.delete(model);
    if (res?.estateDelete.name) {
      ResultSnackBar.show(LangKey.EstatesDelete.t({ number: res?.estateDelete.name }));
    } else {
      ResultSnackBar.error(res?.estateDelete?.messages?.map((er) => er.message).join(',') ?? LangKey.ErrorsError.t());
    }
    setAnchorEl(null);
  };

  /* const handleStartSimulation = async () => {
    const res = await app.estates.simulation(model);
    if (res?.estatesSimulationMutation.estates) {
      ResultSnackBar.show(`Simulation ${res?.estatesSimulationMutation.estates[0].name} was started`);
    } else {
      ResultSnackBar.error(res?.estatesSimulationMutation?.messages?.map((er) => er.message).join(',') ?? 'Error');
    }
    setAnchorEl(null);
  }; */

  return (
    <>
      <UiIconButton
        onClick={handleClick}
        style={{ position: 'absolute', top: isMobile ? 0 : '12px', right: isMobile ? 0 : '12px' }}
        disabled={disabled}
      >
        <Pending color={iconColor} fontSize={isMobile ? 'small' : 'large'} />
      </UiIconButton>
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          {/* <MenuItem
            onClick={handleStartSimulation}
            disabled={model.simulationStatus === SimulationStatusEnum.Running}
          >
            <ListItemIcon><Insights fontSize="small" /></ListItemIcon>
            <ListItemText primary="Aloita simulointi" />
          </MenuItem> */}
          <Confirm
            variant="menuItem"
            onClick={handleDelete}
            message={LangKey.MessagesDelete.t()}
            icon={<Delete />}
            disabled={app.estates.estateActionLoading}
          >
            {LangKey.ButtonsDelete.t()}
          </Confirm>
        </Menu>
      )}
    </>
  );
};

export default EstateMenu;
