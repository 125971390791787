import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { ResultSnackBarView } from '../uiComponents/ResultSnackBar';
import { app } from '../models/App';
import App from '../App';
import TestPageAuthorization from './app/TestPageAuthorization';

const AppContainer = observer(() => {
  React.useEffect(
    () => {
      app.guard.initTestToken();
    },
    [],
  );

  return (
    <>
      <ResultSnackBarView />
      {app.guard.isValidTestToken ? <App /> : <TestPageAuthorization />}
    </>
  );
});

export default AppContainer;
