import * as React from 'react';
import { Collapse, Link, styled } from '@mui/material';
import { HBox, VBox } from '@simosol/boxes';
import UiText, { ReactText } from '../../../../uiComponents/UiText';
import { moneyFlowStr } from '../../../../utils/stringUtils';
import useCollapse from '../../../../uiComponents/useCollapse';
import theme from '../../../../theme';
import { app } from '../../../../models/App';
import { StandOperation } from '../../../../models/CompareTable';
import { StandGqlType } from '../../../../generated/graphql';

const CellStyled = styled('div')<{ last?: 1 }>({
  flex: 1,
});
const RowStyled = styled(HBox)(() => ({
  position: 'relative',
  padding: theme.spacing(1, 2),
  alignItems: 'start',
}));
const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
  lineHeight: 2,
}));
const CollapseButton = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
}));

type Props = {
  year: number;
  period: number;
  stands: StandOperation[];
  isEven?: boolean;
};

const PlanDescriptionRow: React.FunctionComponent<Props> = (props) => {
  const {
    year, period, stands, isEven,
  } = props;

  const yearTitle = period > 1
    ? `${year.toString().slice(-2)}-${(year + period - 1).toString().slice(-2)}`
    : `${year}`;

  const { collapse, toggleCollapse, iconButton } = useCollapse();

  const handleClick = (data: StandGqlType) => {
    app.browser.page = {
      p: 'estate', p1: data.estate.id.toString(),
    };
  };

  const totalSelected = stands
    .reduce((total, stand) => total + stand.activeOperation
      .reduce((tot, op) => tot + (op.cashFlow ?? 0), 0), 0);
  const totalAlt = stands
    .reduce((total, stand) => total + stand.alternativeOperation
      .reduce((tot, op) => tot + (op.cashFlow ?? 0), 0), 0);

  return (
    <VBox
      gap={0}
      style={{
        background: isEven ? undefined : theme.palette.divider,
      }}
    >
      <RowStyled>
        <CellStyled><UiText>{yearTitle}</UiText></CellStyled>
        <CellStyled><UiText color={totalSelected < 0 ? 'red' : 'inherit'}>{moneyFlowStr(totalSelected, true)}</UiText></CellStyled>
        <CellStyled><UiText color={totalAlt < 0 ? 'red' : 'inherit'}>{moneyFlowStr(totalAlt, true)}</UiText></CellStyled>
        <CollapseButton onClick={toggleCollapse}>
          {iconButton}
        </CollapseButton>
      </RowStyled>
      <Collapse in={collapse}>
        {stands.map((stand) => (
          <RowStyled key={stand.data.id}>
            <CellStyled>
              <LinkStyled
                variant="caption"
                onClick={() => handleClick(stand.data)}
              >
                {`${stand.data.estate.name}`}
              </LinkStyled>
            </CellStyled>
            <CellStyled>
              {stand.activeOperation?.map((op) => (
                <div key={op.id}>
                  <ReactText variant="caption" color={op.cashFlow < 0 ? 'red' : 'inherit'}>
                    {`${op.operationType.label} ${moneyFlowStr(op.cashFlow ?? 0, true)}`}
                  </ReactText>
                </div>
              ))}
            </CellStyled>
            <CellStyled>
              {stand.alternativeOperation?.map((op) => (
                <div key={op.id}>
                  <ReactText variant="caption" color={op.cashFlow < 0 ? 'red' : 'inherit'}>
                    {`${op.operationType.label} ${moneyFlowStr(op.cashFlow ?? 0, true)}`}
                  </ReactText>
                </div>
              ))}
            </CellStyled>
          </RowStyled>
        ))}
      </Collapse>
    </VBox>
  );
};

export default PlanDescriptionRow;
