import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment/moment';
import { observer } from 'mobx-react-lite';
import CompareTable from '../../../../models/CompareTable';
import UiText from '../../../../uiComponents/UiText';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  model: CompareTable,
};

const BoxStyled = styled(VBox)(({ theme }) => ({
  padding: theme.spacing(3),
  maxWidth: 260,
}));

const CompareYearSelector: React.FunctionComponent<Props> = observer(({ model }) => {
  const currentYear = moment().year();

  const handleChange = (event: SelectChangeEvent<number>) => {
    // eslint-disable-next-line no-param-reassign
    model.item = +event.target.value;
  };

  return (
    <BoxStyled gap={8}>
      <UiText variant="h5">{LangKey.MainResultYearTitle}</UiText>
      <Select
        fullWidth={false}
        onChange={handleChange}
        value={model.item}
      >
        <MenuItem key={1} value={1}>{`${currentYear}-${currentYear + 4}`}</MenuItem>
        <MenuItem key={2} value={2}>{`${currentYear + 5}-${currentYear + 9}`}</MenuItem>
        <MenuItem key={3} value={3}>{`${currentYear + 10}-${currentYear + 49}`}</MenuItem>
      </Select>
    </BoxStyled>
  );
});

export default CompareYearSelector;
