/* eslint-disable */
export class LangKey {
  static AuthTitle = 'auth.title';
  static AuthLogoutText = 'auth.logoutText';
  static HeaderMetsaniTitle = 'header.metsaniTitle';
  static HeaderVerotyokalutTitle = 'header.verotyokalutTitle';
  static HeaderMuistiinpanotTitle = 'header.muistiinpanotTitle';
  static EstatesTitle = 'estates.title';
  static EstatesDialogTitle = 'estates.dialogTitle';
  static EstatesAddTitle = 'estates.addTitle';
  static EstatesHeader = 'estates.header';
  static EstatesStand = 'estates.stand';
  static EstatesStands = 'estates.stands';
  static EstatesProfit = 'estates.profit';
  static EstatesAdditional = 'estates.additional';
  static EstatesIncorrect = 'estates.incorrect';
  static EstatesDelete = 'estates.delete';
  static EstatesSuccess = 'estates.success';
  static EstatesNotLoaded = 'estates.notLoaded';
  static EstatesNotFound = 'estates.notFound';
  static EstatesSimulationNotStarted = 'estates.simulation.notStarted';
  static EstatesSimulationRunning = 'estates.simulation.running';
  static EstatesSimulationFailed = 'estates.simulation.failed';
  static StandsTitle = 'stands.title';
  static StandsInfoTitle = 'stands.infoTitle';
  static StandsStand = 'stands.stand';
  static StandsHeader = 'stands.header';
  static StandsAdditional = 'stands.additional';
  static StandsNextStep = 'stands.nextStep';
  static StandsBasicInfo = 'stands.basicInfo';
  static StandsAlternativePlan = 'stands.alternativePlan';
  static StandsGoalNextYear = 'stands.goalNextYear';
  static StandsTotalAccumulation = 'stands.totalAccumulation';
  static StandsAllProcedure = 'stands.allProcedure';
  static StandsNothingToShow = 'stands.nothingToShow';
  static StandsNoInfo = 'stands.noInfo';
  static StandsBasicTableArea = 'stands.basicTable.area';
  static StandsBasicTableMainGroup = 'stands.basicTable.mainGroup';
  static StandsBasicTableSubGroup = 'stands.basicTable.subGroup';
  static StandsBasicTableFertilityClass = 'stands.basicTable.fertilityClass';
  static StandsBasicTableSoilType = 'stands.basicTable.soilType';
  static StandsBasicTableDrainageState = 'stands.basicTable.drainageState';
  static StandsBasicTableDitchingYear = 'stands.basicTable.ditchingYear';
  static StandsBasicTableQuality = 'stands.basicTable.quality';
  static StandsBasicTableAccessibility = 'stands.basicTable.accessibility';
  static StandsBasicTablePublicText = 'stands.basicTable.publicText';
  static StandsTableMainTree = 'stands.table.mainTree';
  static StandsTableTreeSpecies = 'stands.table.treeSpecies';
  static StandsTableStandValue = 'stands.table.standValue';
  static StandsTableCarbonStorage = 'stands.table.carbonStorage';
  static StandsTableNaturalValue = 'stands.table.naturalValue';
  static StandsTableWood = 'stands.table.wood';
  static StandsTablePeriod = 'stands.table.period';
  static StandsTableAge = 'stands.table.age';
  static StandsTableFloorArea = 'stands.table.floorArea';
  static StandsTableFrameNumber = 'stands.table.frameNumber';
  static StandsTableLenght = 'stands.table.lenght';
  static StandsTableDeadWoodQuality = 'stands.table.deadWoodQuality';
  static StandsTableDiameter = 'stands.table.diameter';
  static StandsTableVolume = 'stands.table.volume';
  static StandsTableTotalVolume = 'stands.table.totalVolume';
  static StandsTableLogPercent = 'stands.table.logPercent';
  static StandsTableGrowth = 'stands.table.growth';
  static ArticlesArticlesTitle = 'articles.articlesTitle';
  static ArticlesArticlesCategoryAll = 'articles.articlesCategory.all';
  static ArticlesArticlesCategoryFavorite = 'articles.articlesCategory.favorite';
  static ArticlesArticlesCategoryOwnership = 'articles.articlesCategory.ownership';
  static ArticlesArticlesCategoryWoodShop = 'articles.articlesCategory.woodShop';
  static ArticlesArticlesCategoryForestry = 'articles.articlesCategory.forestry';
  static ArticlesArticlesCategoryTechnique = 'articles.articlesCategory.technique';
  static ArticlesArticlesCategoryHobbies = 'articles.articlesCategory.hobbies';
  static ArticlesArticlesCategoryNature = 'articles.articlesCategory.nature';
  static CashTitlePositive = 'cashTitle.positive';
  static CashTitleNegative = 'cashTitle.negative';
  static OperationsTitle = 'operations.title';
  static ButtonsMobile = 'buttons.mobile';
  static ButtonsOk = 'buttons.ok';
  static ButtonsAdd = 'buttons.add';
  static ButtonsDelete = 'buttons.delete';
  static ButtonsYes = 'buttons.yes';
  static ButtonsCancel = 'buttons.cancel';
  static ButtonsSave = 'buttons.save';
  static ButtonsDontWant = 'buttons.dontWant';
  static ButtonsLoginGuest = 'buttons.loginGuest';
  static ButtonsLogin = 'buttons.login';
  static ButtonsLogout = 'buttons.logout';
  static ButtonsOpenStand = 'buttons.openStand';
  static ButtonsCollapseArticle = 'buttons.collapseArticle';
  static ButtonsExpandArticle = 'buttons.expandArticle';
  static ButtonsShowArticle = 'buttons.showArticle';
  static ButtonsCollapseMap = 'buttons.collapseMap';
  static ButtonsUncollapseMap = 'buttons.uncollapseMap';
  static ButtonsShowMore = 'buttons.showMore';
  static ButtonsShowLess = 'buttons.showLess';
  static ButtonsMyGoals = 'buttons.myGoals';
  static ButtonsOrder = 'buttons.order';
  static ButtonsArticle = 'buttons.article';
  static ButtonsAddToFavorite = 'buttons.addToFavorite';
  static ButtonsRemoveFromFavorite = 'buttons.removeFromFavorite';
  static ButtonsBack = 'buttons.back';
  static ComparePlansTitle = 'comparePlans.title';
  static ComparePlansDescription = 'comparePlans.description';
  static ComparePlansPlan = 'comparePlans.plan';
  static ComparePlansCarbon = 'comparePlans.carbon';
  static ComparePlansNature = 'comparePlans.nature';
  static ComparePlansEconomic = 'comparePlans.economic';
  static ResultsPlanYear = 'resultsPlan.year';
  static ResultsPlanInTotal = 'resultsPlan.inTotal';
  static ResultsPlanUserPlan = 'resultsPlan.userPlan';
  static ResultsPlanCurrentPlan = 'resultsPlan.currentPlan';
  static ResultsPlanCarbon = 'resultsPlan.carbon';
  static ResultsPlanNature = 'resultsPlan.nature';
  static ResultsPlanEconomic = 'resultsPlan.economic';
  static FeaturesSpecialFeaturesTitle = 'features.specialFeaturesTitle';
  static FeaturesRestrictionTitle = 'features.restrictionTitle';
  static MainResultResultTitle = 'mainResult.resultTitle';
  static MainResultResultDescription = 'mainResult.resultDescription';
  static MainResultResultTotalTitle = 'mainResult.resultTotalTitle';
  static MainResultYearTitle = 'mainResult.yearTitle';
  static GoalsNewGoalsTitle = 'goals.newGoalsTitle';
  static GoalsOldGoalsTitle = 'goals.oldGoalsTitle';
  static GoalsDescriptionOne = 'goals.descriptionOne';
  static GoalsDescriptionTwo = 'goals.descriptionTwo';
  static GoalsDescriptionThree = 'goals.descriptionThree';
  static GoalsPointLeft = 'goals.pointLeft';
  static GoalsSuccess = 'goals.success';
  static GoalsCriteriaEconomicTitle = 'goals.criteria.economicTitle';
  static GoalsCriteriaEconomicDescription = 'goals.criteria.economicDescription';
  static GoalsCriteriaCarbonTitle = 'goals.criteria.carbonTitle';
  static GoalsCriteriaCarbonDescription = 'goals.criteria.carbonDescription';
  static GoalsCriteriaNatureTitle = 'goals.criteria.natureTitle';
  static GoalsCriteriaNatureDescription = 'goals.criteria.natureDescription';
  static MapWmsLayersTitle = 'map.wmsLayers.title';
  static MapVectorLayersTitle = 'map.vectorLayers.title';
  static MapTooltipMapLayers = 'map.tooltip.mapLayers';
  static MapDenied = 'map.denied';
  static MapUnaviable = 'map.unaviable';
  static MapUnknownError = 'map.unknownError';
  static MapGpsError = 'map.gpsError';
  static MessagesAddEstate = 'messages.addEstate';
  static MessagesConfirm = 'messages.confirm';
  static MessagesDelete = 'messages.delete';
  static MessagesGoals = 'messages.goals';
  static MessagesSimulationRunning = 'messages.simulationRunning';
  static MessagesSimulationFailed = 'messages.simulationFailed';
  static MessagesLoggedArticle = 'messages.loggedArticle';
  static MiscNoVariant = 'misc.noVariant';
  static MiscNoRestriction = 'misc.noRestriction';
  static MiscRequired = 'misc.required';
  static MiscNotNumber = 'misc.notNumber';
  static MiscEmail = 'misc.email';
  static MiscPassword = 'misc.password';
  static ErrorsError = 'errors.error';
  static ErrorsConfirmError = 'errors.confirmError';
  static ErrorsIncorrectPassword = 'errors.incorrectPassword';
  static ErrorsGoalsError = 'errors.goalsError';
  static ErrorsStandsNotLoaded = 'errors.standsNotLoaded';
  static ErrorsPageNotFound = 'errors.pageNotFound';
  static ErrorsTooManyPoint = 'errors.tooManyPoint';
  static ErrorsEstateAlreadyAdded = 'errors.estateAlreadyAdded';
  static TooltipResultTotatal = 'tooltip.resultTotatal';
  static GuestTitle = 'guest.title';
  static GuestDescription = 'guest.description';
}
