import * as React from 'react';
import * as turf from '@turf/turf';
import {
  GpsErrorDialog,
  usePositionWatcher,
  usePositionLock,
  useOwnLocationMarker,
  OwnLocationButton,
  useMap,
  ZoomButtons,
  useMapDefaultStyles,
  useOptimizedStandLayers,
  useBaseLayer,
  useStandClick,
  useMapFitBoundsAndSave,
  useSelectedStands,
  Stand as EditorStand,
} from '@simosol/stands-map';
import { RasterAndVectorLayersControl } from '@simosol/layers-control';
import { runInAction } from 'mobx';
import { mapTransformRequest } from '../../../api/API';
import { app } from '../../../models/App';
import Estate from '../../../models/estates/Estate';
import Stand from '../../../models/stands/Stand';
import useMapStands from './useMapStands';
import baseLayers from './baseLayers';
import { LangKey } from '../../../lang/LangKey';

export const MAP_LOCATION = 'metsalehti_current_map_location';

type Props = {
  estate: Estate,
  stand?: Stand,
};

const MapCommon: React.FC<Props> = (props: Props) => {
  const { estate, stand } = props;

  const mapDefaultClasses = useMapDefaultStyles();
  const { map, mapContainer } = useMap(
    mapDefaultClasses.mapContainer,
    {
      container: 'map',
      accessToken: 'pk.eyJ1Ijoic2ltb3NvbCIsImEiOiJja2g1MnpoMWcwNGZkMnlvaDUxc3BtZWprIn0.WHhuJVpo20TygbABwf0Edw',
      transformRequest: mapTransformRequest,
    },
  );
  const positionWatcher = usePositionWatcher();
  const [positionLock, setPositionLock] = usePositionLock(map, positionWatcher);

  useOwnLocationMarker(map, positionWatcher, mapDefaultClasses.locationMarker);

  const onMapInitiated = () => {
    if (map) {
      if (stand) {
        map.fitBounds(
          turf.bbox(stand.geoJSON.geometry) as [number, number, number, number],
          { padding: 20 },
        );
      } else {
        map.fitBounds(
          turf.bbox(estate.geoJSON.geometry) as [number, number, number, number],
          { padding: 20 },
        );
      }
    }
  };

  map?.on('load', onMapInitiated);
  React.useEffect(
    () => () => {
      map?.off('load', onMapInitiated);
    },
    [],
  );

  const baseLayer = useBaseLayer(map, baseLayers);

  const stands = useMapStands(estate.stands);
  useOptimizedStandLayers(
    map,
    stands,
    baseLayer.baseLayerChangedNum,
    { zoomFilter: 14.01 },
  );
  useStandClick(
    map,
    (s?: EditorStand) => {
      if (!s) return;
      runInAction(() => {
        app.browser.page = {
          p: 'estate',
          p1: estate.id,
          p2: 'stand',
          p3: s.id,
        };
      });
    },
    baseLayer.baseLayerChangedNum,
    undefined,
  );
  useSelectedStands(map, stand ? [stand.id] : undefined);

  useMapFitBoundsAndSave(map, stands, MAP_LOCATION);

  const GPSDictionary = {
    denied: LangKey.MapDenied.t(),
    unaviable: LangKey.MapUnaviable.t(),
    unknownError: LangKey.MapUnknownError.t(),
    gpsError: LangKey.MapGpsError.t(),
  };

  return (
    <>
      {mapContainer}
      {(app.mwsLayers && app.vectorLayers) && (
        <RasterAndVectorLayersControl
          map={map}
          vectorLayersTitle={LangKey.MapVectorLayersTitle.t()}
          vectorLayers={app.vectorLayers}
          wmsLayersTitle={LangKey.MapWmsLayersTitle.t()}
          wmsLayers={app.mwsLayers}
          tooltip={LangKey.MapTooltipMapLayers.t()}
        />
      )}
      <OwnLocationButton
        className={mapDefaultClasses.buttonLocation}
        setPositionLock={setPositionLock}
        positionWatcher={positionWatcher}
        positionLock={positionLock}
      />
      <ZoomButtons className={mapDefaultClasses.buttonsZoom} map={map} />
      <GpsErrorDialog
        positionWatcher={positionWatcher}
        setDialogText={{
          denied: GPSDictionary.denied, gpsError: GPSDictionary.gpsError, unknownError: GPSDictionary.unknownError, unaviable: GPSDictionary.unaviable,
        }}
      />
    </>
  );
};

export default MapCommon;
