import * as React from 'react';
import { Button, styled } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LangKey } from '../../lang/LangKey';

const ButtonStyled = styled(Button)(({
  width: 120,
  transform: 'rotate(-90deg)',
  position: 'absolute',
  top: 100,
  left: -78,
  textTransform: 'uppercase',
  lineHeight: 1,
  fontSize: 12,
}));

type Props = {
  collapsed: boolean;
  onClick: () => void;
};

const CollapseButton: React.FunctionComponent<Props> = ({ collapsed, onClick }) => {
  const icon = collapsed ? <ExpandLess /> : <ExpandMore />;
  return (
    <ButtonStyled
      color="secondary"
      variant="contained"
      endIcon={icon}
      onClick={onClick}
    >
      {collapsed ? LangKey.ButtonsExpandArticle.t() : LangKey.ButtonsCollapseArticle.t()}
    </ButtonStyled>
  );
};

export default CollapseButton;
