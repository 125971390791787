import {
  action, computed, makeObservable, observable,
} from 'mobx';
import { LangKey } from '../../lang/LangKey';

export enum ArticleCategory {
  All = 0,
  Favorite = 1,
  Ownership = 2,
  WoodShop = 3,
  Forestry = 4,
  Technique = 5,
  Hobbies = 6,
  Nature = 7,
}

export const ArticleLabels = {
  [ArticleCategory.All]: LangKey.ArticlesArticlesCategoryAll,
  [ArticleCategory.Favorite]: LangKey.ArticlesArticlesCategoryFavorite,
  [ArticleCategory.Ownership]: LangKey.ArticlesArticlesCategoryOwnership,
  [ArticleCategory.WoodShop]: LangKey.ArticlesArticlesCategoryWoodShop,
  [ArticleCategory.Forestry]: LangKey.ArticlesArticlesCategoryForestry,
  [ArticleCategory.Technique]: LangKey.ArticlesArticlesCategoryTechnique,
  [ArticleCategory.Hobbies]: LangKey.ArticlesArticlesCategoryHobbies,
  [ArticleCategory.Nature]: LangKey.ArticlesArticlesCategoryNature,
};

export type CategoryKeys = typeof ArticleCategory[keyof typeof ArticleCategory];

export const AllCategory: CategoryKeys[] = [
  ArticleCategory.All,
  ArticleCategory.Favorite,
  ArticleCategory.Ownership,
  ArticleCategory.WoodShop,
  ArticleCategory.Forestry,
  ArticleCategory.Technique,
  ArticleCategory.Hobbies,
  ArticleCategory.Nature,
];

export type ArticleType = {
  id: number,
  img: string,
  title: string,
  description: string,
  text: string,
  category: ArticleCategory,
  author: string,
  date: string,
};

const news: ArticleType[] = [
  {
    id: 1,
    img: './temp/img/forest.png',
    title: 'Metsäkeskus tarkasti kemerakohteita, viidenneksellä ehdot eivät täyttyneet',
    description: 'Suomen metsäkeskus tarkasti viime vuonna taimikonhoitotöitä'
      + ' ja nuorten metsien harvennuksia liki 10 000 hehtaarilla,'
      + ' joille oli haettu kemeratukea...',
    text: 'Maecenas faucibus turpis sed vulputate sapien porta tristique. Consectetur nibh libero adipiscing nunc nulla. Mauris consectetur eget in felis. Blandit volutpat senectus tellus fermentum tortor. Volutpat, sit erat elementum imperdiet commodo nibh nunc. Nunc, varius nunc, sit erat quam imperdiet. Praesent sed et nascetur viverra. Mi faucibus convallis lacus sit commodo nunc. Blandit lectus quis lectus egestas risus orci. Sit et imperdiet in mattis cursus tempor senectus lacus risus.\n'
      + '\n'
      + 'Pharetra est nulla laoreet sit enim, tincidunt tellus. Sed rutrum nulla consequat, massa ornare tempus. Sit tristique urna, tempor non arcu in justo adipiscing. Consectetur risus ac, pellentesque tristique porta aliquet. Malesuada tempus sed ac hac scelerisque quis in imperdiet. Neque at etiam massa posuere quisque turpis. Et, augue elementum tortor mauris quis.\n'
      + '\n'
      + 'Ornare feugiat quam massa fermentum tortor eu, vulputate feugiat. Viverra porta laoreet sit lectus pellentesque sed. Pellentesque habitasse ipsum eu arcu nulla tempor. Suscipit ante nisi, enim vel sit fermentum, nulla ipsum. Rhoncus semper maecenas ut lacinia sem aliquam duis. Purus duis placerat nisl sed nibh felis. Felis eget in scelerisque ornare. In purus dignissim ullamcorper hac. Egestas sociis leo sed venenatis id.',
    category: 3,
    author: 'Mikko Riikilä',
    date: '2.2.2022',
  },
  {
    id: 2,
    img: './temp/img/forest.png',
    title: 'Metsäkeskus tarkasti kemerakohteita, viidenneksellä ehdot eivät täyttyneet',
    description: 'Suomen metsäkeskus tarkasti viime vuonna taimikonhoitotöitä'
      + ' ja nuorten metsien harvennuksia liki 10 000 hehtaarilla,'
      + ' joille oli haettu kemeratukea...',
    text: 'Maecenas faucibus turpis sed vulputate sapien porta tristique. Consectetur nibh libero adipiscing nunc nulla. Mauris consectetur eget in felis. Blandit volutpat senectus tellus fermentum tortor. Volutpat, sit erat elementum imperdiet commodo nibh nunc. Nunc, varius nunc, sit erat quam imperdiet. Praesent sed et nascetur viverra. Mi faucibus convallis lacus sit commodo nunc. Blandit lectus quis lectus egestas risus orci. Sit et imperdiet in mattis cursus tempor senectus lacus risus.\n'
      + '\n'
      + 'Pharetra est nulla laoreet sit enim, tincidunt tellus. Sed rutrum nulla consequat, massa ornare tempus. Sit tristique urna, tempor non arcu in justo adipiscing. Consectetur risus ac, pellentesque tristique porta aliquet. Malesuada tempus sed ac hac scelerisque quis in imperdiet. Neque at etiam massa posuere quisque turpis. Et, augue elementum tortor mauris quis.\n'
      + '\n'
      + 'Ornare feugiat quam massa fermentum tortor eu, vulputate feugiat. Viverra porta laoreet sit lectus pellentesque sed. Pellentesque habitasse ipsum eu arcu nulla tempor. Suscipit ante nisi, enim vel sit fermentum, nulla ipsum. Rhoncus semper maecenas ut lacinia sem aliquam duis. Purus duis placerat nisl sed nibh felis. Felis eget in scelerisque ornare. In purus dignissim ullamcorper hac. Egestas sociis leo sed venenatis id.',
    category: 5,
    author: 'Mikko Riikilä',
    date: '2.2.2022',
  },
  {
    id: 3,
    img: './temp/img/forest.png',
    title: 'Metsäkeskus tarkasti kemerakohteita, viidenneksellä ehdot eivät täyttyneet',
    description: 'Suomen metsäkeskus tarkasti viime vuonna taimikonhoitotöitä'
      + ' ja nuorten metsien harvennuksia liki 10 000 hehtaarilla,'
      + ' joille oli haettu kemeratukea...',
    text: 'Maecenas faucibus turpis sed vulputate sapien porta tristique. Consectetur nibh libero adipiscing nunc nulla. Mauris consectetur eget in felis. Blandit volutpat senectus tellus fermentum tortor. Volutpat, sit erat elementum imperdiet commodo nibh nunc. Nunc, varius nunc, sit erat quam imperdiet. Praesent sed et nascetur viverra. Mi faucibus convallis lacus sit commodo nunc. Blandit lectus quis lectus egestas risus orci. Sit et imperdiet in mattis cursus tempor senectus lacus risus.\n'
      + '\n'
      + 'Pharetra est nulla laoreet sit enim, tincidunt tellus. Sed rutrum nulla consequat, massa ornare tempus. Sit tristique urna, tempor non arcu in justo adipiscing. Consectetur risus ac, pellentesque tristique porta aliquet. Malesuada tempus sed ac hac scelerisque quis in imperdiet. Neque at etiam massa posuere quisque turpis. Et, augue elementum tortor mauris quis.\n'
      + '\n'
      + 'Ornare feugiat quam massa fermentum tortor eu, vulputate feugiat. Viverra porta laoreet sit lectus pellentesque sed. Pellentesque habitasse ipsum eu arcu nulla tempor. Suscipit ante nisi, enim vel sit fermentum, nulla ipsum. Rhoncus semper maecenas ut lacinia sem aliquam duis. Purus duis placerat nisl sed nibh felis. Felis eget in scelerisque ornare. In purus dignissim ullamcorper hac. Egestas sociis leo sed venenatis id.',
    category: 2,
    author: 'Mikko Riikilä',
    date: '2.2.2022',
  },
  {
    id: 4,
    img: './temp/img/forest.png',
    title: 'Metsäkeskus tarkasti kemerakohteita, viidenneksellä ehdot eivät täyttyneet',
    description: 'Suomen metsäkeskus tarkasti viime vuonna taimikonhoitotöitä'
      + ' ja nuorten metsien harvennuksia liki 10 000 hehtaarilla,'
      + ' joille oli haettu kemeratukea...',
    text: 'Maecenas faucibus turpis sed vulputate sapien porta tristique. Consectetur nibh libero adipiscing nunc nulla. Mauris consectetur eget in felis. Blandit volutpat senectus tellus fermentum tortor. Volutpat, sit erat elementum imperdiet commodo nibh nunc. Nunc, varius nunc, sit erat quam imperdiet. Praesent sed et nascetur viverra. Mi faucibus convallis lacus sit commodo nunc. Blandit lectus quis lectus egestas risus orci. Sit et imperdiet in mattis cursus tempor senectus lacus risus.\n'
      + '\n'
      + 'Pharetra est nulla laoreet sit enim, tincidunt tellus. Sed rutrum nulla consequat, massa ornare tempus. Sit tristique urna, tempor non arcu in justo adipiscing. Consectetur risus ac, pellentesque tristique porta aliquet. Malesuada tempus sed ac hac scelerisque quis in imperdiet. Neque at etiam massa posuere quisque turpis. Et, augue elementum tortor mauris quis.\n'
      + '\n'
      + 'Ornare feugiat quam massa fermentum tortor eu, vulputate feugiat. Viverra porta laoreet sit lectus pellentesque sed. Pellentesque habitasse ipsum eu arcu nulla tempor. Suscipit ante nisi, enim vel sit fermentum, nulla ipsum. Rhoncus semper maecenas ut lacinia sem aliquam duis. Purus duis placerat nisl sed nibh felis. Felis eget in scelerisque ornare. In purus dignissim ullamcorper hac. Egestas sociis leo sed venenatis id.',
    category: 6,
    author: 'Mikko Riikilä',
    date: '2.2.2022',
  },
  {
    id: 5,
    img: './temp/img/forest.png',
    title: 'Metsäkeskus tarkasti kemerakohteita, viidenneksellä ehdot eivät täyttyneet',
    description: 'Suomen metsäkeskus tarkasti viime vuonna taimikonhoitotöitä'
      + ' ja nuorten metsien harvennuksia liki 10 000 hehtaarilla,'
      + ' joille oli haettu kemeratukea...',
    text: '',
    category: 7,
    author: 'Mikko Riikilä',
    date: '2.2.2022',
  },
];

export default class Articles {
  private _data: ArticleType[];

  @observable
    favoriteList: ArticleType[] = [];

  @observable
  private _currentCategory: ArticleCategory;

  @observable
    currentArticle?: ArticleType = undefined;

  constructor() {
    makeObservable(this);
    this._data = news;
    this._currentCategory = 0;
  }

  @computed
  get articlesALL() {
    return this._data;
  }

  @computed
  get currentCategory() {
    return this._currentCategory;
  }

  set currentCategory(value) {
    this._currentCategory = value;
  }

  setCurrentArticle = (value: ArticleType | undefined) => {
    this.currentArticle = value;
  };

  @computed
  get filteredArticle() {
    if (this._currentCategory === 0) {
      return this.articlesALL;
    }
    if (this._currentCategory === 1) {
      return this.favoriteList;
    }
    return this.articlesALL.filter((el) => el.category === this.currentCategory);
  }

  @action
    favoriteListSet = (value: ArticleType) => {
      if (this.favoriteList.find((el) => el.id === value.id)) {
        this.favoriteList = this.favoriteList.filter((el) => el.id !== value.id);
      } else {
        this.favoriteList.push(value);
      }
    };
}
