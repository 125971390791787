import * as React from 'react';
import '@simosol/boxes/lib/boxes.css';
import { VBox } from '@simosol/boxes';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { app } from './models/App';
import { HiddenDesktop, useMobile } from './uiComponents/Responsive';
import AppTopMenu from './views/app/AppTopMenu';
import { AppWidth } from './theme';
import UiText from './uiComponents/UiText';
import ForestPage from './views/pages/forest/ForestPage';
import EstatePage from './views/pages/estate/EstatePage';
import StandPage from './views/pages/stands/StandPage';
import LogOutButton from './views/app/LogOutButton';
import gphQLApi from './api/GraphqlAPI';
import { LangKey } from './lang/LangKey';
import ArticleLeftContainer from './views/pages/article/leftContainer/ArticleLeftContainer';
import MobileMenu from './views/common/MobileMenu';
import GoalsDialog from './views/pages/goals/GoalsDialog';

const Root = styled(VBox)<{
  mobile: number
}>(({ mobile }) => ({
  minHeight: '100%',
  width: mobile ? '100%' : AppWidth,
  margin: '0 auto',
  height: 'auto !important',
  position: 'relative',
}));

const Logo = styled(VBox)(({ theme }) => ({
  width: '100%',
  height: 124,
  marginTop: '32px',
  marginBottom: '-24px',
  [theme.breakpoints.down('sm')]: {
    height: 96,
  },
  '& img': {
    width: 360,
    height: 50,
    [theme.breakpoints.down('sm')]: {
      width: 200,
      height: 29,
    },
  },
  '& span': {
    marginTop: 8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '.625em',
    },
  },
}));

const ButtonMobile = styled(Button)(({ theme }) => ({
  height: '28px',
  padding: theme.spacing(0.75, 1.5),
  position: 'fixed',
  rotate: '-90deg',
  right: theme.spacing(-2.625),
  top: theme.spacing(46.25),
  zIndex: 100,
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const App = observer(() => {
  const isMobile = useMobile();

  const { page } = app.browser;
  console.log('init');

  let content = <UiText>{LangKey.ErrorsPageNotFound.t({ data: window.location.hash })}</UiText>;

  if (page.p === '') {
    content = <ForestPage disabled />;
  } else if (page.p === 'forest') {
    content = <ForestPage />;
  } else if (page.p === 'estate' && page.p2 !== 'stand') {
    content = <EstatePage />;
  } else if (page.p === 'estate' && page.p2 === 'stand') {
    content = <StandPage />;
  }

  return (
    <Root mobile={+isMobile}>
      <HiddenDesktop>
        {!app.loading && <ButtonMobile onClick={() => app.toggleArticleMobile()}>{LangKey.ButtonsMobile.t()}</ButtonMobile>}
      </HiddenDesktop>
      <div>
        <Logo align="center">
          <img src="./temp/icons/logo-metsalehti.svg" alt="Logo" />
          <span>POLKU OMAAN METSÄÄN</span>
          {isMobile && <MobileMenu />}
        </Logo>
      </div>
      {!isMobile && gphQLApi.isAuth && <LogOutButton />}
      {!app.articleMobile && !isMobile && <AppTopMenu />}
      {(!app.articleMobile || !isMobile) ? content : (
        <HiddenDesktop>
          <ArticleLeftContainer />
        </HiddenDesktop>
      )}
      <GoalsDialog open={app.estates.showGoals} onClose={app.estates.hideShowGoals} />
    </Root>
  );
});

export default App;
