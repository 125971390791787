import { computed, makeObservable, observable } from 'mobx';
// eslint-disable-next-line import/no-cycle
import { SliderData, SliderModel } from './SliderModel';

export class SlidersModel<D extends SliderData = SliderData> {
  pointsMax = 3;

  slidersMax = 2;

  sliders: SliderModel<D>[];

  @observable error?: string;

  private readonly _getSliderDefault?: (slider: SliderModel<D>) => number;

  constructor(data: D[], getSliderDefault?: (slider: SliderModel<D>) => number) {
    makeObservable(this);
    this.sliders = data.map((sliderData) => new SliderModel(this, sliderData));
    this._getSliderDefault = getSliderDefault;
  }

  reset = () => {
    this.sliders.forEach((slider) => {
      // eslint-disable-next-line no-param-reassign
      slider.value = 0;
    });
    this.sliders.forEach((slider) => {
      // eslint-disable-next-line no-param-reassign
      slider.value = this._getSliderDefault?.(slider) ?? 0;
    });
  };

  @computed
  get pointsUsed(): number {
    return this.sliders.reduce((total, slider) => total + slider.value, 0);
  }

  @computed
  get pointsLeft() {
    return this.pointsMax - this.pointsUsed;
  }

  @computed
  get hasPoints() {
    return this.pointsLeft > 0;
  }
}

export default SliderModel;
