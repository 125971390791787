import { Feature, MultiPolygon } from 'geojson';
import { UtilsMap } from '../../utils/UtilsMap';
import { OperationGqlType, StandGqlType, TreatmentTypeEnum } from '../../generated/graphql';
import getPriority from '../operations/OperationTypes';
import { StandPriority } from './StandTypes';

export enum ETreatmentsNames {
  'proposal' = 'Omien tavoitteiden mukainen suunnitelma',
  'economic' = 'Taloutta painottavat toimenpiteet',
  'carbone' = 'Luontoarvoja painottavat toimenpiteet',
  'nature' = 'Hiilitasetta painottavat toimenpiteet',
  'economic_field' = '???',
}

export default class Stand {
  private readonly _data: StandGqlType;

  private readonly _priority: StandPriority;

  private readonly _geoJSON: string;

  proposalTreatment: OperationGqlType[] = [];

  economicTreatment: OperationGqlType[] = [];

  carboneTreatment: OperationGqlType[] = [];

  natureTreatment: OperationGqlType[] = [];

  constructor(data: StandGqlType) {
    this._data = data;
    this._geoJSON = JSON.stringify(this._data.geometry);
    this.economicTreatment = this._data.operationComputedPlans
      .find((p) => p.treatmentType === TreatmentTypeEnum.Economic)?.operations ?? [];
    this.carboneTreatment = this._data.operationComputedPlans
      .find((p) => p.treatmentType === TreatmentTypeEnum.Carbon)?.operations ?? [];
    this.natureTreatment = this._data.operationComputedPlans
      .find((p) => p.treatmentType === TreatmentTypeEnum.Nature)?.operations ?? [];
    this.proposalTreatment = this._data.operationComputedPlans
      .find((p) => p.treatmentType === data.selectedTreatmentType)?.operations ?? [];

    this._priority = this.proposalTreatment.length
      ? getPriority(this.proposalTreatment[0].yearPlanned)
      : StandPriority.low;
  }

  get id() {
    return this._data.id;
  }

  get data() {
    return this._data;
  }

  get geoJSON() {
    return UtilsMap.geomToGeoJSON(this._geoJSON) as Feature<MultiPolygon>;
  }

  get number() {
    return this._data?.number;
  }

  get selectedPlanNextOperations() {
    return this._data.selectedPlanNextOperations;
  }

  get extensionLabel() {
    let label = `${this._data?.number}`;
    if (this._data?.extension) label += `.${this._data?.extension}`;
    return label;
  }

  get areaHa() {
    return this._data?.areaHa ?? 0;
  }

  get areaM2() {
    return this._data?.areaM2 ?? 0;
  }

  get ditchingYear() {
    return this._data?.ditchingYear ?? 0;
  }

  get description() {
    return this._data?.description;
  }

  get estate() {
    return this._data?.estate;
  }

  get treeSpecies() {
    return this._data?.mainTreeSpecies?.label;
  }

  get devClass() {
    return this._data?.developmentClass?.label;
  }

  get mainGroup() {
    return this._data?.mainGroup?.label;
  }

  get subGroup() {
    return this._data?.subGroup?.label;
  }

  get soilType() {
    return this._data?.soilType?.label;
  }

  get quality() {
    return this._data?.quality?.label;
  }

  get accessibility() {
    return this._data?.accessibility?.label;
  }

  get drainageState() {
    return this._data?.drainageState?.label;
  }

  get fertilityClass() {
    return this._data?.fertilityClass?.label;
  }

  get publicText() {
    return this._data?.publicText ?? '';
  }

  get volume() {
    return this._data.selectedPlanEstimatedTotalVolume;
  }

  get value() {
    return this._data.selectedPlanEstimatedValue ?? 0;
  }

  get carbon() {
    return this._data.selectedPlanEstimatedTreeCarbonFlux;
  }

  get natural() {
    return this._data.selectedPlanEstimatedNatureValue;
  }

  get forestRegulation() {
    return this._data.hasForestRegulation;
  }

  get restrictions() {
    return this._data.restrictions;
  }

  get specialFeatures() {
    return this._data.specialFeatures;
  }

  get selectedPlanComputedTreeStand() {
    return this._data?.selectedPlanComputedTreeStand;
  }

  get priority(): StandPriority {
    return this._priority;
  }
}

export const extensionLabel = (id: number, extension?: string) => {
  let label = `${id}`;
  if (extension) label += `.${extension}`;
  return label;
};
