import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { app } from 'models/App';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import UiButton from '../uiComponents/UiButton';
import UiText from '../uiComponents/UiText';
import { LangKey } from '../lang/LangKey';

type Props = {
  open: boolean,
  onClose: () => void;
};

const LoginContentBox = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(12.5),
  paddingBottom: theme.spacing(12.5),
  cursor: 'inherit',
}));

const LoginActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
  margin: theme.spacing(4, 4),
}));

const LoginOut = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(15),
}));

const LogOutDialog = (props: Props) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <LoginContentBox>
        <UiText variant="h2" align="center">
          {LangKey.AuthLogoutText.t()}
        </UiText>
      </LoginContentBox>
      <LoginActions>
        <LoginOut loading={app.loading} variant="contained" onClick={app.logout} color="primary">
          {LangKey.ButtonsLogout.t()}
        </LoginOut>
      </LoginActions>
    </Dialog>
  );
};

export default LogOutDialog;
