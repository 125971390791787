import * as React from 'react';
import {
  styled, TableCell, TableCellProps, TableSortLabel,
} from '@mui/material';
import { VBox } from '@simosol/boxes';
import UiText from '../../../../uiComponents/UiText';
import { Order } from '../../../../models/SortTable';

const TextSecondary = styled(UiText)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type BlockType = {
  textPrimary: string;
  textSecondary?: string;
  bold?: boolean;
  small?: boolean;
};

type Props<T> = {
  order: Order,
  orderBy: keyof T,
  headCell: keyof T,
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
} & TableCellProps & BlockType;

export const StandsPatternHeadBlock = <T extends unknown>(props: Props<T>) => {
  const {
    textPrimary, bold, small, textSecondary, order, orderBy, headCell, onRequestSort,
  } = props;

  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableSortLabel
      active={orderBy === headCell}
      direction={orderBy === headCell ? order : 'asc'}
      onClick={createSortHandler(headCell)}
    >
      <VBox gap={4} style={{ height: '100%' }}>
        <UiText bold={bold} small={small}>{textPrimary}</UiText>
        {textSecondary && <TextSecondary variant="caption" small={small}>{textSecondary}</TextSecondary>}
      </VBox>
    </TableSortLabel>
  );
};

const StandsPatternHeadCell = <T extends unknown>(props: Props<T>) => {
  const {
    textPrimary,
    bold,
    small,
    textSecondary,
    ...other
  } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableCell {...other}>
      <StandsPatternHeadBlock
        textPrimary={textPrimary}
        textSecondary={textSecondary}
        bold={bold}
        small={small}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...other}
      />
    </TableCell>
  );
};

export default StandsPatternHeadCell;
