import { HBox, VBox } from '@simosol/boxes';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import UiText from '../../../uiComponents/UiText';
import Stand from '../../../models/stands/Stand';
import { StratumGqlType } from '../../../generated/graphql';
import { humanNumber } from '../../../utils/stringUtils';
import { useMobile } from '../../../uiComponents/Responsive';

type Props = {
  stand: Stand,
};

const HBoxStyled = styled(HBox)(({ theme }) => ({
  width: '100%',
  justifyContent: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(3),
    justifyContent: 'space-around',
  },
}));

const PercentStyled = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
}));

const PercentTitle = styled(UiText)(({ theme }) => ({
  fontWeight: 400,
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}));

const InfoBlock: React.FC<{ percent: number, name: string }> = ({ percent, name }) => (
  <VBox gap={4} align="center">
    <PercentStyled color="primary" fontWeight="bolder" variant="h3">
      {Number.isNaN(percent) ? ' - ' : humanNumber(percent)}
      %
    </PercentStyled>
    <PercentTitle variant="h5">{name}</PercentTitle>
  </VBox>
);

const InventoryTotalPercent: React.FC<Props> = (props: Props) => {
  const { stand } = props;
  const isMobile = useMobile();

  const { selectedPlanComputedTreeStand } = stand;

  if (!selectedPlanComputedTreeStand || selectedPlanComputedTreeStand.strata.length === 0) return null;

  const totalVolume = selectedPlanComputedTreeStand.strata
    .reduce((tot: number, strata: StratumGqlType) => tot + +strata.volume, 0);

  return (
    <HBoxStyled gap={isMobile ? 8 : 64} align="center">
      {selectedPlanComputedTreeStand.strata.map((strata: StratumGqlType) => (
        <InfoBlock
          key={strata.id}
          percent={(strata.volume / totalVolume) * 100}
          name={strata.species.label}
        />
      ))}
    </HBoxStyled>
  );
};

export default InventoryTotalPercent;
