import * as React from 'react';
import {
  Collapse, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, styled,
} from '@mui/material';
import { VBox } from '@simosol/boxes';
import UiText from '../../../../uiComponents/UiText';
import useCollapse from '../../../../uiComponents/useCollapse';
import CompareYearTabs from './CompareYearTabs';
import ComparePlansContent from './ComparePlansContent';
import ComparePlansGraphs from './ComparePlansGraphs';
import { useMobile } from '../../../../uiComponents/Responsive';
import Estate from '../../../../models/estates/Estate';
import ComparePlanSelect from './ComparePlanSelect';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  estate: Estate,
};

const ListItemRounded = styled(Paper)<{ mobile: number }>(({ theme, mobile }) => ({
  padding: theme.spacing(1, mobile ? 0 : 2),
  borderRadius: 20,
  cursor: 'pointer',
}));

const ListItemTitle = styled(UiText)(({ theme }) => ({
  letterSpacing: '0.05em',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    paddingLeft: '25px',
  },
}));

const CollapseStyled = styled(Collapse)<{
  mobile: number,
  collapse: number,
}>(({ mobile, collapse }) => ({
  padding: (mobile && collapse) ? 8 : 0,
}));

const EstateHeaderComparePlans: React.FC<Props> = (props: Props) => {
  const { estate } = props;
  const isMobile = useMobile();

  const { collapse, toggleCollapse, iconButton } = useCollapse();

  return (
    <List>
      <ListItemRounded mobile={+isMobile}>
        <ListItem onClick={toggleCollapse} sx={{ padding: isMobile ? '0 10px' : 0 }}>
          <ListItemText>
            <ListItemTitle variant="h5">{LangKey.ComparePlansTitle}</ListItemTitle>
          </ListItemText>
          <ListItemSecondaryAction>
            {iconButton}
          </ListItemSecondaryAction>
        </ListItem>
        <CollapseStyled in={collapse} mobile={+isMobile} collapse={+collapse}>
          <VBox gap={24}>
            <UiText div>{LangKey.ComparePlansDescription}</UiText>
            <ComparePlanSelect estate={estate} />
            <CompareYearTabs />
            <ComparePlansContent estate={estate} />
            <ComparePlansGraphs estate={estate} />
          </VBox>
        </CollapseStyled>
      </ListItemRounded>
    </List>
  );
};

export default EstateHeaderComparePlans;
