import * as React from 'react';

export default class Utils {
  static translateStringChildren(children: React.ReactNode, p?: Object) {
    return React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return child.t(p);
      }
      return child;
    });
  }
}
