import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { VBox } from '@simosol/boxes';
import { SliderData } from '../../../models/goals/SliderModel';
import { SlidersModel } from '../../../models/goals/SlidersModel';
import SliderWithLabels from './SliderWithLabels';

type Props<D extends SliderData> = { model: SlidersModel<D> };

const Sliders = observer(<D extends SliderData>(props: Props<D>) => {
  const { model } = props;
  return (
    <VBox gap={8}>
      {model.sliders.map((sliderModel) => (
        <SliderWithLabels key={sliderModel.title} model={sliderModel} />
      ))}
    </VBox>
  );
});

export default Sliders;
