import { PROPS } from '../queries/common';

const ASSORTMENTS = `{
  id
  assortmentType ${PROPS}
  percent
  species ${PROPS}
  volume
}`;

export default ASSORTMENTS;
