import { BaseLayer } from '@simosol/stands-map';

const baseLayers: BaseLayer [] = [
  {
    name: 'MapBaseLayersLoisto',
    style: 'temp/loisto_styles_v3.json',
  },
  {
    name: 'MapBaseLayersMbStreets',
    style: 'mapbox://styles/mapbox/streets-v11',
  },
  {
    name: 'MapBaseLayersMpSatellite',
    style: 'mapbox://styles/mapbox/satellite-v9',
  },
  {
    name: 'MapBaseLayersMpTerrain',
    style: 'mapbox://styles/mapbox/outdoors-v10',
  },
];

export default baseLayers;
