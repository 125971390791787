import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import numeral from 'numeral';
import proj4 from 'proj4';
import reportWebVitals from './reportWebVitals';
import { lang } from './lang/Lang';
import './styles/index.css';
import theme from './theme';
import AppContainer from './views/AppContainer';
import { app } from './models/App';

const numeralOptions = {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'kk',
    billion: 'kkk',
    trillion: 'kkkk',
  },
  ordinal: (number: number) => (number === 1 ? 'er' : 'ème'),
  currency: {
    symbol: '€',
  },
};

// load a locale
numeral.register('locale', 'fi', numeralOptions);
numeral.locale('fi');

window.numeral = numeral;

proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const initDevice = (onComplete: () => void) => { onComplete(); };

const initDOM = async () => {
  app.init();
  root.render(
    <ThemeProvider theme={theme}>
      <AppContainer />
    </ThemeProvider>,
  );
};

initDevice(() => lang.init(initDOM));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
