import * as React from 'react';
import {
  styled, Table, TableBody, TableCell,
} from '@mui/material';
import { VBox } from '@simosol/boxes';
import BlockRounded from '../../../common/BlockRounded';
import { ReactTableRow } from '../../../../uiComponents/ReactTable';
import UiText from '../../../../uiComponents/UiText';
import Stand from '../../../../models/stands/Stand';
import { TreeStandGqlType } from '../../../../generated/graphql';
import { LangKey } from '../../../../lang/LangKey';

const TextSecondary = styled(UiText)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const TextResult = styled(UiText)({
  color: '#4E4E4E',
});

type Props = {
  stand: Stand;
};

const StandsPatternTableShort: React.FC<Props> = ({ stand }) => {
  const { treeCarbonStorage, soilCarbonStorage } = stand.selectedPlanComputedTreeStand as TreeStandGqlType ?? 0;

  return (
    <BlockRounded style={{ overflow: 'hidden' }}>
      <Table>
        <TableBody>
          <ReactTableRow>
            <TableCell>{LangKey.StandsTableMainTree.t()}</TableCell>
            <TableCell><TextResult>{stand.devClass}</TextResult></TableCell>
          </ReactTableRow>
          <ReactTableRow>
            <TableCell>{LangKey.StandsTableTreeSpecies.t()}</TableCell>
            <TableCell><TextResult>{stand.treeSpecies}</TextResult></TableCell>
          </ReactTableRow>
          <ReactTableRow>
            <TableCell>
              <VBox gap={4} style={{ height: '100%' }}>
                <UiText>{LangKey.StandsTableStandValue.t()}</UiText>
                <TextSecondary variant="caption">euro</TextSecondary>
              </VBox>
            </TableCell>
            <TableCell><TextResult>{stand.value}</TextResult></TableCell>
          </ReactTableRow>
          <ReactTableRow>
            <TableCell>
              <VBox gap={4} style={{ height: '100%' }}>
                <UiText>{LangKey.StandsTableCarbonStorage.t()}</UiText>
                <TextSecondary variant="caption">t CO₂/v</TextSecondary>
              </VBox>
            </TableCell>
            <TableCell><TextResult>{treeCarbonStorage + soilCarbonStorage}</TextResult></TableCell>
          </ReactTableRow>
          <ReactTableRow>
            <TableCell>
              <VBox gap={4} style={{ height: '100%' }}>
                <UiText>{LangKey.StandsTableNaturalValue.t()}</UiText>
                <TextSecondary variant="caption">0-1</TextSecondary>
              </VBox>
            </TableCell>
            <TableCell><TextResult>{stand.natural}</TextResult></TableCell>
          </ReactTableRow>
        </TableBody>
      </Table>
    </BlockRounded>
  );
};

export default StandsPatternTableShort;
