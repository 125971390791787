import {
  action, computed, makeObservable, observable,
} from 'mobx';
import Estate from './Estate';
import { EstateGqlType, SimulationStatusEnum } from '../../generated/graphql';
// eslint-disable-next-line import/no-cycle
import gphQLApi from '../../api/GraphqlAPI';

export const ESTATES_PER_PAGE = 5;

export default class Estates {
  @observable.shallow
  private _estates: Estate[] = [];

  @observable loading: boolean = false;

  @observable estateActionLoading: boolean = false;

  @observable showGoals: boolean = false;

  @observable showInfo: boolean = false;

  @observable currentPage: number = 1;

  @observable.shallow importedEstates: EstateGqlType[] = [];

  constructor() {
    makeObservable(this);
  }

  get estates() {
    return this._estates;
  }

  @computed
  get pageEstates() {
    const start = (this.currentPage - 1) * ESTATES_PER_PAGE;
    const end = this.currentPage * ESTATES_PER_PAGE;
    return this._estates.slice(start, end);
  }

  @computed
  get pageCount() {
    return (this._estates.length % ESTATES_PER_PAGE)
      ? Math.floor(this._estates.length / ESTATES_PER_PAGE) + 1
      : Math.floor(this._estates.length / ESTATES_PER_PAGE);
  }

  fromData = (estates: EstateGqlType[]) => {
    estates.forEach((estate: EstateGqlType | null) => {
      if (estate) {
        this._estates.push(new Estate(estate));
      }
    });
  };

  getEstates = async () => {
    try {
      this.loading = true;
      const estateConnection = await gphQLApi.estateConnectionGet();
      const { edges } = estateConnection.estateConnection;
      this.fromData(edges.map((e) => e.node));
      this.downloadAllEstatesStands();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };

  downloadAllEstatesStands = async () => {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const estate of this.estates) {
        const { id } = estate;
        gphQLApi.estateGet({ id }).then((res) => {
          this.addStands([res.estateNode]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  addStands = (estates: EstateGqlType[]) => {
    this._estates.forEach((estateModel: Estate) => {
      estates.forEach((dEstate) => {
        if (estateModel.id === dEstate.id) {
          estateModel.addStands(dEstate.stands);
          estateModel.addPlans(dEstate);
        }
      });
    });
  };

  // eslint-disable-next-line consistent-return
  delete = async (estate: Estate) => {
    try {
      this.estateActionLoading = true;
      const res = await gphQLApi.estateDeleteQL({ id: estate.id });
      if (res.estateDelete.name) {
        this._estates = this._estates.filter((e: Estate) => e.id !== estate.id);
      }
      return res;
    } catch (e) {
      console.error(e);
    } finally {
      this.estateActionLoading = false;
    }
  };

  // eslint-disable-next-line consistent-return
  simulation = async (estate: Estate) => {
    try {
      this.estateActionLoading = true;
      const res = await gphQLApi.estateSimulationQL({ estateIds: [estate.id] });
      const selectedEstates = res.estatesSimulationMutation.estates.map((e) => e.id);
      if (selectedEstates.length) {
        this._estates.forEach((e: Estate) => {
          if (selectedEstates.includes(e.id)) {
            e.simulationStatus = SimulationStatusEnum.Running;
          }
        });
      }
      return res;
    } catch (e) {
      console.error(e);
    } finally {
      this.estateActionLoading = false;
    }
  };

  // eslint-disable-next-line consistent-return
  downloadEstateByCode = async (estateCode: string) => {
    try {
      this.estateActionLoading = true;
      return await gphQLApi.ImportOpenData({ estateCode });
    } catch (e) {
      console.error(e);
    } finally {
      this.estateActionLoading = false;
    }
  };

  @action
    hideImportInfo = () => {
      this.importedEstates.length = 0;
      this.showInfo = false;
      this.showGoals = this.estates.length === 1;
    };

  @action
    hideShowGoals = () => {
      this.showGoals = false;
    };

  @action
    showShowGoals = () => {
      if (!gphQLApi.isAuth) return;
      this.showGoals = true;
    };
}
