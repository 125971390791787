import INFO from './common';

const ESTATE_DELETE = `
  mutation($id: GlobalID!) {
    estateDelete(input: {id: $id}) {
      ${INFO} 
      ... on EstateGqlType {
        id
        name
      }
    }
  }
`;

export type EstateInputType = {
  id: string,
};

export default ESTATE_DELETE;
