import * as React from 'react';
import { styled } from '@mui/material/styles';
import { HBox, VBox } from '@simosol/boxes';
import Info from '@mui/icons-material/Info';
import Collapse from '@mui/material/Collapse';
import UiText from '../../../uiComponents/UiText';
import ScaleIcon from '../../common/icons/ScaleIcon';
import Operation from '../forest/operations/Operation';
import InventoryTotalPercent from './InventoryTotalPercent';
import HBoxVBox from '../../../uiComponents/HBoxVBox';
import StandsPatternTableShort from '../stands/standsPatterns/StandsPatternTableShort';
import StrataTable from '../stands/standsPatterns/stand/StrataTable';
import Stand from '../../../models/stands/Stand';
import Estate from '../../../models/estates/Estate';
import UiButton from '../../../uiComponents/UiButton';
import StandFeature from '../stands/standsPatterns/features/StandFeature';
import useCollapse from '../../../uiComponents/useCollapse';
import { OperationGqlType } from '../../../generated/graphql';
import { LangKey } from '../../../lang/LangKey';

const ButtonStyled = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(20),
}));
const Container = styled(VBox)<{ position?: boolean }>(({ position, theme }) => ({
  width: position ? '50%' : '100%',
  padding: position ? theme.spacing(0, 1) : 0,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const InfoStyled = styled(Info)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const RootContainer = styled(HBoxVBox)(({ theme }) => ({
  margin: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    margin: 'inherit',
  },
}));

const NextOperationTitle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    letterSpacing: '0.05em',
  },
}));

type Props = {
  stand: Stand;
  estate: Estate;
  isMobile: boolean;
  openStand: () => void;
};

const OperationItem = (operation: OperationGqlType, estate: Estate) => {
  const {
    id, operationType, cashFlow, yearPlanned, additionalInfo,
  } = operation;

  const { id: estateId, name: estateName } = estate;

  return (
    <Operation
      key={id}
      operationType={operationType.label}
      estateId={estateId}
      estateName={estateName}
      cashFlow={cashFlow}
      yearPlanned={yearPlanned}
      additionalInfo={additionalInfo}
      standStyle
    />
  );
};

const RowStandInfo: React.FC<Props> = ({
  stand, estate, isMobile, openStand,
}) => {
  const { collapse, iconButton } = useCollapse();

  return (
    <VBox gap={32}>
      <RootContainer vGap={16}>
        <Container gap={16} position>
          <UiText div variant="h4" fontWeight="bold">{LangKey.StandsStand.t({ number: stand.extensionLabel })}</UiText>
          {stand.forestRegulation && (
            <HBox gap={8}>
              <ScaleIcon />
              <UiText variant="button" div>{LangKey.StandsHeader.t()}</UiText>
            </HBox>
          )}
          <UiText div>{stand.description}</UiText>
          <UiText div variant="caption">
            {LangKey.StandsAdditional.t()}
          </UiText>
          <ButtonStyled variant="contained" onClick={openStand}>{LangKey.ButtonsOpenStand.t()}</ButtonStyled>
        </Container>
        {isMobile && (
          <StandsPatternTableShort stand={stand} />
        )}
        <Container gap={8}>
          <NextOperationTitle div variant="h4">{LangKey.StandsNextStep.t()}</NextOperationTitle>
          {stand.selectedPlanNextOperations.length > 0
            ? (
              <>
                <VBox gap={8}>
                  {
                    stand.selectedPlanNextOperations.slice(0, 2).map((operation) => (
                      OperationItem(operation, estate)
                    ))
                  }
                </VBox>
                <Collapse in={collapse}>
                  <VBox gap={8}>
                    {stand.selectedPlanNextOperations.slice(2).map((operation) => (
                      OperationItem(operation, estate)
                    ))}
                  </VBox>
                </Collapse>
                {
                  stand.selectedPlanNextOperations.length > 2 && (
                    <VBox gap={8} align="center">
                      <HBox align="center">
                        <UiText div variant="overline">{!collapse ? 'NÄYTÄ LISÄÄ' : 'VÄHEMMÄN LISÄÄ'}</UiText>
                        {iconButton}
                      </HBox>
                    </VBox>
                  )
                }
              </>
            )
            : (
              <StandFeature
                title={LangKey.MiscNoVariant.t()}
                icon={<InfoStyled color="success" />}
              />
            )}
        </Container>
      </RootContainer>
      <InventoryTotalPercent stand={stand} />
      <StrataTable stand={stand} small />
    </VBox>
  );
};

export default RowStandInfo;
