import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import StandsPatternHeadCell from './StandsPatternHeadCell';
import StandsPatternRow from './StandsPatternRow';
import theme from '../../../../theme';
import { StandsPatternRowStyled, StandsPatternTableStyled } from './StandsPatternStyles';
import { useMobile } from '../../../../uiComponents/Responsive';
import Estate from '../../../../models/estates/Estate';
import Stand from '../../../../models/stands/Stand';
import { getComparator, Order } from '../../../../models/SortTable';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  model: Estate,
};

const StandsPatternsTable: React.FC<Props> = (props: Props) => {
  const { model } = props;
  const isMobile = useMobile();

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Stand>('number');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Stand,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <StandsPatternTableStyled>
        <TableHead>
          <StandsPatternRowStyled>
            <StandsPatternHeadCell
              order={order}
              orderBy={orderBy}
              headCell="number"
              sortDirection={orderBy === 'number' ? order : false}
              onRequestSort={handleRequestSort}
              textPrimary={LangKey.StandsStand.t({ number: '' })}
            />
            <StandsPatternHeadCell
              order={order}
              orderBy={orderBy}
              headCell="areaM2"
              onRequestSort={handleRequestSort}
              textPrimary={LangKey.StandsTableFloorArea.t()}
              textSecondary="ha"
              sortDirection={orderBy === 'areaM2' ? order : false}
            />
            {
              isMobile
                ? (
                  <StandsPatternHeadCell
                    order={order}
                    orderBy={orderBy}
                    headCell="volume"
                    onRequestSort={handleRequestSort}
                    textPrimary={LangKey.StandsTableTotalVolume.t()}
                    textSecondary="m3"
                    sortDirection={orderBy === 'volume' ? order : false}
                  />
                )
                : (
                  <>
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="treeSpecies"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableMainTree.t()}
                      sortDirection={orderBy === 'treeSpecies' ? order : false}
                    />
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="devClass"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableTreeSpecies.t()}
                      sortDirection={orderBy === 'devClass' ? order : false}
                    />
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="volume"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableTotalVolume.t()}
                      sortDirection={orderBy === 'volume' ? order : false}
                      textSecondary="m3"
                    />
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="value"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableStandValue.t()}
                      sortDirection={orderBy === 'value' ? order : false}
                      textSecondary="euro"
                    />
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="carbon"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableCarbonStorage.t()}
                      textSecondary="t CO₂/v"
                      sortDirection={orderBy === 'carbon' ? order : false}
                    />
                    <StandsPatternHeadCell
                      order={order}
                      orderBy={orderBy}
                      headCell="natural"
                      onRequestSort={handleRequestSort}
                      textPrimary={LangKey.StandsTableNaturalValue.t()}
                      textSecondary="0-1"
                      sortDirection={orderBy === 'natural' ? order : false}
                    />
                  </>
                )
            }
          </StandsPatternRowStyled>
        </TableHead>
        <TableBody>
          {
            model.stands?.slice().sort(getComparator(order, orderBy)).map((rowData, k) => {
              const background = k % 2 === 0 ? undefined : theme.palette.action.hover;
              return (
                <StandsPatternRow
                  isMobile={isMobile}
                  estate={model}
                  stand={rowData}
                  background={background}
                  key={rowData.id}
                />
              );
            })
          }
        </TableBody>
      </StandsPatternTableStyled>
    </TableContainer>
  );
};

export default StandsPatternsTable;
