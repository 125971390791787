import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';

export const StandsPatternTableStyled = styled(Table)(({ theme }) => ({
  '& thead > tr:first-child': {
    backgroundColor: theme.palette.action.hover,
    borderBottom: `2px solid ${theme.palette.success.main}`,
  },
  '& .MuiTableRow-root td': {
    padding: theme.spacing(1, 0, 1, 1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 0, 1, 2),
    },
  },
}));

export const StandsPatternRowStyled = styled(TableRow)<{ height?: number }>(({ theme, height }) => ({
  position: 'relative',
  height,
  '& th, td': {
    padding: theme.spacing(1, 0, 1, 1),
  },
  border: 0,
}));
