import { PROPS } from '../queries/common';

const SPECIAL_FEATURES = `{
  id
  endDate
  startDate
  featureAdditionalCode ${PROPS}
  featureCode ${PROPS}
  featureType ${PROPS}
}`;

export default SPECIAL_FEATURES;
