import { SlidersModel } from './SlidersModel';
import { app } from '../App';
import { Criteria } from '../../api/mutations/setGoals';
import { LangKey } from '../../lang/LangKey';

const SlidersModelData = new SlidersModel(
  [
    {
      criteria: Criteria.economic,
      title: LangKey.GoalsCriteriaEconomicTitle.t(),
      description: LangKey.GoalsCriteriaEconomicDescription.t(),
    },
    {
      criteria: Criteria.carbon,
      title: LangKey.GoalsCriteriaCarbonTitle.t(),
      description: LangKey.GoalsCriteriaCarbonDescription.t(),
    },
    {
      criteria: Criteria.nature,
      title: LangKey.GoalsCriteriaNatureTitle.t(),
      description: LangKey.GoalsCriteriaNatureDescription.t(),
    },
  ],
  (sl) => app.goals.goals?.find((g) => g.criteria === sl.data.criteria)?.weight ?? 1,
);

export default SlidersModelData;
