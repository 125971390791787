import * as React from 'react';
import { VBox, HBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import MainContainer from 'views/common/MainContainer';
import { observer } from 'mobx-react-lite';
import { Info } from '@mui/icons-material';
import PageTitle from '../../app/PageTitle';
import ContentBox from '../../app/ContentBox';
import { app } from '../../../models/App';
import StandHeader from './StandHeader';
import StrataTable from './standsPatterns/stand/StrataTable';
import DeadTreeStandsTable from './standsPatterns/stand/DeadTreeStandsTable';
import HBoxVBox from '../../../uiComponents/HBoxVBox';
import StandSpecialFeatures from './standsPatterns/features/StandSpecialFeatures';
import StandRestrictions from './standsPatterns/features/StandRestrictions';
import Estate from '../../../models/estates/Estate';
import Stand from '../../../models/stands/Stand';
import theme, { AppWidth, rightBarWidth } from '../../../theme';
import ArticleContainer from '../article/ArticleContainer';
import { HiddenMobile } from '../../../uiComponents/Responsive';
import useCollapse from '../../../uiComponents/useCollapse';
import useNeighborBoxHeight from '../../../uiComponents/useNeighborBoxHeight';
import StandFeature from './standsPatterns/features/StandFeature';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';
import { LangKey } from '../../../lang/LangKey';

const Content = styled('div')<{ collapsed: number }>(({ collapsed }) => ({
  width: collapsed ? '100%' : (AppWidth - rightBarWidth),
  transition: theme.transitions.create(['width'], { duration: '0.3s' }),
  backgroundColor: '#f5f5f5',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
const HBoxStyled = styled(HBox)(() => ({
  position: 'relative',
}));
const InfoBLock = styled('div')({
  flex: 1,
});
const VBoxLock = styled(VBox)({
  flex: 1,
});
const InfoStyled = styled(Info)({
  margin: theme.spacing(1),
});

const StandPage: React.FC = observer(() => {
  const { page } = app.browser;
  if (page.p !== 'estate') return null;

  const estate = app.estates.estates.find((e: Estate) => e.id === page.p1);
  if (!estate) return null;

  if (!estate.withStands) {
    ResultSnackBar.error(LangKey.ErrorsStandsNotLoaded.t());
    app.browser.page = { p: 'forest' };
  }

  const standId = page.p2 === 'stand' ? page.p3 : undefined;
  if (!standId || !estate.stands) return null;

  const stand = estate.stands.find((s: Stand) => s.id === standId);
  if (!stand) return null;

  const { collapse: expanded, toggleCollapse: toggleExpand } = useCollapse();
  const { ref, height } = useNeighborBoxHeight();

  const name = LangKey.StandsStand.t({ number: stand.extensionLabel });

  return (
    <VBox gap={0}>
      <PageTitle
        links={[
          { page: { p: 'estate', p1: estate.id }, name: estate.name, id: estate.id },
          {
            page: {
              p: 'estate', p1: estate.id, p2: 'stand', p3: stand.id,
            },
            name,
            id: stand.id,
          },
        ]}
        title={name}
      />
      <HBoxStyled gap={0}>
        <Content collapsed={+app.collapse} ref={ref}>
          <StandHeader estate={estate} stand={stand} />
          <ContentBox>
            {stand.selectedPlanComputedTreeStand && (
              <MainContainer title={LangKey.StandsTitle.t()} dense>
                <StrataTable stand={stand} small />
              </MainContainer>
            )}
            <HBoxVBox hGap={24} vGap={24}>
              <InfoBLock>
                <MainContainer title={LangKey.StandsInfoTitle.t()} dense>
                  {(stand.selectedPlanComputedTreeStand && stand.selectedPlanComputedTreeStand.deadTreeStrata.length > 0)
                    ? <DeadTreeStandsTable data={stand.selectedPlanComputedTreeStand.deadTreeStrata} />
                    : (
                      <StandFeature
                        title={LangKey.StandsNoInfo.t()}
                        icon={<InfoStyled color="success" />}
                      />
                    )}
                </MainContainer>
              </InfoBLock>
              <VBoxLock gap={16}>
                <InfoBLock>
                  <VBox gap={24}>
                    <StandSpecialFeatures specialFeatures={stand.specialFeatures} />
                  </VBox>
                </InfoBLock>
                <InfoBLock>
                  <VBox gap={24}>
                    <StandRestrictions restrictions={stand.restrictions} />
                  </VBox>
                </InfoBLock>
              </VBoxLock>
            </HBoxVBox>
          </ContentBox>
        </Content>
        <HiddenMobile>
          <ArticleContainer
            height={height}
            collapsed={app.collapse}
            toggleCollapse={app.toggleCollapsed}
            expanded={expanded}
            toggleExpand={toggleExpand}
            hidden={!app.articles.currentArticle}
          />
        </HiddenMobile>
      </HBoxStyled>
    </VBox>
  );
});

export default StandPage;
