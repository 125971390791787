import * as React from 'react';
import { Tabs, Tab } from '@mui/material';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { app } from '../../../../models/App';

const CompareYearTabs = observer(() => {
  const currentYear = moment().year();

  const handleChange = (_event: React.SyntheticEvent, newVal: number) => {
    app.compareTable.item = newVal;
  };

  return (
    <Tabs
      value={app.compareTable.item}
      onChange={handleChange}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab label={`${currentYear}-${currentYear + 4}`} value={1} />
      <Tab label={`${currentYear + 5}-${currentYear + 9}`} value={2} />
      <Tab label={`${currentYear + 10}-${currentYear + 49}`} value={3} />
    </Tabs>
  );
});

export default CompareYearTabs;
