import * as React from 'react';
import { VBox } from '@simosol/boxes';
import { styled } from '@mui/material/styles';
import BlockTitle from './BlockTitle';
import BlockRounded from './BlockRounded';
import UiText from '../../uiComponents/UiText';

const BlockRoundedStyled = styled(BlockRounded)<{ dense?: number }>(({ theme, dense }) => ({
  padding: dense ? undefined : theme.spacing(3, 0),
  overflow: 'hidden',
}));

const MainRoot = styled(VBox)({
  marginTop: '16px',
});

type Props = {
  title: string;
  description?: string;
  dense?: boolean;
  titleOnly?: boolean;
} & React.PropsWithChildren;

const MainContainer: React.FC<Props> = ({
  title, description, dense, children, titleOnly,
}) => (
  <MainRoot gap={8}>
    <VBox gap={8}>
      <BlockTitle title={title} />
      {description && <UiText>{description}</UiText>}
    </VBox>
    {titleOnly
      ? children
      : (
        <BlockRoundedStyled dense={+!!dense}>
          {children}
        </BlockRoundedStyled>
      )}
  </MainRoot>
);

export default MainContainer;
