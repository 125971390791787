import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  BoxAlign, BoxGap, BoxProps, Box,
} from '@simosol/boxes';
import { breakpointXXS } from '../theme';

type Props = Omit<BoxProps, 'gap' | 'align'> & {
  hGap?: BoxGap,
  vGap?: BoxGap,
  hAlign?: BoxAlign,
  vAlign?: BoxAlign,
  breakpoint?: number,
  xxs?: boolean;
  sm?: boolean;
};

const HBoxVBox = (props: Props) => {
  const {
    hGap, vGap, hAlign, vAlign, breakpoint, xxs, sm, ...otherProps
  } = props;
  const theme = useTheme();

  let value: (keyof typeof theme.breakpoints.values);
  if (sm) value = 'sm';
  else value = 'md';

  const query = useMediaQuery(theme.breakpoints.down(
    breakpoint
    ?? xxs
      ? breakpointXXS
      : theme.breakpoints.values[value],
  ));
  const gap = query ? vGap : hGap;
  const align = query ? vAlign : hAlign;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box type={query ? 'VBox' : 'HBox'} align={align} gap={gap} {...otherProps} />
  );
};

export default HBoxVBox;
