import axios, {
  AxiosError, AxiosPromise, Canceler, Method,
} from 'axios';
import { lang } from '../lang/Lang';
import APIError from './APIError';

export type DLogin = { email: string, password: string };

export type APIMethodPromise<T> = Promise<T> & {
  cancel: Canceler;
};

type APIErrorData = { error: string, message?: string, details?: any } | undefined;

type ApiMethodOptions = {
  noToken?: boolean,
  pagination?: number,
  current_page?: number,
};

const apiStaticSettings: { token?: string } = {};

export const mapTransformRequest = (url: string) => {
  if (process.env.REACT_APP_API_DOMAIN && url.startsWith(process.env.REACT_APP_API_DOMAIN)) {
    return { url, headers: { token: apiStaticSettings.token } };
  }
  return { url };
};

class API {
  private token?: string = '172de19f42d38b3eee5f1387528ee28b18eb5292';

  public onUnauthorized?: () => void;

  /**
   * Base function for creating API methods
   */
  public apiMethodBase = <T = {}>(
    url: string,
    method: Method,
    data?: {},
    options?: ApiMethodOptions,
  ) => {
    const cancelSource = axios.CancelToken.source();

    const headers: { [key: string] : number | string } = {};
    if (!options?.noToken && this.token !== undefined) {
      headers.token = this.token;
    }
    if (options?.pagination) {
      headers['Pagination-Limit'] = options.pagination;
    }
    if (options?.current_page) {
      headers['Pagination-Page'] = options.current_page;
    }
    headers['Accept-Language'] = lang.locale;

    const request: AxiosPromise<T> = axios({
      url,
      method,
      data,
      headers,
      cancelToken: cancelSource.token,
    });
    const responsePromise = this.handleAPIResponse(request);

    const tPromise = responsePromise as APIMethodPromise<T>;
    tPromise.cancel = cancelSource.cancel;
    return tPromise;
  };

  /**
   * Creates the promise, which handles server answer and errors
   */
  private handleAPIResponse = async <T>(req: AxiosPromise<T>): Promise<T> => {
    try {
      const reqRes = await req;
      return reqRes.data;
    } catch (e) {
      const axiosError = e as AxiosError<APIErrorData>;
      if (axiosError.response) {
        const { data } = axiosError.response;
        if (axiosError.response.status === 401) {
          this.onUnauthorized?.();
        }
        if (data) {
          return new APIError('other', axiosError, data) as unknown as T;
        }
        return new APIError('critical', axiosError) as unknown as T;
      } if (axiosError.request) {
        return new APIError('network', axiosError) as unknown as T;
      }
      return new APIError('critical', axiosError) as unknown as T;
    }
  };

  public apiMethod = <T>(route: string, method: Method, data?: {}, options?: ApiMethodOptions) => {
    const apiURL = process.env.REACT_APP_API_DOMAIN;
    return this.apiMethodBase<T>(`${apiURL}${route}`, method, data, options);
  };

  public login = async (data: DLogin) => {
    const { password: oldPassword, email: oldEmail } = data;
    const password = oldPassword.trim();
    const email = oldEmail.trim();
    const res = await this.apiMethod<any>(
      'login/',
      'POST',
      { password, email },
      { noToken: true },
    );
    this.token = res.token2;
    return res;
  };

  // TODO
  // eslint-disable-next-line class-methods-use-this
  public layersGet = async () => {
    const response = await fetch('./temp/user.json');
    const json: {
      vectorLayers: {
        name: string,
        label: string,
        tileUrl: string,
        isChecked: boolean,
      }[],
      rasterLayers: {
        label: string;
        tileUrl: string;
        minZoom?: number;
        checked?: boolean;
        opacity?: number;
      }[], } = await response.json();
    return json;
  };
}

const apiErrorMessage = (e: Error | APIError) => (e instanceof APIError
  ? e.data?.error : undefined ?? e.message ?? '__UNKNOWN_ERROR__');

const api = new API();

export { api, apiErrorMessage };
