import * as React from 'react';
import {
  styled, Table, TableBody, TableCell, TableContainer,
} from '@mui/material';
import { ReactText } from 'uiComponents/UiText';
import Stand from '../../../models/stands/Stand';
import { ReactTableRow } from '../../../uiComponents/ReactTable';
import BlockRounded from '../../common/BlockRounded';
import { useMobile } from '../../../uiComponents/Responsive';
import { humanNumber } from '../../../utils/stringUtils';
import { LangKey } from '../../../lang/LangKey';

const BlockRoundedStyled = styled(BlockRounded)<{
  isMobile: number,
}>(({ isMobile }) => ({
  maxWidth: isMobile ? '100%' : 420,
  overflow: 'hidden',
  padding: 0,
}));
const TableStyled = styled(Table)(({ theme }) => ({
  '& td': {
    padding: theme.spacing(1, 2),
  },
}));

const Row = (props: { name: string, value?: string | number }) => {
  const { name, value } = props;

  return (
    <ReactTableRow>
      <TableCell><ReactText>{name}</ReactText></TableCell>
      <TableCell><ReactText>{`${value}` ?? ''}</ReactText></TableCell>
    </ReactTableRow>
  );
};

type Props = {
  stand?: Stand;
};

export const valueFormatter = (value: string | number | undefined) => {
  if (!value) return '';
  return value;
};

const StandInfo: React.FC<Props> = (props: Props) => {
  const { stand } = props;

  if (!stand) return null;

  const isMobile = useMobile();

  return (
    <BlockRoundedStyled isMobile={+isMobile}>
      <TableContainer>
        <TableStyled>
          <TableBody>
            <Row name={LangKey.StandsStand.t({ number: '' })} value={valueFormatter(stand.extensionLabel)} />
            <Row name={LangKey.StandsBasicTableArea.t()} value={valueFormatter(humanNumber(stand.areaHa))} />
            <Row name={LangKey.StandsBasicTableMainGroup.t()} value={valueFormatter(stand.mainGroup)} />
            <Row name={LangKey.StandsBasicTableSubGroup.t()} value={valueFormatter(stand.subGroup)} />
            <Row name={LangKey.StandsBasicTableFertilityClass.t()} value={valueFormatter(stand.fertilityClass)} />
            <Row name={LangKey.StandsBasicTableSoilType.t()} value={valueFormatter(stand.soilType)} />
            <Row name={LangKey.StandsBasicTableDrainageState.t()} value={valueFormatter(stand.drainageState)} />
            <Row name={LangKey.StandsBasicTableDitchingYear.t()} value={valueFormatter(stand.ditchingYear)} />
            <Row name={LangKey.StandsTableTreeSpecies.t()} value={valueFormatter(stand.treeSpecies)} />
            <Row name={LangKey.StandsTableMainTree.t()} value={valueFormatter(stand.devClass)} />
            <Row name={LangKey.StandsTableTreeSpecies.t()} value={valueFormatter(stand.quality)} />
            <Row name={LangKey.StandsBasicTableAccessibility.t()} value={valueFormatter(stand.accessibility)} />
            <Row name={LangKey.StandsBasicTablePublicText.t()} value={valueFormatter(stand.publicText)} />
          </TableBody>
        </TableStyled>
      </TableContainer>
    </BlockRoundedStyled>
  );
};

export default StandInfo;
