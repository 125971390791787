import { VBox, HBox } from '@simosol/boxes';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import EstateHeader from './EstateHeader';
import PageTitle from '../../app/PageTitle';
import ContentBox from '../../app/ContentBox';
import StandsPattern from '../stands/standsPatterns/StandsPattern';
import { app } from '../../../models/App';
import Estate from '../../../models/estates/Estate';
import theme, { AppWidth, rightBarWidth } from '../../../theme';
import { HiddenMobile } from '../../../uiComponents/Responsive';
import ArticleContainer from '../article/ArticleContainer';
import useCollapse from '../../../uiComponents/useCollapse';
import UseNeighborBoxHeight from '../../../uiComponents/useNeighborBoxHeight';
import ResultSnackBar from '../../../uiComponents/ResultSnackBar';
import { LangKey } from '../../../lang/LangKey';

const MainContainer = styled('div')<{ collapsed: boolean }>(({ collapsed }) => ({
  width: collapsed ? '100%' : (AppWidth - rightBarWidth),
  transition: theme.transitions.create(['width'], { duration: '0.3s' }),
  backgroundColor: '#f5f5f5',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HBoxStyled = styled(HBox)(() => ({
  position: 'relative',
}));

const EstatePage: React.FC = observer(() => {
  const { page } = app.browser;
  if (page.p !== 'estate') return null;

  const estate = app.estates.estates.find((e: Estate) => e.id === page.p1);
  if (!estate) return null;

  if (!estate.withStands) {
    ResultSnackBar.error(LangKey.EstatesNotLoaded.t());
    app.browser.page = { p: 'forest' };
  }

  const { collapse: expanded, toggleCollapse: toggleExpand } = useCollapse();
  const { ref, height } = UseNeighborBoxHeight();

  return (
    <VBox gap={0}>
      <PageTitle
        links={[{ page: { p: 'estate', p1: estate.id }, name: estate.name, id: estate.id }]}
        title={estate.name}
      />
      <HBoxStyled gap={0}>
        <MainContainer collapsed={app.collapse} ref={ref}>
          <EstateHeader model={estate} />
          {estate.stands && estate.stands.length > 0 && (
            <ContentBox>
              <StandsPattern model={estate} />
            </ContentBox>
          )}
        </MainContainer>
        <HiddenMobile>
          <ArticleContainer
            height={height}
            collapsed={app.collapse}
            toggleCollapse={app.toggleCollapsed}
            expanded={expanded}
            toggleExpand={toggleExpand}
            hidden={!app.articles.currentArticle}
          />
        </HiddenMobile>
      </HBoxStyled>
    </VBox>
  );
});

export default EstatePage;
