const INFO = `... on OperationInfo {
    messages {
      field
      kind
      message
    }
  }
`;

export default INFO;
