import { HBox, VBox } from '@simosol/boxes';
import * as React from 'react';
import { Info } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import UiText from '../../../../uiComponents/UiText';
import useCollapse from '../../../../uiComponents/useCollapse';
import { humanNumber, moneyFlowStr } from '../../../../utils/stringUtils';
import { OperationGqlType } from '../../../../generated/graphql';
import { LangKey } from '../../../../lang/LangKey';

const Root = styled(HBox)(() => ({
  width: '100%',
  position: 'relative',
  justifyContent: 'space-between',
}));

const ImgStyled = styled('img')({
  width: 36,
  height: 36,
});

const VBoxStyled = styled(VBox)(() => ({
  width: '100%',
}));

const DateInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: '0 10px',
  borderRadius: theme.spacing(2),
}));

const InfoIcon = styled(Info)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const Box = styled('div')(({ theme }) => ({
  flex: 1,
  width: theme.spacing(12),
  textAlign: 'center',
}));

const CollapseStyled = styled(Collapse)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

const StyledGoalLabel = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    letterSpacing: '0.05em',
  },
}));

const StyledCardInfo = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    letterSpacing: '0.05em',
    color: theme.palette.text.secondary,
  },
}));

const StyledMoneyInfo = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    letterSpacing: '0.05em',
  },
}));

type Props = {
  data: OperationGqlType;
  icon: 'waiting' | 'done' | 'next';
};

const StandStepsGoal: React.FC<Props> = ({ data, icon }) => {
  const { collapse, toggleCollapse } = useCollapse();

  // eslint-disable-next-line no-nested-ternary
  const iconLink = icon === 'next'
    ? './media/operations/operation.svg'
    : icon === 'done'
      ? './media/operations/operationDone.svg'
      : './media/operations/operationWaiting.svg';

  const hasAssort = data.assortments.length > 0;

  console.log(data.assortments);

  return (
    <Root align="start">
      {hasAssort && (
        <IconButtonStyled onClick={toggleCollapse}>
          {!collapse ? <ExpandMore /> : <ExpandLess />}
        </IconButtonStyled>
      )}
      <HBox gap={16}>
        <ImgStyled src={iconLink} alt="alt" />
        <VBoxStyled gap={4} align="start">
          <HBox align="center">
            <StyledGoalLabel bold div>{data.operationType.label}</StyledGoalLabel>
            <IconButton>
              <InfoIcon fontSize="small" />
            </IconButton>
          </HBox>
          <DateInfo>
            <UiText variant="caption">{LangKey.StandsGoalNextYear}</UiText>
          </DateInfo>
          <StyledMoneyInfo variant="caption" color={data.cashFlow < 0 ? 'red' : '#6D6D6D'}>
            {data.cashFlow && (data.cashFlow > 0)
              ? LangKey.CashTitlePositive.t({ value: moneyFlowStr(data.cashFlow) })
              : LangKey.CashTitleNegative.t({ value: moneyFlowStr(data.cashFlow ?? 0) })}
          </StyledMoneyInfo>
          {hasAssort && (
            <StyledCardInfo variant="caption">{LangKey.StandsTotalAccumulation.t({ value: humanNumber(data.harvestVolume) })}</StyledCardInfo>
          )}
          <CollapseStyled in={collapse}>
            {data.assortments.map((assortment) => (
              <HBox gap={0} style={{ flex: 1 }}>
                <Box style={{ textAlign: 'left' }}><UiText div variant="caption">{assortment.species?.label}</UiText></Box>
                <Box><UiText div variant="caption">{assortment.assortmentType?.label}</UiText></Box>
                <Box><UiText div variant="caption">{humanNumber(assortment.volume)}</UiText></Box>
              </HBox>
            ))}
          </CollapseStyled>
        </VBoxStyled>
      </HBox>
    </Root>
  );
};

export default StandStepsGoal;
