import {
  computed, observable, makeObservable, action,
} from 'mobx';

export default class Guard {
  @observable
  private _testToken = '';

  constructor() {
    makeObservable(this);
  }

  private readonly testTokenKey = 'met_token';

  @action
    initTestToken = () => {
      this._testToken = localStorage.getItem(this.testTokenKey) || '';
    };

  @action
    setTestToken = (token: string) => {
      this._testToken = token;
      localStorage.setItem(this.testTokenKey, token);
    };

  @computed
  get isValidTestToken() {
    if (process.env.REACT_APP_PROTECT) {
      return true;
    }
    return this._testToken === 'VQXBtkXfXztuZRCmNngd==';
  }

  @computed
  get isTestTokenError() {
    return this._testToken && !this.isValidTestToken;
  }
}
