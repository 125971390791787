import * as React from 'react';
import {
  styled, TableBody, TableCell, TableContainer, TableHead,
} from '@mui/material';
import BlockRounded from '../../../../common/BlockRounded';
import { StandsPatternRowStyled, StandsPatternTableStyled } from '../StandsPatternStyles';
import { ReactTableRow } from '../../../../../uiComponents/ReactTable';
import { ReactText } from '../../../../../uiComponents/UiText';
import StandsPatternHeadCell from '../StandsPatternHeadCell';
import { getComparator, Order } from '../../../../../models/SortTable';
import { DeadTreeStratumGqlType } from '../../../../../generated/graphql';
import { LangKey } from '../../../../../lang/LangKey';

type Props = {
  data: DeadTreeStratumGqlType[],
};

const BlockRoundedStyled = styled(BlockRounded)(({
  padding: 0,
  overflow: 'hidden',
}));

const DeadTreeStandsTable: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof DeadTreeStratumGqlType>('species');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof DeadTreeStratumGqlType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <BlockRoundedStyled>
      <TableContainer>
        <StandsPatternTableStyled>
          <TableHead>
            <StandsPatternRowStyled>
              <StandsPatternHeadCell
                order={order}
                orderBy={orderBy}
                headCell="species"
                sortDirection={orderBy === 'species' ? order : false}
                onRequestSort={handleRequestSort}
                textPrimary={LangKey.StandsTableWood.t()}
              />
              <StandsPatternHeadCell
                order={order}
                orderBy={orderBy}
                headCell="deadTreeType"
                sortDirection={orderBy === 'deadTreeType' ? order : false}
                onRequestSort={handleRequestSort}
                textPrimary={LangKey.StandsTableDeadWoodQuality.t()}
              />
              <StandsPatternHeadCell
                order={order}
                orderBy={orderBy}
                headCell="meanDiameter"
                sortDirection={orderBy === 'meanDiameter' ? order : false}
                onRequestSort={handleRequestSort}
                textPrimary={LangKey.StandsTableDiameter.t()}
                textSecondary="cm"
              />
              <StandsPatternHeadCell
                order={order}
                orderBy={orderBy}
                headCell="volume"
                sortDirection={orderBy === 'volume' ? order : false}
                onRequestSort={handleRequestSort}
                textPrimary={LangKey.StandsTableVolume.t()}
                textSecondary="m3/ha"
              />
            </StandsPatternRowStyled>
          </TableHead>
          <TableBody>
            {data.slice().sort(getComparator(order, orderBy)).map((rowData) => (
              <ReactTableRow key={rowData.id}>
                <TableCell><ReactText>{rowData.species.label}</ReactText></TableCell>
                <TableCell><ReactText>{rowData.deadTreeType.label}</ReactText></TableCell>
                <TableCell><ReactText>{rowData.meanDiameter}</ReactText></TableCell>
                <TableCell><ReactText>{rowData.volume}</ReactText></TableCell>
              </ReactTableRow>
            ))}
            <ReactTableRow key={data.length}>
              <TableCell><ReactText bold>{LangKey.ResultsPlanInTotal.t()}</ReactText></TableCell>
              <TableCell />
              <TableCell>
                <ReactText>
                  {(data
                    .reduce((tot, row) => tot + Number(row.meanDiameter), 0) / data.length)
                    .toFixed(2)}
                </ReactText>
              </TableCell>
              <TableCell>
                <ReactText>
                  {data.reduce((tot, row) => tot + Number(row.volume), 0).toFixed(2)}
                </ReactText>
              </TableCell>
            </ReactTableRow>
          </TableBody>
        </StandsPatternTableStyled>
      </TableContainer>
    </BlockRoundedStyled>
  );
};

export default DeadTreeStandsTable;
