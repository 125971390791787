import * as React from 'react';
import { VBox } from '@simosol/boxes';
import MainContainer from '../../../../common/MainContainer';
import StandFeature from './StandFeature';
import ScaleIcon from '../../../../common/icons/ScaleIcon';
import { SpecialFeatureGqlType } from '../../../../../generated/graphql';
import { LangKey } from '../../../../../lang/LangKey';

type Props = {
  specialFeatures: SpecialFeatureGqlType[],
};

const StandSpecialFeatures: React.FC<Props> = (props: Props) => {
  const { specialFeatures } = props;

  if (specialFeatures.length === 0) {
    return (
      <MainContainer title={LangKey.FeaturesSpecialFeaturesTitle.t()} titleOnly>
        <StandFeature
          title={LangKey.MiscNoRestriction.t()}
          icon={<ScaleIcon />}
        />
      </MainContainer>
    );
  }

  return (
    <MainContainer title={LangKey.FeaturesSpecialFeaturesTitle.t()} titleOnly>
      <VBox gap={16}>
        {specialFeatures.map((specialFeature) => (
          <StandFeature
            title={specialFeature.featureCode?.label}
            subtitle={specialFeature.featureAdditionalCode?.label}
            icon={<ScaleIcon />}
            tooltip
          />
        ))}
      </VBox>
    </MainContainer>
  );
};

export default StandSpecialFeatures;
