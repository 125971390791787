import * as React from 'react';
import { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { VBox } from '@simosol/boxes';
import HBoxVBox from '../../../uiComponents/HBoxVBox';
import EstatesHeaderMap from '../../pages/stands/EstatesHeaderMap';
import { useMobile } from '../../../uiComponents/Responsive';
import Estate from '../../../models/estates/Estate';
import Stand from '../../../models/stands/Stand';

const HBoxStyled = styled(HBoxVBox)(({ theme }) => ({
  padding: theme.spacing(6, 6, 0, 6),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    padding: theme.spacing(2, 2, 0, 2),
  },
}));

const Block = styled(VBox)<{ isMobile: number }>(({ isMobile }) => ({
  width: isMobile ? '100%' : 420,
}));

type Props = {
  estate: Estate,
  stand?: Stand,
} & PropsWithChildren;

const CommonStandsHeader: React.FC<Props> = (props: Props) => {
  const { estate, stand, children } = props;
  const isMobile = useMobile();

  return (
    <HBoxStyled hGap={24} vGap={8}>
      <Block gap={24} isMobile={+isMobile}>
        {children}
      </Block>
      <EstatesHeaderMap estate={estate} stand={stand} />
    </HBoxStyled>
  );
};

export default CommonStandsHeader;
