import * as React from 'react';
import { styled } from '@mui/material/styles';
import Info from '@mui/icons-material/Info';
import UiIconButton from '../../../../uiComponents/UiIconButton';

const Popover = React.lazy(() => import('@mui/material/Popover'));

const PopoverContent = styled('div')(({ theme }) => ({
  maxWidth: 400,
  padding: theme.spacing(2),
}));

const InfoButtonStyled = styled(UiIconButton)<{ short?: boolean }>(({ theme, short }) => ({
  [theme.breakpoints.down('md')]: {
    position: short ? 'inherit' : 'absolute',
    top: short ? 0 : -10,
    right: short ? 0 : -10,
  },
}));

type Props = {
  description?: string;
  disabled?: boolean;
  mobile?: boolean;
  short?: boolean;
};

const OperationInfo: React.FunctionComponent<Props> = ({
  description, disabled, mobile, short,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const openPopover = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const closePopover = () => setAnchorEl(null);
  return (
    <>
      <InfoButtonStyled
        onClick={openPopover}
        disabled={disabled}
        short={short}
      >
        <Info color="primary" fontSize={mobile ? 'small' : 'large'} />
      </InfoButtonStyled>
      <React.Suspense fallback={<div />}>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <PopoverContent>{description}</PopoverContent>
        </Popover>
      </React.Suspense>
    </>
  );
};

export default OperationInfo;
