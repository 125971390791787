import * as React from 'react';
import { Collapse, styled, TableCell } from '@mui/material';
import { HBox, VBox } from '@simosol/boxes';
import UiText, { ReactText } from '../../../../../uiComponents/UiText';
import useCollapse from '../../../../../uiComponents/useCollapse';
import { StratumGqlType } from '../../../../../generated/graphql';
import { LangKey } from '../../../../../lang/LangKey';

const BlockRow = styled(HBox)<{ top?: number, bottom?: number }>(({ theme, top, bottom }) => ({
  height: 40,
  padding: theme.spacing(1, 2),
  backgroundColor: '#fff',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& div:first-child': {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
  },
  '& div:nth-child(2)': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  '& div:last-child': {
    width: '5%',
    minWidth: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  borderTop: top ? `2px solid ${theme.palette.success.main}` : undefined,
  borderBottom: bottom ? `2px solid ${theme.palette.success.main}` : undefined,
  [theme.breakpoints.down('sm')]: {
    height: 44,
    padding: 0,
  },
}));

const MobileCell = styled(TableCell)({
  width: '45%',
  whiteSpace: 'nowrap',
  padding: 0,
});

const GrayText = styled(UiText)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type Props = {
  data: StratumGqlType,
  small?: boolean,
};

const InventoryTableShortRow: React.FC<Props> = ({ data, small }) => {
  const { collapse, iconButton } = useCollapse();

  return (
    <>
      <BlockRow>
        <MobileCell>
          <UiText small={small} style={{ paddingTop: '8px' }}>{LangKey.StandsTableWood}</UiText>
        </MobileCell>
        <MobileCell>
          <UiText div style={{ paddingTop: '8px' }}>{data.species.label}</UiText>
        </MobileCell>
        <div>
          {iconButton}
        </div>
      </BlockRow>
      <Collapse in={collapse}>
        <BlockRow top={1} style={{ height: 33 }}>
          <MobileCell>
            <UiText>{LangKey.StandsTablePeriod}</UiText>
          </MobileCell>
          <MobileCell>
            <ReactText div>{data.storey.label}</ReactText>
          </MobileCell>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableAge}</UiText>
              <GrayText variant="caption">{LangKey.ResultsPlanYear}</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.age}</ReactText>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableFloorArea}</UiText>
              <GrayText variant="caption">m2/ha</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.basalArea}</ReactText>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableFrameNumber}</UiText>
              <GrayText variant="caption">kpl/ha</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.number}</ReactText>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableLenght}</UiText>
              <GrayText variant="caption">m</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.meanHeight}</ReactText>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableDiameter}</UiText>
              <GrayText variant="caption">cm</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.meanDiameter}</ReactText>
          <div />
        </BlockRow>
        <BlockRow>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableVolume}</UiText>
              <GrayText variant="caption">m3/ha</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.volume}</ReactText>
          <div />
        </BlockRow>
        <BlockRow style={{ height: 28 }}>
          <MobileCell>
            <UiText>{LangKey.StandsTableLogPercent}</UiText>
          </MobileCell>
          <ReactText div>{data.sawLogPercent}</ReactText>
          <div />
        </BlockRow>
        <BlockRow bottom={1}>
          <MobileCell>
            <VBox gap={4} style={{ height: '100%' }}>
              <UiText>{LangKey.StandsTableGrowth}</UiText>
              <GrayText variant="caption">m3/ha/v</GrayText>
            </VBox>
          </MobileCell>
          <ReactText div>{data.volumeGrowth}</ReactText>
          <div />
        </BlockRow>
      </Collapse>
    </>
  );
};

export default InventoryTableShortRow;
