import * as React from 'react';
import {
  styled, Table, TableProps, TableRow, TableRowProps,
} from '@mui/material';

type RowProps = {
  top?: 1;
};

const StyledTableRow = styled(TableRow)<RowProps>(({ theme, top }) => ({
  backgroundColor: '#fff',
  '& td, th': {
    padding: 0,
  },
  '& td:first-child, th:first-child': {
    paddingLeft: theme.spacing(2),
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  border: 0,
  borderTop: top ? `2px solid ${theme.palette.success.main}` : undefined,
  height: 36,
  position: 'relative',
}));

export const ReactTableRow: React.FC<TableRowProps & RowProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledTableRow {...props} />
);

// eslint-disable-next-line react/jsx-props-no-spreading
export const ReactTable: React.FC<TableProps> = (props) => <Table {...props} />;
