import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() => createStyles({
  root: {
    border: '1px solid #7000E0',
    boxSizing: 'border-box',
    height: (props: Props) => props.height ?? 8,
    width: '100%',
  },
}));

type Props = {
  height?: number,
  color?: string,
};

const Divider = (props: Props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root} />
  );
};

export default Divider;
