import * as React from 'react';
import { styled } from '@mui/material/styles';
import { HBox, VBox } from '@simosol/boxes';
import { rightBarWidth } from 'theme';
import ArticleExpandButton from './ArticleExpandButton';
import ArticleContent from './ArticleContent';
import ArticleLeftContainer from './leftContainer/ArticleLeftContainer';

type Props = {
  height: number;
  collapsed: boolean;
  toggleCollapse: () => void;
  expanded: boolean;
  toggleExpand: () => void;
  hidden: boolean;
};

const Root = styled('div')<{
  expanded: boolean,
  collapsed: boolean,
}>(({ expanded, collapsed, theme }) => {
  let blockWidth: string | number = rightBarWidth;
  if (collapsed) blockWidth = theme.spacing(0);
  if (expanded) blockWidth = 'calc(100% - 40px)';

  return {
    zIndex: theme.zIndex.drawer,
    width: blockWidth,
    position: expanded ? 'absolute' : 'relative',
    right: 0,
    top: 0,
    backgroundColor: '#fff',
    transition: theme.transitions.create(['width', 'min-width'], { duration: '0.3s' }),
  };
});

const ContainerStyled = styled(HBox)<{
  collapsed: boolean,
  expanded: boolean,
  height: number,
}>(({ collapsed, expanded, height }) => ({
  overflowY: collapsed ? 'hidden' : 'auto',
  display: (collapsed && !expanded) ? 'none' : 'flex',
  height,
}));

const LeftContainer = styled(VBox)({
  width: rightBarWidth,
  overflowX: 'hidden',
  minWidth: rightBarWidth,
});

const ArticleContainer: React.FC<Props> = ({
  toggleCollapse, collapsed, expanded, toggleExpand, height, hidden,
}) => (
  <Root expanded={expanded} collapsed={collapsed}>
    <ContainerStyled collapsed={collapsed} expanded={expanded} align="stretch" height={height}>
      <LeftContainer>
        <ArticleLeftContainer expanded={expanded} />
      </LeftContainer>
      <ArticleContent expanded={expanded} />
    </ContainerStyled>
    <ArticleExpandButton
      collapsed={collapsed}
      toggleCollapse={toggleCollapse}
      expanded={expanded}
      toggleExpand={toggleExpand}
      hidden={hidden}
    />
  </Root>
);

export default ArticleContainer;
