import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { HBox } from '@simosol/boxes';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoRounded from '@mui/icons-material/InfoRounded';
import UiText from '../../../uiComponents/UiText';
import { SliderData, SliderModel } from '../../../models/goals/SliderModel';
import GoalSlider from './GoalSlider';
import { LangKey } from '../../../lang/LangKey';

const UiTextDescription = styled(UiText)({
  color: '#737779',
});

const UiTextTitle = styled(UiText)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const descriptions = [
  LangKey.GoalsDescriptionOne.t(),
  LangKey.GoalsDescriptionTwo.t(),
  LangKey.GoalsDescriptionThree.t(),
];

type Props<D extends SliderData> = { model: SliderModel<D> };

const SliderWithLabels = observer(<D extends SliderData>(props: Props<D>) => {
  const { model } = props;
  const { value } = model;
  const text = value < descriptions.length ? descriptions[value] : '';

  return (
    <div>
      <HBox gap={4} align="center">
        <UiTextTitle variant="h5">{model.title}</UiTextTitle>
        <Tooltip placement="top" arrow title={model.description}>
          <IconButton>
            <InfoRounded color="success" />
          </IconButton>
        </Tooltip>
      </HBox>
      <UiTextDescription>{text}</UiTextDescription>
      <GoalSlider model={model} />
    </div>
  );
});

export default SliderWithLabels;
