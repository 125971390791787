import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

type Props = {
  disabled?: boolean,
  default?:boolean
} & SvgIconProps;

const useCollapse = (props?: Props) => {
  const [collapse, setCollapse] = React.useState(props?.default ?? false);
  const toggleCollapse = () => !props?.disabled && setCollapse(!collapse);
  // eslint-disable-next-line react/jsx-props-no-spreading
  const icon = !collapse ? <ExpandMore {...props} /> : <ExpandLess {...props} />;

  const iconButton = (
    <IconButton color="secondary" onClick={toggleCollapse}>
      {icon}
    </IconButton>
  );

  return {
    collapse,
    toggleCollapse,
    iconButton,
  };
};

export default useCollapse;
