import * as React from 'react';
import { styled } from '@mui/material/styles';
import UiButton from '../uiComponents/UiButton';
import Article from './Article';
import LoginDialog from './LoginDialog';
import { LangKey } from '../lang/LangKey';

const UiButtonOrder = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(20),
}));

const UiButtonLogin = styled(UiButton)(({ theme }) => ({
  width: theme.spacing(30),
}));

const GuestArticle = () => {
  const [openLogin, setOpenLogin] = React.useState<boolean>(false);

  return (
    <Article
      title={LangKey.GuestTitle.t()}
      desc={LangKey.GuestDescription.t()}
      img="./temp/img/1.png"
    >
      <UiButtonOrder
        div
        variant="contained"
        color="primary"
        onClick={() => {}}
      >
        {LangKey.ButtonsOrder.t()}
      </UiButtonOrder>
      <UiButtonLogin
        div
        variant="contained"
        color="primary"
        onClick={() => setOpenLogin(true)}
      >
        {LangKey.ButtonsLoginGuest.t()}
      </UiButtonLogin>
      <LoginDialog open={openLogin} onClose={() => setOpenLogin(false)} />
    </Article>
  );
};

export default GuestArticle;
