import Router from '@simosol/router';
import { VectorLayer, WMSLayer } from '@simosol/layers-control';
import { action, makeObservable, observable } from 'mobx';
import Page, { PageModel } from './Pages';
import Guard from './Guard';
import { api } from '../api/API';
// eslint-disable-next-line import/no-cycle
import Estates from './estates/Estates';
// eslint-disable-next-line import/no-cycle
import Goals from './goals/Goals';
import { ObtainJsonWebTokenInput } from '../generated/graphql';
import CompareTable from './CompareTable';
import gphQLApi from '../api/GraphqlAPI';
import NextOperations from './operations/NextOperations';
import Articles from './article/Articles';

export class App {
  @observable
    collapse: boolean = false;

  @observable
    articleMobile: boolean = false;

  @observable
    loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  totalUmount: number | undefined;

  browser = new Router<Page>(new PageModel());

  goals = new Goals();

  compareTable = new CompareTable();

  guard = new Guard();

  estates = new Estates();

  nextOperations = new NextOperations();

  articles = new Articles();

  private _vector?: VectorLayer[];

  private _raster?: WMSLayer[];

  get vectorLayers() {
    return this._vector;
  }

  get mwsLayers() {
    return this._raster;
  }

  private initLayers = async () => {
    const data = await api.layersGet();
    this._vector = data.vectorLayers.map((l) => ({
      name: l.name, label: l.label, tileURL: l.tileUrl, checked: l.isChecked,
    }));
    this._raster = data.rasterLayers.map((l) => ({
      label: l.label,
      minZoom: l.minZoom,
      tileURL: l.tileUrl,
      checked: l.checked ?? false,
      opacity: l.opacity ?? 1,
    }));
  };

  init = () => {
    if (gphQLApi.getToken) {
      this.initApp();
    }
  };

  initApp = async () => {
    try {
      this.loading = true;
      await this.initLayers();
      this.goals.getGoals();
      this.estates.getEstates();
      const getTotalUmount = async () => {
        const estatesTotalEconomyGet = await gphQLApi.estatesTotalEconomyGet();
        this.totalUmount = estatesTotalEconomyGet.estatesTotalEconomyCriteriaImprovementForSelectedPlan;
      };
      getTotalUmount();
      this.nextOperations.getData();
      this.compareTable.getData();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  };

  login = async (formData: ObtainJsonWebTokenInput) => {
    await gphQLApi.tokenAuth({ input: formData });
    this.initApp();
  };

  logout = () => {
    this.browser.page = { p: '' };
    gphQLApi.deleteToken();
    this.estates = new Estates();
    this.goals = new Goals();
    this.compareTable = new CompareTable();
    this.nextOperations = new NextOperations();
  };

  @action
    toggleCollapsed = () => {
      this.collapse = !this.collapse;
    };

  @action
    toggleArticleMobile = () => {
      this.articleMobile = !this.articleMobile;
    };
}

const app = new App();
export { app };
