import { ESTATE_STANDS, ESTATE_WITHOUT_STANDS } from '../instanses/estate';
import { COMPARE_OPERATION, SIMPLE_OPERATION } from '../instanses/operation';

export const GET_ALL_ESTATES = `{
  estateConnection {
    edges {
      node ${ESTATE_WITHOUT_STANDS}
    }
  }
}
`;

export const GET_TOTAL_ECONOMY_CRITERIA = '{ estatesTotalEconomyCriteriaImprovementForSelectedPlan }';

export const GET_NEXT_OP_FOR_SELECTED = `{
  estatesNextOperationsForSelectedPlan {
    edges {
      node ${SIMPLE_OPERATION}
    }
  }
}
`;

export const GET_PLANNING_OP_FOR_SELECTED = `{
   planningYearOperationsSummaryForSelectedPlan {
    year
    totalCashFlow
    operations ${COMPARE_OPERATION}
  }
}
`;

export const GET_PLANNING_OP_FOR_PLAIN = `{
  planningYearOperationsSummaryForPlainPlans {
    yearInfo {
      totalCashFlow
      year
      operations ${COMPARE_OPERATION}
    }
    treatmentType
  }
}
`;

export const GET_PLANNING_TS_FOR_PLAIN = `{
  planningYearTreeStandsSummaryForPlainPlans {
    treatmentType
    yearInfo {
      totalCarbonStorage
      totalNatureValueIndex
      year
    }
  }
}
`;

export const GET_PLANNING_TS_FOR_SELECTED = `{
  planningYearTreeStandsSummaryForSelectedPlan {
    totalCarbonStorage
    totalNatureValueIndex
    year
  }
}
`;

export const GET_ONE_ESTATE = `
  query ($id: GlobalID!){
    estateNode (id: $id) ${ESTATE_STANDS}
}`;
