import axios from 'axios';
import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment/moment';
import { lang } from '../lang/Lang';
import {
  GET_ALL_ESTATES,
  GET_NEXT_OP_FOR_SELECTED,
  GET_ONE_ESTATE,
  GET_PLANNING_OP_FOR_PLAIN,
  GET_PLANNING_OP_FOR_SELECTED,
  GET_PLANNING_TS_FOR_PLAIN,
  GET_PLANNING_TS_FOR_SELECTED,
  GET_TOTAL_ECONOMY_CRITERIA,
} from './queries/estates';
import IMPORT_OPEN_DATA, { EstateImportType } from './mutations/importOpenData';
import {
  EstateGqlType,
  EstateGqlTypeConnection,
  ObtainJsonWebTokenType,
  OperationGqlTypeConnection,
  OperationInfo,
  PlanningYearOperationInfoGqlType,
  PlanningYearOperationSummaryGqlType,
  PlanningYearTreeStandInfoGqlType,
  PlanningYearTreeStandSummaryGqlType,
  RefreshTokenType,
  TokenType,
  UserGoalsSetInput,
} from '../generated/graphql';
import ESTATE_DELETE, { EstateInputType } from './mutations/estateDelete';
// eslint-disable-next-line import/no-cycle
import SET_GOALS, { GoalsInputType } from './mutations/setGoals';
import ESTATE_SIMULATION, { EstatesInputType } from './mutations/estatesSimulation';
import TOKEN_AUTH, { AuthTokenType } from './mutations/auth/tokenAuth';
import REFRESH_TOKEN from './mutations/auth/refreshToken';
import GET_GOALS from './queries/Goals';

const TOKEN_STORAGE_KEY = 'metsalehti';

const LOCAL_SEPARATOR = '_%%_';

type QueryType = {
  query: string,
  variables?: {},
};

class GraphqlAPI {
  @observable
  private token?: string = '';

  private tokenExp?: string = '';

  private refreshToken?: string = '';

  constructor() {
    makeObservable(this);
    const res = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (res) {
      const parts = res.split(LOCAL_SEPARATOR);
      if (parts && parts.length === 3) {
        const [token, exp, refreshToken] = parts;
        this.token = token;
        this.tokenExp = exp;
        this.refreshToken = refreshToken;
      }
    }
  }

  private setToken(token: TokenType, refreshToken: RefreshTokenType) {
    this.token = token.token;
    this.tokenExp = token.payload.exp;
    this.refreshToken = refreshToken?.token;
    localStorage.setItem(
      TOKEN_STORAGE_KEY,
      `${token.token}${LOCAL_SEPARATOR}${token.payload.exp}${LOCAL_SEPARATOR}${refreshToken.token}`,
    );
  }

  deleteToken = () => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    this.token = undefined;
    this.tokenExp = undefined;
    this.refreshToken = undefined;
  };

  @computed
  get getToken() {
    return this.token;
  }

  @computed
  get isAuth() {
    window.scrollTo(window.scrollX, 0);
    return !!this.token;
  }

  // eslint-disable-next-line class-methods-use-this
  private apiMethodBase = async <T>(data: QueryType) => {
    const headers: { [key: string] : number | string } = {};
    headers['Accept-Language'] = lang.locale;
    if (this.token) {
      headers.authorization = this.token;
    }

    const apiURL = process.env.REACT_APP_GRAPHQL_DOMAIN;
    return axios.post<T>(`${apiURL}`, data, { headers });
  };

  public apiGQLMethod = async <T>(data: QueryType) => {
    if (this.tokenExp && moment() >= moment(this.tokenExp).utc(true)) {
      await this.tokenRefresh();
    }
    const response = await this.apiMethodBase<T>(data);
    // return axios data
    return response.data;
  };

  /** ************** AUTH ***************** */
  public tokenAuth = async (variables: AuthTokenType) => {
    const res = await this.apiGQLMethod<{
      data: {
        tokenAuth: ObtainJsonWebTokenType,
      },
    }>(
      { query: TOKEN_AUTH, variables },
    );
    const { tokenAuth } = res.data;
    const { token, refreshToken } = tokenAuth;
    if (token && refreshToken) {
      this.setToken(token, refreshToken);
    }
  };

  public tokenRefresh = async () => {
    const result = await this.apiMethodBase<{
      data: {
        refreshToken: ObtainJsonWebTokenType,
      },
    }>(
      {
        query: REFRESH_TOKEN,
        variables: {
          input: {
            refreshToken: this.refreshToken,
          },
        },
      },
    );
    const { refreshToken } = result.data.data;
    const { token, refreshToken: refToken } = refreshToken;
    if (token && refToken) {
      this.setToken(token, refToken);
    }
  };

  /** ************ ESTATES **************** */
  public estateConnectionGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        estateConnection: EstateGqlTypeConnection,
      }
    }>(
      { query: GET_ALL_ESTATES },
    );
    // return response data
    return res.data;
  };

  public estatesTotalEconomyGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        estatesTotalEconomyCriteriaImprovementForSelectedPlan: number,
      }
    }>(
      { query: GET_TOTAL_ECONOMY_CRITERIA },
    );
    // return response data
    return res.data;
  };

  public estatesNextOperationsGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        estatesNextOperationsForSelectedPlan: OperationGqlTypeConnection,
      }
    }>(
      { query: GET_NEXT_OP_FOR_SELECTED },
    );
    // return response data
    return res.data;
  };

  public planningYearOperationsSummaryForSelectedPlanGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        planningYearOperationsSummaryForSelectedPlan: PlanningYearOperationInfoGqlType[],
      }
    }>(
      { query: GET_PLANNING_OP_FOR_SELECTED },
    );
    // return response data
    return res.data;
  };

  public planningYearOperationsSummaryForPlainPlansGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        planningYearOperationsSummaryForPlainPlans: PlanningYearOperationSummaryGqlType[],
      }
    }>(
      { query: GET_PLANNING_OP_FOR_PLAIN },
    );
    // return response data
    return res.data;
  };

  public planningYearTreeStandsSummaryForSelectedPlanGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        planningYearTreeStandsSummaryForSelectedPlan: PlanningYearTreeStandInfoGqlType[],
      }
    }>(
      { query: GET_PLANNING_TS_FOR_SELECTED },
    );
    // return response data
    return res.data;
  };

  public planningYearTreeStandsSummaryForPlainPlansGet = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        planningYearTreeStandsSummaryForPlainPlans: PlanningYearTreeStandSummaryGqlType[],
      }
    }>(
      { query: GET_PLANNING_TS_FOR_PLAIN },
    );
    // return response data
    return res.data;
  };

  public estateGet = async (variables: { id: string }) => {
    const res = await this.apiGQLMethod<{
      data: {
        estateNode: EstateGqlType,
      }
    }>(
      { query: GET_ONE_ESTATE, variables },
    );
    // return response data
    return res.data;
  };

  public ImportOpenData = async (variables: EstateImportType) => {
    const res = await this.apiGQLMethod<{
      data: {
        importOpenData: {
          estates: EstateGqlType[],
        } & OperationInfo,
      }
    }>(
      { query: IMPORT_OPEN_DATA, variables: { ...variables } },
    );
    // return response data
    return res.data;
  };

  public estateDeleteQL = async (variables: EstateInputType) => {
    const res = await this.apiGQLMethod<{
      data: {
        estateDelete: {
          name: string,
        } & OperationInfo,
      }
    }>(
      { query: ESTATE_DELETE, variables: { ...variables } },
    );
    // return response data
    return res.data;
  };

  public estateSimulationQL = async (variables: EstatesInputType) => {
    const res = await this.apiGQLMethod<{
      data: {
        estatesSimulationMutation: {
          estates: {
            id: string,
            name: string,
          }[],
        } & OperationInfo,
      }
    }>(
      { query: ESTATE_SIMULATION, variables: { ...variables } },
    );
    // return response data
    return res.data;
  };

  public getGoals = async () => {
    const res = await this.apiGQLMethod<{
      data: {
        userGoals: {
          goals: number[],
        },
      }
    }>(
      {
        query: GET_GOALS,
      },
    );
    // return response data
    return res.data;
  };

  public setGoals = async (variables: GoalsInputType) => {
    const res = await this.apiGQLMethod<{
      data: {
        userGoalsSet: UserGoalsSetInput & OperationInfo,
      }
    }>(
      { query: SET_GOALS, variables: { ...variables } },
    );
    // return response data
    return res.data;
  };
}

const gphQLApi = new GraphqlAPI();

export default gphQLApi;
